import { MockServerOpts } from "sockapi/server-config";
import { SavedHuntBackendState } from "sockapi/scope-specs/state-download-spec";
import {
  ServerInterface,
  ServerInterfaceCallbacks,
} from "sock-server/server-interface/ServerInterface";

export enum SharedWorkerReqType {
  INIT = "init",
  HANDLE_MSG = "handle_msg",
  REMOVE_CLIENT = "remove_client",
}

export type SharedWorkerReq =
  | {
      type: SharedWorkerReqType.INIT;
      mockServerOpts: MockServerOpts;
      connUid?: string;
      resetServer?: {
        initState?: SavedHuntBackendState;
      };
    }
  | {
      type: SharedWorkerReqType.HANDLE_MSG;
      msg: string;
    }
  | {
      type: SharedWorkerReqType.REMOVE_CLIENT;
    };

export enum SharedWorkerRespType {
  INIT_ACK = "init_ack",
  RESP = "resp",
  CLOSE = "close",
}

type SharedWorkerResp =
  | {
      type: SharedWorkerRespType.INIT_ACK;
    }
  | {
      type: SharedWorkerRespType.RESP;
      msg: string;
    }
  | {
      type: SharedWorkerRespType.CLOSE;
    };

export class SharedWorkerInterface implements ServerInterface {
  worker: SharedWorker;
  callbacks?: ServerInterfaceCallbacks;
  mockServerOpts: MockServerOpts;
  connUid?: string;
  handleMsg: (msg: string) => void;
  disconnect: () => void;
  isClosed: boolean;

  constructor(
    worker: SharedWorker,
    mockServerOpts: MockServerOpts,
    connUid?: string
  ) {
    this.worker = worker;
    this.mockServerOpts = mockServerOpts;
    this.connUid = connUid;
    this.handleMsg = () => {
      throw new Error("connection not initialized yet");
    };
    this.disconnect = () => {
      throw new Error("connection not initialized yet");
    };
    this.isClosed = false;
  }

  init(callbacks: ServerInterfaceCallbacks) {
    this.callbacks = callbacks;
    this.connect();
  }

  private connect(): void {
    if (this.callbacks === undefined)
      throw new Error("expect callbacks to be defined");
    const { onOpen, onResp, afterDisconnect } = this.callbacks;

    this.worker.port.onmessage = ({ data }: MessageEvent<SharedWorkerResp>) => {
      switch (data.type) {
        case SharedWorkerRespType.INIT_ACK: {
          onOpen();
          break;
        }
        case SharedWorkerRespType.RESP: {
          if (this.isClosed) return;
          Promise.resolve()
            .then(() => {
              onResp(data.msg);
            })
            .catch(console.error);
          break;
        }
        case SharedWorkerRespType.CLOSE: {
          this.reconnect();
          break;
        }
      }
    };

    this.handleMsg = (msg) => {
      this.worker.port.postMessage({
        type: SharedWorkerReqType.HANDLE_MSG,
        msg,
      });
    };

    this.disconnect = () => {
      this.worker.port.postMessage({
        type: SharedWorkerReqType.REMOVE_CLIENT,
      });
      afterDisconnect({
        numReconnections: 0,
        reconnectDelayInSeconds: 0,
      });
    };

    this.worker.port.start();

    this.worker.port.postMessage({
      type: SharedWorkerReqType.INIT,
      mockServerOpts: this.mockServerOpts,
      connUid: this.connUid,
    });
  }

  send(msg: string) {
    Promise.resolve()
      .then(() => {
        this.handleMsg(msg);
      })
      .catch(console.error);
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }

  close() {
    this.disconnect();
    this.isClosed = true;
    this.worker.port.close();
  }

  resetServer(initState?: SavedHuntBackendState) {
    this.worker.port.postMessage({
      type: SharedWorkerReqType.INIT,
      mockServerOpts: this.mockServerOpts,
      connUid: this.connUid,
      resetServer: { initState },
    });
  }
}
