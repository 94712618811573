import { RawWSInterface } from "sock-server/server-interface/RawWSInterface";

export class BrowserRawWSInterface implements RawWSInterface {
  wsEndpoint: string;

  constructor(wsEndpoint: string) {
    this.wsEndpoint = wsEndpoint;
  }

  makeConnection(
    onOpen: () => void,
    onClose: () => void,
    onRecv: (msg: string) => void,
    onErr: (err: Error) => void
  ) {
    const ws = new WebSocket(this.wsEndpoint);
    ws.addEventListener("open", (ev) => {
      onOpen();
    });
    ws.addEventListener("close", (ev) => {
      onClose();
    });
    ws.addEventListener("message", (ev) => {
      onRecv(ev.data);
    });
    ws.addEventListener("error", (ev) => {
      // Ignore these errors since they could be generated when we
      // disconnect from the server, and we already have handling
      // for that.
    });
    return {
      isOpen: () => {
        return ws.readyState === WebSocket.OPEN;
      },
      send: (msg: string) => {
        ws.send(msg);
      },
      close: () => {
        ws.close();
      },
    };
  }
}
