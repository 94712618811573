import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { ErratumSummary } from "sockapi/scope-specs/erratum-content-spec";

export type TeamErrataScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    [erratumId: string]: ErratumSummary;
  };
  update: {
    [erratumId: string]: ErratumSummary | null;
  };
};

export const teamErrataScopeHooks: ScopeHooks<ScopeType.TEAM_ERRATA> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    for (const [erratumId, erratum] of Object.entries(payload)) {
      if (erratum === null) delete scopeState[erratumId];
      else scopeState[erratumId] = erratum;
    }
  },
};
