import { PuzzleMetadata } from "sockapi/puzzle-data";
import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { SockAPIScopeState } from "sockapi/scope-state";
import { HintRequest } from "sockapi/scope-specs/hint-spec";

export const HINT_REQUEST_MAX_LENGTH = 10000;

export enum SubmitHintResultType {
  SUCCESS = "success",
  HUNT_IS_CLOSED = "hunt_is_closed",
  NO_HINT_TOKENS = "no_hint_tokens",
  OTHER_HINT_OPEN = "other_hint_open",
}

type SubmitHintResult =
  | {
      type:
        | SubmitHintResultType.SUCCESS
        | SubmitHintResultType.HUNT_IS_CLOSED
        | SubmitHintResultType.NO_HINT_TOKENS;
    }
  | {
      type: SubmitHintResultType.OTHER_HINT_OPEN;
      openHintPuzName: string;
    };

export type TeamHintsScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    hints: { [puzName: string]: { [hintId: string]: true } };

    numTotalTokens: number;
    /** Number of hint tokens used for each puzzle. */
    puzzleNumUsedTokens: { [puzName: string]: number };

    openHints: { [puzName: string]: true };
  };
  update: {
    addHint?: {
      puzName: string;
      hintId: string;
    };
    setNumTotalTokens?: number;
    updPuzzleNumUsedTokens?: { [puzName: string]: number };
    updOpenHints?: { [puzName: string]: boolean };
  };
  step: {
    puzName: string;
    req: Omit<HintRequest, "timestamp">;
  };
  stepResponse: SubmitHintResult;
};

export const getNumUsedHintTokensFromScopeState = (
  scopeState: SockAPIScopeState<ScopeType.TEAM_HINTS>
): number => {
  return Object.values(scopeState.puzzleNumUsedTokens).reduce(
    (acc, val) => acc + val,
    0
  );
};

export const isPuzzleEligibleForHint = (puzData: PuzzleMetadata): boolean => {
  const { isStory = false } = puzData;
  return !isStory;
};

export const checkSubmitHint = (
  isHuntClosed: boolean,
  isPosthunt: boolean,
  numUnusedHintTokens: number,
  openHintPuzName: string | null,
  isFollowup: boolean
): SubmitHintResult => {
  if (!isFollowup && numUnusedHintTokens <= 0)
    return { type: SubmitHintResultType.NO_HINT_TOKENS };
  if (openHintPuzName !== null)
    return {
      type: SubmitHintResultType.OTHER_HINT_OPEN,
      openHintPuzName,
    };
  return { type: SubmitHintResultType.SUCCESS };
};

export const teamHintsScopeHooks: ScopeHooks<ScopeType.TEAM_HINTS> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const { addHint, setNumTotalTokens, updPuzzleNumUsedTokens, updOpenHints } =
      payload;
    if (addHint !== undefined) {
      const { puzName, hintId } = addHint;
      scopeState.hints[puzName] = Object.assign(
        scopeState.hints[puzName] ?? {},
        { [hintId]: true }
      );
    }
    if (setNumTotalTokens !== undefined)
      scopeState.numTotalTokens = setNumTotalTokens;
    for (const [puzName, numUsedTokens] of Object.entries(
      updPuzzleNumUsedTokens ?? {}
    )) {
      if (numUsedTokens === 0) delete scopeState.puzzleNumUsedTokens[puzName];
      else scopeState.puzzleNumUsedTokens[puzName] = numUsedTokens;
    }
    for (const [puzName, isOpen] of Object.entries(updOpenHints ?? {})) {
      if (isOpen) scopeState.openHints[puzName] = true;
      else delete scopeState.openHints[puzName];
    }
  },
};
