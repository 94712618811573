import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { SockAPIScopeState } from "sockapi/scope-state";

export const CUSTOM_TITLE_MAX_LENGTH = 128;

export type TeamTitlesScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    titles: { [puzName: string]: string };
  };
  update: {
    titles?: { [puzName: string]: string };
  };
  step: {
    setTitle?: { puzName: string; title: string };
  };
  stepResponse: "";
  backendState: SockAPIScopeState<ScopeType.TEAM_TITLES>;
};

export const teamTitlesScopeHooks: ScopeHooks<ScopeType.TEAM_TITLES> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    for (const [puzName, title] of Object.entries(payload.titles ?? {})) {
      if (title === "") delete scopeState.titles[puzName];
      else scopeState.titles[puzName] = title;
    }
  },
};
