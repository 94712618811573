import { ScopeHooks } from "sockapi/scope-specs";
import { getPuzzleScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopePuzzleIdentifier } from "sockapi/scopes";

export enum PuzzleContentType {
  PUZZLE = "puzzle",
  SOLUTION = "solution",
}

export type PuzzleContent = {
  hash: string;
  content: string;
  firstKeyframeId?: string;
  // If any fields are added, make sure to update arePuzzleContentEqual.
};

export const arePuzzleContentEqual = (
  a?: PuzzleContent,
  b?: PuzzleContent
): boolean => {
  if (a === undefined || b === undefined)
    return a === undefined && b === undefined;
  return (
    a.hash === b.hash &&
    a.content === b.content &&
    a.firstKeyframeId === b.firstKeyframeId
  );
};

export const getPuzzleContentStaticDir = (content: PuzzleContent): string =>
  `/static/puzzle_resources/${content.hash}`;

export type PuzzleContentScopeSpec = {
  identifier: ScopePuzzleIdentifier;
  state: PuzzleContent & { answer?: never };
};

export const puzzleContentScopeHooks: ScopeHooks<ScopeType.PUZZLE_CONTENT> = {
  identityFunc: (scope) => scope,
  getIdComponents: getPuzzleScopeIdComponents,
};
