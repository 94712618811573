import { useMemo } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import settings from "settings";

// Note: Ideally these stores should be as separated as possible
// so that they don't overwrite each other in localstorage
// (i.e. if user has two tabs open each using different preference
// fields, changing a preference in one tab doesn't reset the
// preference set in the other).

/**
 * Separately persisted state.
 * If any fields are added here, make sure to add lines to initialize
 * them in useSeparatelyPersistedStateStore.
 */
type SeparatelyPersistedState = {
  /**
   * If null, then the user has explicitly requested the null puzzle
   * page (i.e. just the sidebar).
   * If undefined, then the user has not opened any puzzle yet.
   * What we do here depends on the hunt start flow, but by default
   * we redirect to the first unlocked puzzle.
   */
  lastSeenPuzSlug?: string | null;
  lastSeenNonNullPuzSlug?: string;
  adminPageLastSeenTeamId?: string;
  autoplayIsPaused?: boolean;
  autoplayVolume?: number;
  sidebarWidth: number;
  spinAngle: number;
  volume: number;
  isMuted: boolean;
};

type SeparatelyPersistedStateUpdate = Partial<SeparatelyPersistedState>;

const getSeparatelyPersistedStateStorageKey = (key: string) => {
  return `${settings.browserStoragePrefix}__${key}`;
};
const loadSeparatelyPersistedState = (key: string) => {
  const val = localStorage.getItem(getSeparatelyPersistedStateStorageKey(key));
  if (val === null) return undefined;
  return JSON.parse(val);
};

type SeparatelyPersistedStateStoreState = {
  state: SeparatelyPersistedState;
  update: (upd: SeparatelyPersistedStateUpdate) => void;
};
const useSeparatelyPersistedStateStore =
  create<SeparatelyPersistedStateStoreState>((set) => ({
    state: {
      lastSeenPuzSlug: loadSeparatelyPersistedState("lastSeenPuzSlug"),
      lastSeenNonNullPuzSlug: loadSeparatelyPersistedState(
        "lastSeenNonNullPuzSlug"
      ),
      adminPageLastSeenTeamId: loadSeparatelyPersistedState(
        "adminPageLastSeenTeamId"
      ),
      autoplayIsPaused: loadSeparatelyPersistedState("autoplayIsPaused"),
      autoplayVolume: loadSeparatelyPersistedState("autoplayVolume"),
      sidebarWidth: loadSeparatelyPersistedState("sidebarWidth") ?? 300,
      spinAngle: loadSeparatelyPersistedState("spinAngle") ?? 0,
      volume: loadSeparatelyPersistedState("volume") ?? 50,
      isMuted: loadSeparatelyPersistedState("isMuted") ?? false,
    },
    update: (upd) => set((state) => ({ state: { ...state.state, ...upd } })),
  }));

export const useSeparatelyPersistedState = <TRet>(
  statePick: (state: SeparatelyPersistedState) => TRet
): TRet => useSeparatelyPersistedStateStore((state) => statePick(state.state));

export const getCurrentSidebarWidth = (): number =>
  useSeparatelyPersistedStateStore.getState().state.sidebarWidth;
export const getCurrentSpinAngle = (): number =>
  useSeparatelyPersistedStateStore.getState().state.spinAngle;

const saveSeparatelyPersistedState = (
  upd: SeparatelyPersistedStateUpdate
): void => {
  const saveEntry = (
    key: string,
    val: object | string | number | boolean | null
  ) => {
    localStorage.setItem(
      getSeparatelyPersistedStateStorageKey(key),
      JSON.stringify(val)
    );
  };
  try {
    for (const [key, val] of Object.entries(upd)) {
      saveEntry(key, val);
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 * Only to be used outside of reactive contexts, as this does not
 * trigger updates based on updates from the server.
 */
export const updateSeparatelyPersistedStateNonReactive = (
  upd: SeparatelyPersistedStateUpdate
): void => {
  saveSeparatelyPersistedState(upd);
  useSeparatelyPersistedStateStore.getState().update(upd);
};

/**
 * Wrapper that persists individual fields manually instead of relying on
 * the persist middleware, in order to ensure that the fields are persisted
 * separately.
 */
export const useUpdateSeparatelyPersistedState = () => {
  const update = useSeparatelyPersistedStateStore(({ update }) => update);
  return useMemo(
    () => (upd: SeparatelyPersistedStateUpdate) => {
      saveSeparatelyPersistedState(upd);
      update(upd);
    },
    [update]
  );
};

export const useAdminPreferencesStore = create(
  persist<{
    isAdminEnabledPref: boolean;
    setIsAdminEnabledPref: (isAdminEnabledPref: boolean) => void;
    isSpectatingPref: boolean;
    setIsSpectatingPref: (isSpectatingPref: boolean) => void;

    /** Identifier for the staff user, for the admin queue. */
    staffId: string | null;
    setStaffId: (staffId: string | null) => void;
  }>(
    (set) => ({
      isAdminEnabledPref: true,
      setIsAdminEnabledPref: (isAdminEnabledPref) =>
        set({ isAdminEnabledPref }),
      // Default to spectating in production to avoid accidentally
      // interfering with teams.
      isSpectatingPref: !settings.devMode,
      setIsSpectatingPref: (isSpectatingPref) => set({ isSpectatingPref }),

      staffId: null,
      setStaffId: (staffId) => set({ staffId }),
    }),
    {
      name: `${settings.browserStoragePrefix}__admin_preferences`,
      version: 0,
    }
  )
);

export const useIsAdminEnabledStore = create<{
  isAdminEnabled: boolean;
  setIsAdminEnabled: (isAdminEnabled: boolean) => void;
  isSpectating: boolean;
  setIsSpectating: (isSpectating: boolean) => void;
}>((set) => ({
  isAdminEnabled: true,
  setIsAdminEnabled: (isAdminEnabled) => set({ isAdminEnabled }),
  isSpectating: true,
  setIsSpectating: (isSpectating) => set({ isSpectating }),
}));

export enum BigBoardSortOrder {
  LEADERBOARD = "leaderboard",
  HEALTH = "health",
  ACTIVITY = "activity",
}

export enum BigBoardPuzzleSortOrder {
  CANONICAL = "canonical",
  SOLVES = "solves",
}

interface BigBoardPreferencesStoreState {
  useEmojiHeaders: boolean;
  setUseEmojiHeaders: (useEmojiHeaders: boolean) => void;
  sortOrder: BigBoardSortOrder;
  setSortOrder: (sortOrder: BigBoardSortOrder) => void;
  puzzleSortOrder: BigBoardPuzzleSortOrder;
  setPuzzleSortOrder: (puzzleSortOrder: BigBoardPuzzleSortOrder) => void;
  showHidden: boolean;
  setShowHidden: (showHidden: boolean) => void;
  numTeamsPerPage: number;
  setNumTeamsPerPage: (numTeamsPerPage: number) => void;
  pageNum: number;
  setPageNum: (pageNum: number) => void;
}
export const useBigBoardPreferencesStore = create(
  persist<BigBoardPreferencesStoreState>(
    (set) => ({
      useEmojiHeaders: false,
      setUseEmojiHeaders: (useEmojiHeaders) => set({ useEmojiHeaders }),
      sortOrder: BigBoardSortOrder.LEADERBOARD,
      setSortOrder: (sortOrder) => set({ sortOrder }),
      puzzleSortOrder: BigBoardPuzzleSortOrder.CANONICAL,
      setPuzzleSortOrder: (puzzleSortOrder) => set({ puzzleSortOrder }),
      showHidden: false,
      setShowHidden: (showHidden) => set({ showHidden }),
      numTeamsPerPage: 30,
      setNumTeamsPerPage: (numTeamsPerPage) => set({ numTeamsPerPage }),
      pageNum: 0,
      setPageNum: (pageNum) => set({ pageNum }),
    }),
    {
      name: `${settings.browserStoragePrefix}__bigboard_preferences`,
      version: 2,
    }
  )
);
