import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { GachaPull, PullAck } from "sockapi/gacha/pulls";

export type TeamGachaScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    // Gacha pulls made by this team.
    pulls: {
      [pullId: string]: GachaPull;
    };
  };
  update: {
    newPulls: {
      [pullId: string]: GachaPull;
    };
  };
  step: {
    /**
     * If true, performs the pull even if the team does not have
     * enough hope. Only works for admin connections.
     */
    ignoreHopeLimit?: true;
    /** If set, performs multiple pulls at once. Only works for admins. */
    numPulls?: number;
  };
  stepResponse: PullAck;
};

export const teamGachaScopeHooks: ScopeHooks<ScopeType.TEAM_GACHA> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    Object.assign(scopeState.pulls, payload.newPulls);
  },
};
