import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export const HOPE_POOL_CONFIG = {
  puzName: "puz313",
  /** Only support a fixed contribution amount. */
  contributionAmt: 5,
  minToClaim: 100,
  claimRewardMultiplier: 1.5,
};

export type HopePoolScopeSpec = {
  state: {
    poolAmt: number;
  };
  backendState: {
    poolAmt: number;
    cycleId: number;
  };
};

export const hopePoolScopeHooks: ScopeHooks<ScopeType.HOPE_POOL> = {
  identityFunc: (scope) => scope,
};
