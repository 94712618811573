import { DistributiveOmit } from "util/util";
import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import { SockAPIScopeState } from "sockapi/scope-state";

export enum HintResponseType {
  ANSWERED = "answered",
  REFUNDED = "refunded",
  OBSOLETE = "obsolete",
}

export type HintRequest = {
  content: string;
  isFollowup?: boolean;
  shouldNotifyEmails?: boolean;
  timestamp: number;
};

export type HintResponse = {
  type: HintResponseType;
  content: string;
  timestamp: number;
};

export type HintSetResponse = DistributiveOmit<HintResponse, "timestamp">;

export const doesHintUseToken = (
  request: HintRequest,
  response: HintResponse | null
): boolean => {
  if (request.isFollowup ?? false) return false;
  if (response === null) return true;
  switch (response?.type) {
    case HintResponseType.REFUNDED:
    case HintResponseType.OBSOLETE:
      return false;
    case HintResponseType.ANSWERED:
      return true;
  }
  // Don't provide a default case so that typescript will
  // throw a build error if this is not exhaustive.
};

export type HintScopeSpec = {
  identifier: { hintId: string };
  state: {
    teamId: string;
    puzName: string;
    request: HintRequest;
    response: HintResponse | null;
  };
  update: {
    response?: HintResponse;
  };
  step: {
    setResponse: HintSetResponse;
  };
  stepResponse: "";
  backendState: SockAPIScopeState<ScopeType.HINT>;
};

export const hintScopeHooks: ScopeHooks<ScopeType.HINT> = {
  identityFunc: (scope) => scope,
  getIdComponents: ({ hintId }) => [hintId],
  update: (scopeState, payload) => {
    const { response } = payload;
    if (response !== undefined) scopeState.response = response;
  },
};
