import { useEffect, useState, useMemo } from "react";
import { create } from "zustand";
import produce from "immer";

import { arrayZipMap } from "util/util";
import { AsyncLockMap } from "util/locks";
import { ScopeType } from "sockapi/scope-types";
import { scopeHooks, globalScopeHooks } from "sockapi/scope-specs";
import { scopeToString } from "sockapi/scopes";
import {
  SockAPIScopeType,
  SockAPIScopeIdentifierType,
  SockAPIScopeIdentifier,
  SockAPIScope,
  SockAPIScopeState,
  SockAPIScopeTrackingEntry,
  SockAPIScopeTrackingState,
  SockAPIScopeTrackingStateForType,
  getScopeTrackingStateForTypeReadonly,
  getOrCreateScopeTrackingEntry,
  setPosthuntScopeStateDownloadFailed,
} from "sockapi/scope-state";
import {
  SockAPIScopeAssign,
  SockAPIScopeUpdate,
  applyScopeAssignToTrackingState,
  applyScopeUpdateToTrackingState,
} from "sockapi/scope-updates";
import { TeamPuzzleSockAPIScopeState } from "sockapi/scope-specs/team-spec";
import { getNumUsedHintTokensFromScopeState } from "sockapi/scope-specs/team-hints-spec";
import { Puzzle105StatusType } from "sockapi/scope-specs/team-puzzle-105-spec";
import { HopeSnapshot } from "sockapi/gacha/hope";
import {
  PuzzleMetadata,
  getPuzzleDisplayName,
  isUsedForSolveCount,
} from "sockapi/puzzle-data";

import settings from "settings";
import {
  useServerInteractionStore,
  useShouldDisplayAdmin,
  useSockClient,
  useTeamId,
} from "stores/ServerInteractionStore";
import {
  CompletedStoryBeats,
  useCompletedStoryBeats,
} from "stores/BrowserWidePersistentState";
import { HuntNotification } from "sockapi/client-notifications";
import { actualHope } from "sockapi/gacha/hope";
import { useReactiveTimeout } from "reactive-timers";

type ScopeStateCacheEntry<TScopeType extends SockAPIScopeType> = {
  state: SockAPIScopeState<TScopeType>;
  clientTag: string;
  serverTag?: string;
};

const getScopeStateCacheDataStorageKeyPrefix = () => {
  return `${settings.browserStoragePrefix}__scopeStateCacheData`;
};

const clearScopeStateCache = () => {
  const prefix = getScopeStateCacheDataStorageKeyPrefix();
  for (const key of Object.keys(localStorage)) {
    if (key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
};

const clearScopeStateCacheIfTooLarge = () => {
  if (new Blob(Object.values(localStorage)).size > 2 * 1024 * 1024)
    clearScopeStateCache();
};

const scopeStateCacheClearThrottleMs = 60 * 1000; // 1 minute
let prevClearScopeStateCacheIfTooLargeTime = 0;
const clearScopeStateCacheIfTooLargeThrottled = () => {
  const timeNow = Date.now();
  if (
    timeNow - prevClearScopeStateCacheIfTooLargeTime <
    scopeStateCacheClearThrottleMs
  )
    return;
  prevClearScopeStateCacheIfTooLargeTime = timeNow;
  clearScopeStateCacheIfTooLarge();
};

const getScopeStateCacheDataStorageKey = (scope: SockAPIScope) => {
  return `${getScopeStateCacheDataStorageKeyPrefix()}__${scopeToString(scope)}`;
};
const saveScopeStateCacheEntry = <TScope extends SockAPIScope>(
  scope: TScope,
  state: SockAPIScopeState<TScope["type"]>,
  tag?: string
) => {
  if (scopeHooks[scope.type].disableClientCache ?? false) return;
  const entry: ScopeStateCacheEntry<TScope["type"]> = {
    state,
    clientTag: `${settings.localMode ? "local_" : ""}${settings.gitCommitHash}`,
    serverTag: tag,
  };
  clearScopeStateCacheIfTooLargeThrottled();
  try {
    localStorage.setItem(
      getScopeStateCacheDataStorageKey(scope),
      JSON.stringify(entry)
    );
  } catch (err) {
    console.error(err);
  }
};
const loadCachedScopeState = <TScope extends SockAPIScope>(
  scope: TScope
): SockAPIScopeTrackingEntry<TScope["type"]> | null => {
  if (!settings.enableScopeStateCache) return null;
  const data = localStorage.getItem(getScopeStateCacheDataStorageKey(scope));
  if (data === null) return null;
  const entry: ScopeStateCacheEntry<TScope["type"]> = JSON.parse(data);
  const { state, clientTag, serverTag } = entry;
  if (clientTag !== settings.gitCommitHash) return null;
  return { state, cacheTag: serverTag };
};

interface ScopeStateStoreState {
  scopeTrackingState: SockAPIScopeTrackingState;
  initScopeState: (scopes: SockAPIScope[]) => void;
  applyScopeAssign: (assign: SockAPIScopeAssign) => void;
  applyScopeUpdate: (upd: SockAPIScopeUpdate) => void;
  assignForPosthunt: (assign: SockAPIScopeAssign) => void;
  setPosthuntDownloadFailed: (scope: SockAPIScope) => void;
  playAudioNotif: ((upd: HuntNotification) => void) | null;
  setPlayAudioNotif: (
    playAudioNotif: ((upd: HuntNotification) => void) | null
  ) => void;
}

export const useScopeStateStore = create<ScopeStateStoreState>((set) => ({
  scopeTrackingState: {},
  initScopeState: (scopes) =>
    set(
      produce((state) => {
        for (const scope of scopes) {
          getOrCreateScopeTrackingEntry(
            state.scopeTrackingState,
            scope,
            () => loadCachedScopeState(scope) ?? undefined
          );
        }
      })
    ),
  applyScopeAssign: (assign) =>
    set(
      produce((state) => {
        const changeResult = applyScopeAssignToTrackingState(
          state.scopeTrackingState,
          assign
        );
        saveScopeStateCacheEntry(assign.scope, changeResult.state, assign.tag);
      })
    ),
  applyScopeUpdate: (upd) =>
    set(
      produce((state) => {
        const changeResult = applyScopeUpdateToTrackingState(
          state.scopeTrackingState,
          upd
        );
        saveScopeStateCacheEntry(upd.scope, changeResult.state, upd.tag);
      })
    ),
  assignForPosthunt: (assign) =>
    set(
      produce((state) => {
        applyScopeAssignToTrackingState(state.scopeTrackingState, assign, true);
      })
    ),
  setPosthuntDownloadFailed: (scope) =>
    set(
      produce((state) => {
        setPosthuntScopeStateDownloadFailed(state.scopeTrackingState, scope);
      })
    ),
  playAudioNotif: null,
  setPlayAudioNotif: (playAudioNotif) => set({ playAudioNotif }),
}));

/** Common endpoint for all scope state subscriptions. */
const useSubscribeMultipleEffect = (scopes: SockAPIScope[] | null) => {
  const sockClient = useSockClient();
  const initScopeState = useScopeStateStore((state) => state.initScopeState);
  useEffect(() => {
    if (scopes === null) return;
    initScopeState(scopes);
    return sockClient.addSubscribeMultipleEffect(scopes);
  }, [
    sockClient,
    // Just let it resubscribe every rerender since it's cheap now.
    scopes,
    initScopeState,
  ]);
};

const useScopeTrackingStateForType = <TScopeType extends SockAPIScopeType>(
  scopeType: TScopeType
): SockAPIScopeTrackingStateForType<TScopeType> => {
  return useScopeStateStore((state) =>
    getScopeTrackingStateForTypeReadonly(state.scopeTrackingState, scopeType)
  );
};

export type StateDownloadResult<T> =
  | {
      success: true;
      state: T;
    }
  | {
      success: false;
      refresh: () => void;
      isRefreshing: boolean;
    };

/**
 * If a component newly requests posthunt state, but a previous
 * request for the same state had failed, only retry the download
 * if this amount of time has passed.
 */
const POSTHUNT_STATE_DOWNLOAD_RETRY_INTERVAL_MS = 10 * 1000; // 10s

class PosthuntStateDownloadController {
  downloadLocks: AsyncLockMap;

  constructor() {
    this.downloadLocks = new AsyncLockMap();
  }

  /**
   * Downloads the posthunt state for a given scope into the store,
   * if it doesn't already have an unexpired result stored.
   * Returns whether the download succeeded.
   */
  async downloadStateAsync<TScope extends SockAPIScope>(
    scope: TScope,
    getIsCanceled: () => boolean
  ): Promise<boolean> {
    const scopeId = scopeToString(scope);
    return await this.downloadLocks.acquireAndRunAsync(scopeId, async () => {
      const existingEntry = getScopeTrackingEntryNonReactive(scope, true);
      if (existingEntry?.state !== undefined) return true;
      const timeNow = Date.now();
      if (
        existingEntry?.downloadFailedInfo !== undefined &&
        timeNow - existingEntry.downloadFailedInfo.timestamp <
          POSTHUNT_STATE_DOWNLOAD_RETRY_INTERVAL_MS
      )
        return false;
      try {
        const state = await (
          await fetch(`/static/posthunt-state/${scopeId}.json`)
        ).json();
        if (getIsCanceled()) return false;
        useScopeStateStore.getState().assignForPosthunt({
          scope,
          state,
        });
        return true;
      } catch (err) {
        if (getIsCanceled()) return false;
        useScopeStateStore.getState().setPosthuntDownloadFailed(scope);
        return false;
      }
    });
  }
}

// Use a singleton to coalesce and lock posthunt state downloads.
const posthuntStateDownloadController = new PosthuntStateDownloadController();

type UseScopeStatePosthuntableOpts = {
  hasPosthunt?: boolean;
  forcePosthuntIfNotAdmin?: boolean;
};

const useScopeStateArrayPosthuntable = <TScopeType extends SockAPIScopeType>(
  scopeType: TScopeType,
  scopes: SockAPIScope<TScopeType>[] | null,
  opts: UseScopeStatePosthuntableOpts
): StateDownloadResult<SockAPIScopeState<TScopeType>[]> | null => {
  const { hasPosthunt = false, forcePosthuntIfNotAdmin = false } = opts;
  const [completedDownloadTask, setCompletedDownloadTask] = useState<{
    scopes: SockAPIScope<TScopeType>[];
  } | null>(null);
  const [isFailed, setIsFailed] = useState(false);
  const scopeTrackingState = useScopeTrackingStateForType(scopeType);
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const usePosthunt =
    (forcePosthuntIfNotAdmin && !shouldDisplayAdmin) ||
    (hasPosthunt && settings.isPosthunt);
  useSubscribeMultipleEffect(usePosthunt ? null : scopes);
  const isDownloadComplete =
    completedDownloadTask !== null &&
    scopes !== null &&
    completedDownloadTask.scopes.length === scopes.length &&
    completedDownloadTask.scopes.every(
      (scope, i) => scopeToString(scope) === scopeToString(scopes[i])
    );

  useEffect(() => {
    if (!usePosthunt) return;
    if (scopes === null) return;
    if (isDownloadComplete) return;
    let isCanceled = false;
    Promise.all(
      scopes.map((scope) =>
        posthuntStateDownloadController.downloadStateAsync(
          scope,
          () => isCanceled
        )
      )
    )
      .then((results) => {
        if (isCanceled) return;
        setIsFailed(results.some((res) => !res));
      })
      .finally(() => {
        if (isCanceled) return;
        setCompletedDownloadTask({ scopes });
      });
    return () => {
      isCanceled = true;
    };
  }, [usePosthunt, isDownloadComplete, scopes]);

  if (isFailed)
    return {
      success: false,
      refresh: () => setCompletedDownloadTask(null),
      isRefreshing: completedDownloadTask === null,
    };
  if (scopes === null) return null;

  const scopeState = scopes.map(
    (scope) =>
      scopeTrackingState[scopeToString(scope, usePosthunt)]?.state ?? null
  );
  const availableState = scopeState.flatMap((state) =>
    state === null ? [] : [state]
  );
  if (availableState.length < scopeState.length) return null;
  return { success: true, state: availableState };
};

const useScopeStateArrayResolvablePosthuntable = <
  TScopeType extends SockAPIScopeIdentifierType
>(
  scopeType: TScopeType,
  identifiers: SockAPIScopeIdentifier<TScopeType>[] | null,
  opts: UseScopeStatePosthuntableOpts
): StateDownloadResult<SockAPIScopeState<TScopeType>[]> | null => {
  const scopes = useMemo(
    () =>
      identifiers === null
        ? null
        : identifiers.map(
            (identifier): SockAPIScope<TScopeType> => ({
              type: scopeType,
              ...identifier,
            })
          ),
    [scopeType, identifiers]
  );
  return useScopeStateArrayPosthuntable(scopeType, scopes, opts);
};

export const useScopeStateArrayResolvable = <
  TScopeType extends SockAPIScopeIdentifierType
>(
  scopeType: TScopeType,
  identifiers: SockAPIScopeIdentifier<TScopeType>[] | null
): SockAPIScopeState<TScopeType>[] | null => {
  const result = useScopeStateArrayResolvablePosthuntable(
    scopeType,
    identifiers,
    {}
  );
  if (result === null) return null;
  if (!result.success)
    throw new Error("scope sync should never fail for non-posthunt");
  return result.state;
};

export const useScopeStateResolvablePosthuntable = <
  TScopeType extends SockAPIScopeIdentifierType
>(
  scopeType: TScopeType,
  identifier: SockAPIScopeIdentifier<TScopeType> | null,
  opts: UseScopeStatePosthuntableOpts
): StateDownloadResult<SockAPIScopeState<TScopeType>> | null => {
  const identifiers = useMemo<SockAPIScopeIdentifier<TScopeType>[] | null>(
    () => (identifier === null ? null : [identifier]),
    [identifier]
  );
  const scopeStateArrayResult = useScopeStateArrayResolvablePosthuntable(
    scopeType,
    identifiers,
    opts
  );
  if (scopeStateArrayResult === null) return null;
  if (!scopeStateArrayResult.success) return scopeStateArrayResult;
  return { success: true, state: scopeStateArrayResult.state[0] };
};

/**
 * Use a scope state. The scope type must be constant throughout
 * the lifetime of the component, but the identifier may resolve
 * or change.
 */
export const useScopeStateResolvable = <
  TScopeType extends SockAPIScopeIdentifierType
>(
  scopeType: TScopeType,
  identifier: SockAPIScopeIdentifier<TScopeType> | null
): SockAPIScopeState<TScopeType> | null => {
  const result = useScopeStateResolvablePosthuntable(scopeType, identifier, {});
  if (result === null) return null;
  if (!result.success)
    throw new Error("scope sync should never fail for non-posthunt");
  return result.state;
};

export const useGlobalScopeStatePosthuntable = <
  TScopeType extends Exclude<SockAPIScopeType, SockAPIScopeIdentifierType>
>(
  scopeType: TScopeType,
  enable: boolean,
  opts: UseScopeStatePosthuntableOpts
): StateDownloadResult<SockAPIScopeState<TScopeType>> | null => {
  const scopeArray = useMemo<SockAPIScope<TScopeType>[]>(
    () => [globalScopeHooks[scopeType].identityFunc({ type: scopeType })],
    [scopeType]
  );
  const result = useScopeStateArrayPosthuntable(
    scopeType,
    enable ?? true ? scopeArray : null,
    opts
  );
  if (result === null) return null;
  if (!result.success) return result;
  const state = result.state[0];
  if (state === undefined) throw new Error("expect one state to be synced");
  return { success: true, state };
};

/**
 * Use a scope state for a scope with no identifier.
 * The scope type cannot change throughout the lifetime of the component.
 * Use useScopeStateResolvable instead to use a scope with an identifier.
 */
export const useGlobalScopeState = <
  TScopeType extends Exclude<SockAPIScopeType, SockAPIScopeIdentifierType>
>(
  scopeType: TScopeType,
  /**
   * Flag to support dynamically enabling a scope subscription
   * while unconditionally calling useEffect.
   * If unset, always enable the scope subscription.
   */
  enable?: boolean
): SockAPIScopeState<TScopeType> | null => {
  const result = useGlobalScopeStatePosthuntable(scopeType, enable ?? true, {});
  if (result === null) return null;
  if (!result.success) throw new Error("should never fail for non-posthunt");
  return result.state;
};

/**
 * Only to be used outside of reactive contexts, as this does not
 * trigger updates based on updates from the server.
 */
export const getScopeTrackingEntryNonReactive = <TScope extends SockAPIScope>(
  scope: SockAPIScope,
  isPosthunt?: boolean
): SockAPIScopeTrackingEntry<TScope["type"]> | null => {
  const scopeType: TScope["type"] = scope.type;
  const { scopeTrackingState } = useScopeStateStore.getState();
  return (
    getScopeTrackingStateForTypeReadonly(scopeTrackingState, scopeType)[
      scopeToString(scope, isPosthunt ?? false)
    ] ?? null
  );
};

/**
 * Only to be used outside of reactive contexts, as this does not
 * trigger updates based on updates from the server.
 */
export const getGlobalScopeStateNonReactive = <
  TScopeType extends Exclude<SockAPIScopeType, SockAPIScopeIdentifierType>
>(
  scopeType: TScopeType
): SockAPIScopeState<TScopeType> | null => {
  const entry = getScopeTrackingEntryNonReactive<
    SockAPIScope & { type: TScopeType }
  >({ type: scopeType });
  return entry?.state ?? null;
};

/**
 * Only to be used outside of reactive contexts, as this does not
 * trigger updates based on updates from the server.
 */
export const getScopeStateResolvableNonReactive = <
  TScopeType extends SockAPIScopeIdentifierType
>(
  scopeType: TScopeType,
  identifier: SockAPIScopeIdentifier<TScopeType> | null
): SockAPIScopeState<TScopeType> | null => {
  if (identifier === null) return null;
  const scope: SockAPIScope<TScopeType> = { type: scopeType, ...identifier };
  const entry = getScopeTrackingEntryNonReactive<
    SockAPIScope & { type: TScopeType }
  >(scope);
  return entry?.state ?? null;
};

/**
 * Only to be used outside of reactive contexts, as this does not
 * trigger updates based on updates from the server.
 */
const getTeamIdNonReactive = (): string | null => {
  const sockClient = useServerInteractionStore.getState().getSockClient();
  return sockClient?.getTeamId() ?? null;
};

/**
 * Only to be used outside of reactive contexts, as this does not
 * trigger updates based on updates from the server.
 */
export const getTeamScopeStateNonReactive = (): Readonly<
  SockAPIScopeState<ScopeType.TEAM>
> | null => {
  const teamId = getTeamIdNonReactive();
  return getScopeStateResolvableNonReactive(
    ScopeType.TEAM,
    teamId === null ? null : { teamId }
  );
};

export const useTeamScopeStatePosthuntable = (
  overrideTeamId: string | null | undefined,
  opts: UseScopeStatePosthuntableOpts
): StateDownloadResult<Readonly<SockAPIScopeState<ScopeType.TEAM>>> | null => {
  const ownTeamId = useTeamId();
  const teamId = overrideTeamId === undefined ? ownTeamId : overrideTeamId;
  return useScopeStateResolvablePosthuntable(
    ScopeType.TEAM,
    teamId === null ? null : { teamId },
    opts
  );
};

export const useTeamScopeState = (
  overrideTeamId?: string | null
): Readonly<SockAPIScopeState<ScopeType.TEAM>> | null => {
  const result = useTeamScopeStatePosthuntable(overrideTeamId, {});
  if (result === null) return null;
  if (!result.success)
    throw new Error("scope sync should never fail for non-posthunt");
  return result.state;
};

export const useTeamMembersScopeStatePosthuntable = (
  teamId: string | null,
  opts: UseScopeStatePosthuntableOpts
) => {
  return useScopeStateResolvablePosthuntable(
    ScopeType.TEAM_MEMBERS,
    teamId === null ? null : { teamId },
    opts
  );
};

export const useTeamMembersScopeState = (teamId: string | null) => {
  const result = useTeamMembersScopeStatePosthuntable(teamId, {});
  if (result === null) return null;
  if (!result.success) throw new Error("should never fail for non-posthunt");
  return result.state;
};

export const useTeamAnswerableScopeState = (
  teamId: string | null,
  puzName: string | null
) => {
  return useScopeStateResolvable(
    ScopeType.TEAM_ANSWERABLE,
    teamId === null || puzName === null ? null : { teamId, puzName }
  );
};

export const useTeamGachaScopeState = (teamId: string | null) => {
  return useScopeStateResolvable(
    ScopeType.TEAM_GACHA,
    teamId === null ? null : { teamId }
  );
};

export const useIsHuntOver = (): boolean | null => {
  const teamScopeState = useTeamScopeState();
  return useReactiveTimeout(teamScopeState?.huntEndTime ?? null);
};

export const isPuzzleTeamSolved = (
  puzData: TeamPuzzleSockAPIScopeState
): boolean => {
  return puzData.solveTime !== undefined;
};

/**
 * Only display a story beat as solved if the user has completed
 * it locally, to encourage everyone to see the story.
 */
export const isPuzzleSolved = (
  puzData: TeamPuzzleSockAPIScopeState,
  completedStoryBeats: CompletedStoryBeats
): boolean => {
  // The solved state from the server should always take priority
  // over the local solve state.
  if (!isPuzzleTeamSolved(puzData)) return false;
  // For story beats, only mark as solved in the client if the user
  // has watched it locally.
  if (puzData.isStory ?? false)
    return completedStoryBeats[puzData.puzName] ?? false;
  return true;
};

export const usePuzzleData = (
  puzName: string | null
): TeamPuzzleSockAPIScopeState | null => {
  const teamScopeState = useTeamScopeState();
  if (puzName === null) return null;
  if (teamScopeState === null) return null;
  return teamScopeState.puzzles[puzName] ?? null;
};

export const useIsPuzzleSolved = (puzName: string | null): boolean | null => {
  const completedStoryBeats = useCompletedStoryBeats();
  const teamScopeState = useTeamScopeState();
  if (puzName === null) return null;
  if (teamScopeState === null) return null;
  const puzData = teamScopeState.puzzles[puzName];
  if (puzData === undefined) return false;
  return isPuzzleSolved(puzData, completedStoryBeats);
};

export const useCurrentHopeFromHopeSnapshot = (
  hopeSnapshot: HopeSnapshot | null
) => {
  const [hope, setHope] = useState<number | null>(null);
  useEffect(() => {
    // Put a small delay to reduce error conditions due to
    // a time skew with the server.
    setHope(actualHope(hopeSnapshot, Date.now() - 1000));
    // Recalculate actual hope every second.
    const interval = setInterval(() => {
      setHope(actualHope(hopeSnapshot));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setHope, hopeSnapshot]);
  return hope;
};

export const useCurrentHope = (teamId?: string | null): number | null => {
  const teamScopeState = useTeamScopeState(teamId);
  const hopeSnapshot = teamScopeState?.hopeSnapshot ?? null;
  return useCurrentHopeFromHopeSnapshot(hopeSnapshot);
};

/**
 * Returns all accessible puzzles. Normally, this would be just the
 * unlocked puzzles. For admins and posthunt, this would have
 * all puzzles.
 */
export const useAccessiblePuzzles = (): {
  [puzName: string]: PuzzleMetadata;
} | null => {
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const isHuntAdminAccessible = settings.isPosthunt || shouldDisplayAdmin;
  const teamScopeState = useTeamScopeState();
  const huntAdminScopeState = useGlobalScopeState(
    ScopeType.HUNT_ADMIN,
    isHuntAdminAccessible
  );
  return (
    (isHuntAdminAccessible
      ? huntAdminScopeState?.puzzles
      : teamScopeState?.puzzles) ?? null
  );
};

export const useSortedPuzzles = (): TeamPuzzleSockAPIScopeState[] | null => {
  const teamScopeState = useTeamScopeState();
  const completedStoryBeats = useCompletedStoryBeats();
  return useMemo(() => {
    if (teamScopeState === null) return null;
    return Object.values(teamScopeState.puzzles).sort((puzData1, puzData2) => {
      const solveTime1 = isPuzzleSolved(puzData1, completedStoryBeats)
        ? puzData1.solveTime
        : undefined;
      const solveTime2 = isPuzzleSolved(puzData2, completedStoryBeats)
        ? puzData2.solveTime
        : undefined;

      // Make solved puzzles always appear last.
      const isSolved1 = solveTime1 !== undefined;
      const isSolved2 = solveTime2 !== undefined;
      if (isSolved1 !== isSolved2)
        return (isSolved1 ? 1 : 0) - (isSolved2 ? 1 : 0);

      // Make story beats always appear first.
      const isStory1 = puzData1.isStory;
      const isStory2 = puzData2.isStory;
      if (isStory1 !== isStory2) return (isStory2 ? 1 : 0) - (isStory1 ? 1 : 0);

      // Make the final meta appear first.
      const isBigMeta1 = puzData1.isBigMeta;
      const isBigMeta2 = puzData2.isBigMeta;
      if (isBigMeta1 !== isBigMeta2)
        return (isBigMeta2 ? 1 : 0) - (isBigMeta1 ? 1 : 0);

      // Make celestials appear first.
      const isCelestial1 = puzData1.isCelestial;
      const isCelestial2 = puzData2.isCelestial;
      if (isCelestial1 !== isCelestial2)
        return (isCelestial2 ? 1 : 0) - (isCelestial1 ? 1 : 0);

      // Puzzles don't have a canonical order in the gacha.
      // Sort them by solve/unlock time, most recent first.
      return (
        (solveTime2 ?? puzData2.unlockTime) -
        (solveTime1 ?? puzData1.unlockTime)
      );
    });
  }, [completedStoryBeats, teamScopeState]);
};

export const useSortedHintsForTeamPuzzle = (
  teamId: string | null,
  puzName: string | null,
  latestFirst = false
) => {
  const teamHintsScopeState = useScopeStateResolvable(
    ScopeType.TEAM_HINTS,
    teamId === null ? null : { teamId }
  );
  const hintIdentifiers =
    teamHintsScopeState !== null && puzName !== null
      ? Object.keys(teamHintsScopeState.hints[puzName] ?? {}).map((hintId) => ({
          hintId,
        }))
      : null;
  const hintsScopeState = useScopeStateArrayResolvable(
    ScopeType.HINT,
    hintIdentifiers
  );
  if (hintIdentifiers === null || hintsScopeState === null) return null;
  return arrayZipMap(
    hintIdentifiers,
    hintsScopeState,
    ({ hintId }, hint): [string, SockAPIScopeState<ScopeType.HINT>] => [
      hintId,
      hint,
    ]
  ).sort(([hintId1, hint1], [hintId2, hint2]) => {
    const timestampDiff = hint1.request.timestamp - hint2.request.timestamp;
    return latestFirst ? -timestampDiff : timestampDiff;
  });
};

/**
 * Use all errata for a puzzle.
 * If puzName is null, this gets errata for all puzzles,
 * as well as errata not associated with any puzzles.
 * isPuzNameValid allows us to distinguish this from the case where
 * we have not loaded puzName yet.
 */
export const useErrataIdsForPuzzle = (
  puzName: string | null,
  isPuzNameValid: true
):
  | {
      erratumId: string;
    }[]
  | null => {
  const teamId = useTeamId();
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const teamErrataScopeState = useScopeStateResolvable(
    ScopeType.TEAM_ERRATA,
    teamId === null ? null : { teamId }
  );
  const huntAdminScopeState = useGlobalScopeState(
    ScopeType.HUNT_ADMIN,
    shouldDisplayAdmin
  );
  const errata = huntAdminScopeState?.errata ?? teamErrataScopeState ?? null;
  return useMemo(
    () =>
      !isPuzNameValid || errata === null
        ? null
        : Object.entries(errata)
            .filter(
              ([erratumId, { puzName: erratumPuzName }]) =>
                puzName === null ||
                (erratumPuzName !== null && erratumPuzName === puzName)
            )
            .sort(
              (
                [erratumId1, { timestamp: timestamp1 }],
                [erratumId2, { timestamp: timestamp2 }]
              ) => timestamp1 - timestamp2
            )
            .map(([erratumId, summary]) => ({
              erratumId,
            })),
    [errata, isPuzNameValid, puzName]
  );
};

export const useErrataContentForPuzzle = (
  puzName: string | null,
  isPuzNameValid: true
):
  | {
      erratumId: string;
      content: SockAPIScopeState<ScopeType.ERRATUM_CONTENT>;
    }[]
  | null => {
  const contentIdentifiers = useErrataIdsForPuzzle(puzName, isPuzNameValid);
  const contents = useScopeStateArrayResolvable(
    ScopeType.ERRATUM_CONTENT,
    contentIdentifiers
  );
  if (contentIdentifiers === null || contents === null) return null;
  return arrayZipMap(
    contentIdentifiers,
    contents,
    ({ erratumId }, content) => ({
      erratumId,
      content,
    })
  );
};

export const useCelestialsAsSequence = ():
  | TeamPuzzleSockAPIScopeState[]
  | null => {
  const teamScopeState = useTeamScopeState();
  const puzzles = teamScopeState?.puzzles ?? null;
  if (puzzles === null) return null;
  return Object.values(puzzles)
    .filter(
      ({ isCelestial = false, isBigMeta = false }) => isCelestial || isBigMeta
    )
    .sort((puz1, puz2) => {
      const isBigMeta1 = puz1.isBigMeta ?? false;
      const isBigMeta2 = puz2.isBigMeta ?? false;
      const diffIsBigMeta = (isBigMeta1 ? 1 : 0) - (isBigMeta2 ? 1 : 0);
      if (diffIsBigMeta !== 0) return diffIsBigMeta;
      return puz1.unlockTime - puz2.unlockTime;
    });
};

export const usePuzzlesInSequence = (
  sequenceId: string | null
): PuzzleMetadata[] | null => {
  const puzzles = useAccessiblePuzzles();
  if (sequenceId === null) return null;
  if (puzzles === null) return null;
  return Object.values(puzzles)
    .filter(
      (puzData) =>
        puzData.sequenceId !== undefined && puzData.sequenceId === sequenceId
    )
    .sort((puz1Metadata, puz2Metadata) => {
      const puz1 = {
        unlockTime: 0,
        order: 0,
        ...puz1Metadata,
      };
      const puz2 = {
        unlockTime: 0,
        order: 0,
        ...puz2Metadata,
      };
      const isMeta1 = puz1.isMeta ?? false;
      const isMeta2 = puz2.isMeta ?? false;
      // Sort metas last.
      const diffIsMeta = (isMeta1 ? 1 : 0) - (isMeta2 ? 1 : 0);
      if (diffIsMeta !== 0) return diffIsMeta;
      const diffUnlockTime = puz1.unlockTime - puz2.unlockTime;
      if (diffUnlockTime !== 0) return diffUnlockTime;
      return puz1.order - puz2.order;
    });
};

export const useNumTotalHintTokens = (teamId: string | null): number | null => {
  const teamHintsScopeState = useScopeStateResolvable(
    ScopeType.TEAM_HINTS,
    teamId === null ? null : { teamId }
  );
  return teamHintsScopeState?.numTotalTokens ?? null;
};

export const useNumUsedHintTokens = (teamId: string | null): number | null => {
  const teamHintsScopeState = useScopeStateResolvable(
    ScopeType.TEAM_HINTS,
    teamId === null ? null : { teamId }
  );
  if (teamHintsScopeState === null) return null;
  return getNumUsedHintTokensFromScopeState(teamHintsScopeState);
};

export const usePuzzleDisplayName = (
  puzName: string | null,
  overrideTeamId?: string | null
): string | null => {
  const ownTeamId = useTeamId();
  const teamId = overrideTeamId === undefined ? ownTeamId : overrideTeamId;
  const teamScopeState = useTeamScopeState(teamId);
  const customTitles =
    useScopeStateResolvable(
      ScopeType.TEAM_TITLES,
      teamId !== null ? { teamId } : null
    )?.titles ?? null;
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const puzzles = useAccessiblePuzzles();
  const puzData = puzName !== null ? puzzles?.[puzName] ?? null : null;

  if (puzName === null) return null;
  if (puzData === null) return null;
  if (customTitles === null) return null;

  return getPuzzleDisplayName(
    puzName,
    puzData.displayName,
    // Always use the team's indices if they exist
    // since admin puzData doesn't come with indices.
    teamScopeState?.puzzles[puzName]?.untitledPuzzleIndex,
    customTitles[puzName],
    shouldDisplayAdmin
  );
};

export const usePuzzleDisplayNames = (
  overrideTeamId?: string | null
): {
  [puzName: string]: string;
} | null => {
  const ownTeamId = useTeamId();
  const teamId = overrideTeamId === undefined ? ownTeamId : overrideTeamId;
  const teamScopeState = useTeamScopeState(teamId);
  const customTitles =
    useScopeStateResolvable(
      ScopeType.TEAM_TITLES,
      teamId !== null ? { teamId } : null
    )?.titles ?? null;
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const puzzles = useAccessiblePuzzles();

  if (puzzles === null) return null;
  if (customTitles === null) return null;

  return Object.fromEntries(
    Object.entries(puzzles).map(([puzName, { displayName }]) => [
      puzName,
      getPuzzleDisplayName(
        puzName,
        displayName,
        // Always use the team's indices if they exist
        // since admin puzData doesn't come with indices.
        teamScopeState?.puzzles[puzName]?.untitledPuzzleIndex,
        customTitles[puzName],
        shouldDisplayAdmin
      ),
    ])
  );
};

export const useLeaderboardNumSolves = () => {
  const teamId = useTeamId();
  const teamScopeState = useTeamScopeState();
  const puz105State = useScopeStateResolvable(
    ScopeType.TEAM_PUZZLE_105,
    teamId === null ? null : { teamId }
  );

  if (teamId === null || teamScopeState === null || puz105State === null)
    return null;
  const { puzzles } = teamScopeState;
  const puz105Status = puz105State.status;
  return (
    Object.values(puzzles).filter(
      (puzData) =>
        isUsedForSolveCount(puzData) && puzData.solveTime !== undefined
    ).length +
    (puz105Status !== undefined &&
    puz105Status.type === Puzzle105StatusType.ACCEPTED &&
    puz105Status.isAvailableInGacha
      ? 1
      : 0)
  );
};
