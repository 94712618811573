export class EventController<T> {
  listeners: Map<number, (evData: T) => void>;
  nextListenerId: number;

  constructor() {
    this.listeners = new Map();
    this.nextListenerId = 0;
  }

  private genListenerId(): number {
    const listenerId = this.nextListenerId;
    this.nextListenerId++;
    return listenerId;
  }

  /** Adds a listener too the event. Returns a cleanup function. */
  addListener(func: (evData: T) => void): () => void {
    const listenerId = this.genListenerId();
    this.listeners.set(listenerId, func);
    return () => {
      this.listeners.delete(listenerId);
    };
  }

  fire(evData: T): void {
    for (const func of this.listeners.values()) {
      func(evData);
    }
  }

  async waitAsync(): Promise<T> {
    return await new Promise((resolve, reject) => {
      const removeListener = this.addListener((evData) => {
        resolve(evData);
        removeListener();
      });
    });
  }

  /** Waits until the provided predicate returns a non-null value. */
  async waitForAsync<TRet extends NonNullable<unknown>>(
    pred: (evData: T) => TRet | null
  ): Promise<TRet> {
    return await new Promise((resolve, reject) => {
      const removeListener = this.addListener((evData) => {
        const ret = pred(evData);
        if (ret === null) return;
        resolve(ret);
        removeListener();
      });
    });
  }
}
