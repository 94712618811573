import { formatDuration } from "util/util";
import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export const SERVER_HEALTH_CONFIG = {
  healthMonitoringIntervalMs: 6000,
  cursorEventsMonitoringIntervalMs: 3000,
  maxNumMiscLockContentions: 10,
};

export type IntervalMetricsSummary = {
  /** Just pass the interval length in to make reporting simpler. */
  intervalLength: number;
  totNumEvents: number;
  lastEventTime: number;
  numPerInterval: number;
};

export const getIntervalMetricsSummaryString = (
  metrics: IntervalMetricsSummary,
  timeNow?: number
): string => {
  const lastEventTimeStr =
    metrics.lastEventTime === 0
      ? "N/A"
      : `${formatDuration(
          (timeNow ?? Date.now()) - metrics.lastEventTime
        )} ago`;
  return `${metrics.numPerInterval} / ${metrics.intervalLength / 1000}s (tot: ${
    metrics.totNumEvents
  }) (last: ${lastEventTimeStr})`;
};

export enum DirtyStateType {
  /** The resource is new, so it doesn't need to be synced. */
  NEW = "new",
  /** The resource needs to be synced. */
  DIRTY = "dirty",
  /** The resource is currently being synced. */
  SYNCING = "syncing",
  /** The resource failed to sync. */
  FAILED = "failed",
  /** The resource is synced. */
  SYNCED = "synced",
}

export type DirtyState = {
  type: DirtyStateType;
  lastSyncTime: number;
};

export type ServerDirtyState = {
  teams: {
    [teamId: string]: {
      fastSync?: DirtyState;
      misc?: DirtyState;
    };
  };
};

export type ServerDirtyStateUpdate = {
  teams: {
    [teamId: string]: {
      fastSync?: DirtyState;
      misc?: DirtyState;
    };
  };
};

export const mergeServerDirtyStateUpdate = (
  upd1: ServerDirtyStateUpdate,
  upd2: ServerDirtyStateUpdate
) => {
  for (const [teamId, teamUpds2] of Object.entries(upd2.teams)) {
    const teamUpds1 = upd1.teams[teamId];
    if (teamUpds1 === undefined) {
      upd1.teams[teamId] = teamUpds2;
      continue;
    }
    if (teamUpds2.fastSync !== undefined)
      teamUpds1.fastSync = teamUpds2.fastSync;
    if (teamUpds2.misc !== undefined) teamUpds1.misc = teamUpds2.misc;
  }
};

export const updateServerDirtyState = (
  state: ServerDirtyState,
  upd: ServerDirtyStateUpdate
) => {
  // Currently, the state and update have the same type signature,
  // so we can just reuse the merge function.
  mergeServerDirtyStateUpdate(state, upd);
};

export type MiscLockContention = {
  id: string;
  numActive: number;
};

export type ServerHealthReport = {
  fastSyncMetrics: IntervalMetricsSummary;
  slowSyncMetrics: IntervalMetricsSummary;
  checkAnswerMetrics: IntervalMetricsSummary;
  numAuthsMetrics: IntervalMetricsSummary;
  numRequestsMetrics: IntervalMetricsSummary;

  teamNumAuthsMetrics: { [teamId: string]: IntervalMetricsSummary };
  teamNumRequestsMetrics: { [teamId: string]: IntervalMetricsSummary };

  fastSyncQueueLength: number;
  numActivePeriodicTasksHandlers: number;
  numActiveConns: number;
  downloadLockContention: number;
  syncLockContention: number;
  farmerLocksContention: number;
  userTimeUsage: number;
  systemTimeUsage: number;

  /**
   * A report of the most contended locks in decreasing order
   * of contention.
   */
  miscLocks: MiscLockContention[];
};

export type ServerHealthScopeSpec = {
  state: {
    report: ServerHealthReport;
    dirtyState: ServerDirtyState;
  };
  update: {
    report: ServerHealthReport;
    dirtyStateUpd: ServerDirtyStateUpdate;
  };
};

export const serverHealthScopeHooks: ScopeHooks<ScopeType.SERVER_HEALTH> = {
  identityFunc: (scope) => scope,
  update: (scopeState, payload) => {
    const { report, dirtyStateUpd } = payload;
    scopeState.report = report;
    updateServerDirtyState(scopeState.dirtyState, dirtyStateUpd);
  },
  disableClientCache: true,
};
