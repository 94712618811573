import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

export const OLIVE_CONFIG = {
  puzName: "olive",
  grid1Length: 27,
  grid2Width: 17,
  grid2Height: 17,
  maxWishes: 8,
};

export type TeamOliveGridState = {
  grid1: (string | null)[];
  grid2: (string | null)[];
};

type TeamOliveProgressState = {
  numWishes: number;
  numTotalGrid1Tokens: number;
  numTotalGrid2Tokens: number;
};

export type TeamOliveScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: TeamOliveGridState & TeamOliveProgressState;
  update: {
    setGrid1?: { [cellIndex: number]: string };
    setGrid2?: { [cellIndex: number]: string };
    numWishes?: number;
    numTotalGrid1Tokens?: number;
    numTotalGrid2Tokens?: number;
  };
  step: {
    revealGrid1?: number;
    revealGrid2?: number;
  };
  stepResponse: "";
  backendState: {
    grid1Reveals: number[];
    grid2Reveals: number[];
  };
};

export const teamOliveScopeHooks: ScopeHooks<ScopeType.TEAM_OLIVE> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const {
      setGrid1,
      setGrid2,
      numWishes,
      numTotalGrid1Tokens,
      numTotalGrid2Tokens,
    } = payload;
    for (const [index, val] of Object.entries(setGrid1 ?? {})) {
      scopeState.grid1[Number(index)] = val;
    }
    for (const [index, val] of Object.entries(setGrid2 ?? {})) {
      scopeState.grid2[Number(index)] = val;
    }
    if (numWishes !== undefined) scopeState.numWishes = numWishes;
    if (numTotalGrid1Tokens !== undefined)
      scopeState.numTotalGrid1Tokens = numTotalGrid1Tokens;
    if (numTotalGrid2Tokens !== undefined)
      scopeState.numTotalGrid2Tokens = numTotalGrid2Tokens;
  },
};
