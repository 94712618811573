import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import {
  PuzzleSubmissionType,
  AdminQueueTaskType,
} from "sockapi/scope-specs/admin-queue-task-spec";

export type AdminQueueTaskSummary = {
  /**
   * Timestamp for when the task was opened. If the task is closed
   * and then reopened, this tracks the last time the task was opened.
   */
  requestTime: number;
  /**
   * Timestamp for when the task was responded to. This can be
   * used to check if the task is open.
   */
  respondTime?: number;
} & (
  | {
      type: AdminQueueTaskType.HINT;
      teamId: string;
      puzName: string;
    }
  | {
      type: AdminQueueTaskType.PUZZLE_SUBMISSION;
      submissionType: PuzzleSubmissionType;
      teamId: string;
      isPublicCustomPuzzle?: true;
      isImagePrivate?: true;
      isPrivate?: true;
    }
);
export type AdminQueuePuzzleSubmissionTaskSummary = AdminQueueTaskSummary & {
  type: AdminQueueTaskType.PUZZLE_SUBMISSION;
};

export type AdminQueueScopeSpec = {
  state: {
    tasks: { [taskId: string]: AdminQueueTaskSummary };
  };
  update: {
    setTasks?: { [taskId: string]: AdminQueueTaskSummary };
  };
};

export const adminQueueScopeHooks: ScopeHooks<ScopeType.ADMIN_QUEUE> = {
  identityFunc: (scope) => scope,
  update: (scopeState, payload) => {
    const { setTasks } = payload;
    for (const [taskId, taskSummary] of Object.entries(setTasks ?? {})) {
      scopeState.tasks[taskId] = taskSummary;
    }
  },
};
