import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { PUZZLE_105_CONFIG } from "sockapi/scope-specs/team-puzzle-105-spec";
import { PuzzleMetadata } from "sockapi/puzzle-data";
import { HopeSnapshot } from "sockapi/gacha/hope";

export const getIsTeamConditionSatisfied = (
  cond: string,
  teamState: {
    cutsceneChoices: { [keyframeId: string]: number };
  }
): boolean => {
  const { cutsceneChoices } = teamState;
  const moonickCutsceneChoice =
    cutsceneChoices[PUZZLE_105_CONFIG.cutsceneChoice.keyframeId];
  switch (cond) {
    case "moonick-free-puzzle":
      return (
        moonickCutsceneChoice !== undefined &&
        moonickCutsceneChoice ===
          PUZZLE_105_CONFIG.cutsceneChoice.freePuzzleIndex
      );
    case "moonick-free-moonick":
      return (
        moonickCutsceneChoice !== undefined &&
        moonickCutsceneChoice ===
          PUZZLE_105_CONFIG.cutsceneChoice.freeMoonickIndex
      );
    case "not-moonick-free-puzzle":
      return (
        moonickCutsceneChoice === undefined ||
        moonickCutsceneChoice !==
          PUZZLE_105_CONFIG.cutsceneChoice.freePuzzleIndex
      );
    case "not-moonick-free-moonick":
      return (
        moonickCutsceneChoice === undefined ||
        moonickCutsceneChoice !==
          PUZZLE_105_CONFIG.cutsceneChoice.freeMoonickIndex
      );
  }
  throw new Error(`unhandled condition ${cond}`);
};

/**
 * Information about unlocked puzzles that a client might
 * need. Should not contain spoilers about puzzles not yet unlocked.
 */
export type TeamPuzzleSockAPIScopeState = PuzzleMetadata & {
  /** Timestamp of when the team unlocked the puzzle. */
  unlockTime: number;
  /** If set, the puzzle has not been seen by the team. */
  isNew?: true;
  /**
   * Timestamp of when the team solved the puzzle. Should always be
   * set if solved, since the client uses whether or not this
   * is set to check if the puzzle is solved.
   */
  solveTime?: number;
  /**
   * Whether the puzzle accepts an answer submission. This is set whether
   * or not the puzzle is solved.
   */
  hasAnswer?: true;
  /**
   * The canonical answer to the puzzle. Only set for answerables if
   * the team has solved the puzzle.
   */
  answer?: string;
  stats?: {
    unlockCount: number;
    solveCount: number;
  };
  /** The team-specific index to disambiguate the puzzle if untitled. */
  untitledPuzzleIndex?: number;

  areHintsDisabledUntilAllCannedHintsFound?: true;

  isScrambleSequel?: true;
  noCannedHints?: true;
  hasMultipleAnswers?: true;
};

/** The verb to use to communicate that a puzzle was completed. */
export const getCompletionVerb = (
  puzzle: TeamPuzzleSockAPIScopeState
): string => {
  if (puzzle.isStory ?? false) return "Completed";
  // TODO: if we have non-solution answerables, we might want to give them different verbs
  return "Solved";
};

export type SockAPITeamFinishedData = {
  /** Number of puzzles used for the solve count. */
  numSolveCountPuzzles: number;
  /** Number of custom puzzles available in the gacha. */
  numCustomPuzzles: number;
};

export type TeamScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    displayName: string;
    /** Details about puzzles unlocked by the team. */
    puzzles: { [puzName: string]: TeamPuzzleSockAPIScopeState };
    /** When the hunt started. */
    huntStartTime: number;
    /** When the hunt ends. */
    huntEndTime: number;
    /** Hope earned by the team, as of the timestamp. */
    hopeSnapshot: HopeSnapshot | null;
    /** State revealed to the team only after finishing. */
    finishedData?: SockAPITeamFinishedData;
    /** Select cutscene choices to share with the client. */
    cutsceneChoices: { [keyframeId: string]: number };
    /**
     * Solved puzzles without accessible solutions.
     * This should be updated atomically with puzzle solve state.
     */
    lockedSolutions: { [puzName: string]: true };
    extraGuesses?: { [puzName: string]: number };
  };
  update: {
    displayName?: string;
    /** Solve times to set/unset. */
    solveTimes?: { [puzName: string]: number | null };
    /** Canonical answers to reveal. */
    answers?: { [puzName: string]: string };
    unlocks?: { [puzName: string]: TeamPuzzleSockAPIScopeState | null };
    updPuzzles?: {
      [puzName: string]: {
        isNew?: boolean;
      };
    };
    hopeSnapshot?: HopeSnapshot | null;
    finishedData?: SockAPITeamFinishedData | null;
    cutsceneChoices?: { [keyframeId: string]: number };
    /** Overrides the entire locked solutions object. */
    lockedSolutions?: { [puzName: string]: true };
    extraGuesses?: { [puzName: string]: number };
  };
};

export const teamScopeHooks: ScopeHooks<ScopeType.TEAM> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const {
      displayName,
      solveTimes,
      answers,
      unlocks,
      updPuzzles,
      hopeSnapshot,
      finishedData,
      cutsceneChoices,
      lockedSolutions,
      extraGuesses,
    } = payload;
    const { puzzles } = scopeState;
    if (displayName !== undefined) scopeState.displayName = displayName;
    for (const [puzName, puzData] of Object.entries(unlocks ?? {})) {
      if (puzData === null) delete puzzles[puzName];
      else puzzles[puzName] = puzData;
    }
    for (const [puzName, solveTime] of Object.entries(solveTimes ?? {})) {
      if (solveTime === null) {
        if (puzzles[puzName] !== undefined) {
          delete puzzles[puzName].solveTime;
          // Also delete the answer if any, since this is effectively
          // telling us to unsolve the puzzle.
          delete puzzles[puzName].answer;
        }
      } else {
        if (puzzles[puzName] !== undefined)
          puzzles[puzName].solveTime = solveTime;
      }
    }
    for (const [puzName, answer] of Object.entries(answers ?? {})) {
      if (puzzles[puzName] !== undefined) puzzles[puzName].answer = answer;
    }
    const getPuzData = (puzName: string) => {
      const puzData = puzzles[puzName];
      if (puzData === undefined)
        throw new Error(`trying to update ${puzName} but it isn't unlocked`);
      return puzData;
    };
    for (const [puzName, puzUpd] of Object.entries(updPuzzles ?? {})) {
      const { isNew } = puzUpd;
      const puzData = getPuzData(puzName);
      if (isNew !== undefined) {
        if (isNew) puzData.isNew = true;
        else delete puzData.isNew;
      }
    }
    if (hopeSnapshot !== undefined) scopeState.hopeSnapshot = hopeSnapshot;
    if (finishedData !== undefined) {
      if (finishedData !== null) scopeState.finishedData = finishedData;
      else delete scopeState.finishedData;
    }
    scopeState.cutsceneChoices = Object.assign(
      scopeState.cutsceneChoices ?? {},
      cutsceneChoices ?? {}
    );
    if (lockedSolutions !== undefined)
      scopeState.lockedSolutions = lockedSolutions;
    scopeState.extraGuesses = Object.assign(
      scopeState.extraGuesses ?? {},
      extraGuesses ?? {}
    );
  },
};
