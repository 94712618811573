import { SockAPIScopeTrackingState } from "sockapi/scope-state";
import { SockClient } from "sockapi/client/SockClient";

type ToastrToastOpts = {
  toastClass?: string;
  timeOut?: number;
  extendedTimeOut?: number;
  closeButton?: boolean;
  onclick?: () => void;
};
type ToastrToastFunc = (
  msg: string,
  title?: string,
  opts?: ToastrToastOpts
) => void;

const Globals = window as unknown as {
  djangoBaseUrl?: string;
  wsEndpoint?: string;
  cursorsWsEndpoint?: string;
  isPosthunt?: false;
  hasAdminAccess?: boolean;
  isImpersonate?: boolean;
  teamId?: string;
  slug?: string;
  isWrapupPageVisible?: boolean;
  viewedTeam?: {
    teamId: string;
    members: string[];
  };
  toastr: {
    options: {
      hideDuration: number;
      timeOut: number;
      extendedTimeOut: number;
      closeButton: boolean;
      positionClass: string;
    };
    success: ToastrToastFunc;
    warning: ToastrToastFunc;
    error: ToastrToastFunc;
    info: ToastrToastFunc;
  };
  showNotify: (data: {
    title: string;
    text: string;
    link?: string;
    isImportant?: boolean;
    shortTimeout?: boolean;
  }) => void;
  handleClipboardButton: () => void;
  Color: {
    new (color: string): {
      range: (color: string) => (val: number) => string;
    };
  };

  // Debug backdoors. Do not use this for anything functional;
  // these just let us view the state in the browser.
  sockClient?: SockClient;
  scopeTrackingState?: SockAPIScopeTrackingState;
  sockServer?: unknown;
};

const NOTIF_LONG_TIMEOUT = 15000;
const NOTIF_SHORT_TIMEOUT = 3000;

Globals.showNotify = (data) => {
  const { title, text, link, isImportant, shortTimeout = false } = data;
  const timeout = shortTimeout ? NOTIF_SHORT_TIMEOUT : NOTIF_LONG_TIMEOUT;
  Globals.toastr.info(text, title, {
    toastClass: isImportant ?? false ? "toast-important" : undefined,
    onclick: link ? () => window.open(link, "_blank") : undefined,
    timeOut: timeout,
    extendedTimeOut: timeout,
  });
};

Globals.toastr.options.hideDuration = 500;
Globals.toastr.options.timeOut = NOTIF_SHORT_TIMEOUT;
Globals.toastr.options.extendedTimeOut = NOTIF_SHORT_TIMEOUT;
Globals.toastr.options.closeButton = true;
Globals.toastr.options.positionClass = "toast-bottom-right";

export default Globals;
export const { toastr, showNotify } = Globals;
