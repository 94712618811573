import { SockAPIError } from "sockapi/errors";
import { SockAPIScope } from "sockapi/scope-state";
import { SockAPIScopeAssign, SockAPIScopeUpdate } from "sockapi/scope-updates";
import {
  SockAPIScopeStep,
  SockAPIScopeStepResponse,
} from "sockapi/scope-steps";
import { HuntNotification } from "sockapi/client-notifications";
import { CursorResp, CursorEvent } from "sockapi/cursors-messages";

export enum WSReqType {
  AUTH = "auth",

  SCOPE_STEP = "scope_step",

  VIEW_PUZZLE = "view_puzzle",

  SET_CURSOR_GROUP = "set_cursor_group",
  CURSOR_EVENT = "cursor_event",

  UPDATE_SUBSCRIPTIONS = "update_subscriptions",
  PING = "ping",
}

type WSReqAuth = {
  type: WSReqType.AUTH;
  jwt: string;
};

type WSReqScopeStep = {
  type: WSReqType.SCOPE_STEP;
  step: SockAPIScopeStep;
  /**
   * If supplied, will be echoed back by response updates to identify
   * which step the response corresponds to, so that the client can
   * wait on it.
   */
  stepId?: string;
};

type WSReqViewPuzzle = {
  type: WSReqType.VIEW_PUZZLE;
  puzName: string;
};

type WSReqSetCursorGroup = {
  type: WSReqType.SET_CURSOR_GROUP;
  reqId: number;
  cursorGroupName: string;
};

type WSReqCursorEvent = {
  type: WSReqType.CURSOR_EVENT;
  event: CursorEvent;
};

export enum WSReqUpdateSubscriptionRequestType {
  ADD = "add",
  REMOVE = "remove",
}

type WSReqUpdateSubscriptions = {
  type: WSReqType.UPDATE_SUBSCRIPTIONS;
  upds: (
    | {
        type: WSReqUpdateSubscriptionRequestType.ADD;
        scope: SockAPIScope;
        tag?: string;
      }
    | {
        type: WSReqUpdateSubscriptionRequestType.REMOVE;
        scope: SockAPIScope;
      }
  )[];
};

type WSReqPing = {
  type: WSReqType.PING;
};

export type WSReq = Readonly<
  | WSReqAuth
  | WSReqScopeStep
  | WSReqViewPuzzle
  | WSReqSetCursorGroup
  | WSReqCursorEvent
  | WSReqUpdateSubscriptions
  | WSReqPing
>;

export enum WSRespType {
  ERROR = "error",
  AUTH_SUCCESS = "auth_success",

  SCOPE_ASSIGN = "scope_assign",
  SCOPE_UPDATE = "scope_update",
  SCOPE_STEP_RESPONSE = "scope_step_response",

  NOTIFICATION = "notification",

  SET_CURSOR_GROUP_ACK = "set_cursor_group_ack",
  CURSOR_EVENT = "cursor_event",

  UPDATE_SUBSCRIPTIONS_ERRORS = "update_subscriptions_errors",
  PONG = "pong",
}

type WSRespError = {
  type: WSRespType.ERROR;
  err: SockAPIError;
  /**
   * The step the server was handling when the error
   * was emitted, if any.
   */
  stepId?: string;
};

export type SockAPIAuthSuccessResult = {
  teamId: string;
};

type WSRespAuthSuccess = {
  type: WSRespType.AUTH_SUCCESS;
  result: SockAPIAuthSuccessResult;
};

type WSRespScopeAssign = {
  type: WSRespType.SCOPE_ASSIGN;
  assign: SockAPIScopeAssign;
};

type WSRespScopeUpdate = {
  type: WSRespType.SCOPE_UPDATE;
  upd: SockAPIScopeUpdate;
};

type WSRespScopeStepResponse = {
  type: WSRespType.SCOPE_STEP_RESPONSE;
  stepResponse: SockAPIScopeStepResponse;
  /** The step this response is for. */
  stepId: string;
};

type WSRespNotification = {
  type: WSRespType.NOTIFICATION;
  notif: HuntNotification;
};

type WSRespSetCursorGroupAck = {
  type: WSRespType.SET_CURSOR_GROUP_ACK;
  reqId: number;
};

export type WSRespCursorEvent = {
  type: WSRespType.CURSOR_EVENT;
  events: CursorResp[];
};

export type SockAPIUpdateSubscriptionError = {
  type: WSReqUpdateSubscriptionRequestType;
  scope: SockAPIScope;
  err: SockAPIError;
};

type WSRespUpdateSubscriptionsErrors = {
  type: WSRespType.UPDATE_SUBSCRIPTIONS_ERRORS;
  errors: SockAPIUpdateSubscriptionError[];
};

type WSRespPong = {
  type: WSRespType.PONG;
};

export type WSResp = Readonly<
  | WSRespError
  | WSRespAuthSuccess
  | WSRespScopeAssign
  | WSRespScopeUpdate
  | WSRespScopeStepResponse
  | WSRespNotification
  | WSRespSetCursorGroupAck
  | WSRespCursorEvent
  | WSRespUpdateSubscriptionsErrors
  | WSRespPong
>;
