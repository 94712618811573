import Logo from "images/logo.svg?react";
import { HTMLAttributes } from "react";

// we inline the CSS here because this file needs to be light
export function Spinner({
  size = 24,
  text = "",
  inline = false,
  ...otherProps
}: {
  size?: number;
  text?: string;
  inline?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <span
      {...otherProps}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        alignItems: "center",
        justifyContent: "center",
        position: inline ? undefined : "fixed",
        inset: "0",
        pointerEvents: "none",
        flexGrow: "1",
        ...otherProps.style,
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
.spinnything path {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: innerspinny 2s ease-in-out infinite;
}

@keyframes innerspinny {
  0% { stroke-dasharray: 0 150; stroke-dashoffset: 0; }
  47.5% { stroke-dasharray: 50 150; stroke-dashoffset: 0; }
  95%, 100% { stroke-dasharray: 42 150; stroke-dashoffset: -59; }
}
        `,
        }}
      />
      <Logo width={size} height={size} className="spinnything" />
      {text}
    </span>
  );
}

export function LargeSpinner({ inline }: { inline?: boolean }) {
  return <Spinner size={128} inline={inline} />;
}
