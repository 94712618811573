import { useState, useEffect } from "react";

/**
 * Takes in a timestamp for when the timeout should trigger,
 * or null if there are unsatisfied dependencies.
 * Returns whether the timeout has triggered, updating reactively
 * when the timeout triggers.
 */
export const useReactiveTimeout = (
  triggerTime: number | null
): boolean | null => {
  const [hasTriggered, setHasTriggered] = useState<boolean | null>(() =>
    triggerTime !== null ? Date.now() >= triggerTime : null
  );

  useEffect(() => {
    if (triggerTime === null) {
      setHasTriggered(null);
      return;
    }
    if (Date.now() >= triggerTime) {
      setHasTriggered(true);
      return;
    }
    setHasTriggered(false);

    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    const setTimer = () => {
      const delay = triggerTime - Date.now();
      // Cap at 1h since timeout values have a 32-bit limit.
      setTimeout(checkTrigger, Math.min(delay, 1000 * 60 * 60));
    };
    const checkTrigger = () => {
      if (Date.now() >= triggerTime) {
        setHasTriggered(true);
        timer = undefined;
      } else setTimer();
    };

    setTimer();
    return () => clearTimeout(timer);
  }, [triggerTime]);

  return hasTriggered;
};
