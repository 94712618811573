import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import { SockAPIScopeState } from "sockapi/scope-state";

/**
 * Enum to represent a puzzle submission scope, which may not be
 * one-to-one with puzzles.
 */
export enum PuzzleSubmissionType {
  PUZZLE_105 = "puzzle_105",
  PUZZLE_139 = "puzzle_139",
  PUZZLE_144 = "puzzle_144",
}

export const getPuzzleSubmissionTaskDesc = (
  submissionType: PuzzleSubmissionType
): string => {
  // Omit the default case to make typescript ensure
  // that this is exhaustive.
  switch (submissionType) {
    case PuzzleSubmissionType.PUZZLE_105:
      return "Free Puzzle";
    case PuzzleSubmissionType.PUZZLE_139:
      return "Hug a Tree";
    case PuzzleSubmissionType.PUZZLE_144:
      return "Musical Intuition";
  }
};

export enum AdminQueueTaskType {
  HINT = "hint",
  PUZZLE_SUBMISSION = "puzzle_submission",
}

type AdminQueueTaskDetailsDict = {
  [AdminQueueTaskType.HINT]: { hintId: string };
  [AdminQueueTaskType.PUZZLE_SUBMISSION]: {
    teamId: string;
    submissionType: PuzzleSubmissionType;
  };
};

/**
 * Use "extends infer" to convince Typescript to distribute the union
 * of scope types.
 */
export type AdminQueueTask<
  TTaskType extends AdminQueueTaskType = AdminQueueTaskType
> = TTaskType extends infer TTaskTypeEx
  ? TTaskTypeEx extends AdminQueueTaskType
    ? {
        type: TTaskType;
      } & AdminQueueTaskDetailsDict[TTaskType]
    : never
  : never;

export type AdminQueueTaskClaim = {
  staffId: string;
  claimTime: number;
};

export type AdminTaskNotificationBackendState = {
  /** A discord message ID associated with the notification, if any. */
  messageId?: string;
};

export type AdminQueueTaskScopeSpec = {
  identifier: { taskId: string };
  state: {
    task: AdminQueueTask;
    claim?: AdminQueueTaskClaim;
  };
  update: {
    /** The new claimer, or null if now unclaimed. */
    claim?: AdminQueueTaskClaim | null;
  };
  step: {
    claim?: {
      staffId: string;
      /** If set, forces the claim. */
      force?: true;
    };

    unclaim?: true;
  };
  /** Whether the claim was successful. Always true for unclaim. */
  stepResponse: boolean;
  backendState: SockAPIScopeState<ScopeType.ADMIN_QUEUE_TASK> & {
    notificationBackendState?: AdminTaskNotificationBackendState;
  };
};

export const adminQueueTaskScopeHooks: ScopeHooks<ScopeType.ADMIN_QUEUE_TASK> =
  {
    identityFunc: (scope) => scope,
    getIdComponents: ({ taskId }) => [taskId],
    update: (scopeState, payload) => {
      const { claim } = payload;
      if (claim !== undefined) {
        if (claim === null) delete scopeState.claim;
        else scopeState.claim = claim;
      }
    },
  };
