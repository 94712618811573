import { KeysMatching } from "util/util";
import { ScopeSpecs, scopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export type ScopeTeamIdentifier = {
  teamId: string;
};

export type ScopePuzzleIdentifier = {
  puzName: string;
};

export type ScopeTeamPuzzleIdentifier = ScopeTeamIdentifier &
  ScopePuzzleIdentifier;

type ScopeIdentifierDict = {
  [TScopeType in KeysMatching<
    ScopeSpecs,
    { identifier: unknown }
  >]: ScopeSpecs[TScopeType]["identifier"];
};
export type ScopeIdentifierType = keyof ScopeIdentifierDict;

/**
 * Use "extends infer" to convince Typescript to distribute the union
 * of scope types.
 */
export type ScopeIdentifier<TScopeType extends ScopeIdentifierType> =
  TScopeType extends infer TScopeTypeEx
    ? TScopeTypeEx extends ScopeIdentifierType
      ? ScopeIdentifierDict[TScopeTypeEx]
      : never
    : never;

/**
 * Use "extends infer" to convince Typescript to distribute the union
 * of scope types.
 */
export type Scope<TScopeType extends ScopeType = ScopeType> =
  TScopeType extends infer TScopeTypeEx
    ? TScopeTypeEx extends ScopeType
      ? {
          type: TScopeTypeEx;
        } & (TScopeTypeEx extends ScopeIdentifierType
          ? ScopeIdentifier<TScopeTypeEx>
          : unknown)
      : never
    : never;

export const simplifyScope = <TScopeType extends ScopeType>(
  scope: Scope & { type: TScopeType }
): Scope<TScopeType> => {
  const scopeType: TScopeType = scope.type;
  return scopeHooks[scopeType].identityFunc(scope);
};
const isScopeIdentifierType = (
  scopeType: ScopeType
): scopeType is ScopeIdentifierType => {
  return "getIdComponents" in scopeHooks[scopeType];
};
export const scopeToString = <TScope extends Scope>(
  scope: TScope,
  isPosthunt?: boolean
): string => {
  const scopeType: TScope["type"] = scope.type;
  const posthuntableScopeType = `${
    isPosthunt ?? false ? "posthunt__" : ""
  }${scopeType}`;
  if (!isScopeIdentifierType(scopeType)) return posthuntableScopeType;
  return [
    posthuntableScopeType,
    ...scopeHooks[scopeType].getIdComponents(
      simplifyScope<TScope["type"]>(scope)
    ),
  ]
    .map((val) => val.toString())
    .join("__");
};
