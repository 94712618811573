import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export type PuzzleStatsSummary = {
  order: number;
  unlocks: number;
  solves: number;
  guesses: number;
  hints: number;
  getCannedHints: number;
  openCannedHints: number;
  unhinted: number;
  hinted: number;
  unsolved: number;
};

type HuntStatsCounters = {
  teams: number;
  members: number;
  wishes: number;
  dust: number;
  hintTokens: number;
  fairplayGuesses: number;
  fairplaySolves: number;
};

export type HuntStats = HuntStatsCounters & {
  puzzles: { [puzName: string]: PuzzleStatsSummary };
};

export type HuntStatsUpdate = Partial<HuntStats>;

export type HuntStatsScopeSpec = {
  state: HuntStats;
  update: HuntStatsUpdate;
};

export const huntStatsScopeHooks: ScopeHooks<ScopeType.HUNT_STATS> = {
  identityFunc: (scope) => scope,
  update: (state, payload) => {
    const { puzzles = {}, ...others } = payload;
    for (const [key, delta] of Object.entries(others))
      state[key as keyof HuntStatsCounters] += delta;
    Object.assign(state.puzzles, puzzles);
  },
};
