import Twemoji from "react-twemoji";
import settings from "settings";
import { useErrataIdsForPuzzle } from "stores/ScopeStateStore";
import {
  useShouldDisplayAdmin,
  useServerInteractionStore,
} from "stores/ServerInteractionStore";
import {
  useIsAdminEnabledStore,
  useAdminPreferencesStore,
} from "stores/UserPreferencesStore";
import { DjangoLink, RoutedLink } from "components/Link";
import VolumeControl from "components/VolumeControl";
import Globals from "Globals";

function SiteNavDisplay() {
  const { djangoBaseUrl, hasAdminAccess } = settings;
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const errataIds = useErrataIdsForPuzzle(null, true) ?? [];

  const isAdminEnabled = useIsAdminEnabledStore(
    (state) => state.isAdminEnabled
  );
  const setIsAdminEnabled = useIsAdminEnabledStore(
    (state) => state.setIsAdminEnabled
  );
  const setIsAdminEnabledPref = useAdminPreferencesStore(
    (state) => state.setIsAdminEnabledPref
  );
  const isSpectating = useIsAdminEnabledStore((state) => state.isSpectating);
  const setIsSpectating = useIsAdminEnabledStore(
    (state) => state.setIsSpectating
  );
  const setIsSpectatingPref = useAdminPreferencesStore(
    (state) => state.setIsSpectatingPref
  );

  const sockClient = useServerInteractionStore(
    (state) => state.getSockClient
  )();

  return (
    <>
      <hr />
      <RoutedLink path="/camp" href={`${djangoBaseUrl}camp`}>
        <Twemoji>🏕️ Camp</Twemoji>
      </RoutedLink>
      <RoutedLink path="/teams">Teams</RoutedLink>
      {errataIds.length > 0 ? (
        <RoutedLink path="/errata">Updates</RoutedLink>
      ) : null}
      <DjangoLink href="about">About</DjangoLink>
      <a href="https://galacticpuzzlehunt.com/archive">Archive</a>
      {Globals.isWrapupPageVisible ?? false ? (
        <RoutedLink path="/wrapup">Wrap-Up</RoutedLink>
      ) : null}
      {settings.isPosthunt ? (
        <RoutedLink path="/unlock">Unlocks</RoutedLink>
      ) : null}
      <VolumeControl />
      {hasAdminAccess && sockClient !== null ? (
        <>
          <hr />
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const newIsAdminEnabled = !isAdminEnabled;
              setIsAdminEnabled(newIsAdminEnabled);
              setIsAdminEnabledPref(newIsAdminEnabled);
              sockClient.setIsAdmin(newIsAdminEnabled);
            }}
          >
            <Twemoji tag="span">{isAdminEnabled ? "✅️" : "🔲️"}</Twemoji> Admin
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const newIsSpectating = !isSpectating;
              setIsSpectating(newIsSpectating);
              setIsSpectatingPref(newIsSpectating);
              sockClient.isSpectating = newIsSpectating;
            }}
          >
            <Twemoji tag="span">{isSpectating ? "✅️" : "🔲️"}</Twemoji>{" "}
            Spectate
          </a>
          {shouldDisplayAdmin ? (
            <>
              <RoutedLink path="/sock-admin">Sock Admin</RoutedLink>
              <RoutedLink path="/health">Sock Health</RoutedLink>
              <RoutedLink path="/unlock">Team Unlocks</RoutedLink>
              {/* emojify the most important ones to visually signpost */}
              <RoutedLink path="/admin-queue">
                <Twemoji tag="span">🚨️</Twemoji> Admin Queue
              </RoutedLink>
              <RoutedLink path="/bigboard">
                <Twemoji tag="span">🪧️</Twemoji> Big Board
              </RoutedLink>
              <a
                href="https://docs.google.com/document/d/16u79AHEK0L9UmZN7Iq_IJ-8lY0BJN4V-ZWZ7IWFJBKU/edit"
                target="_blank"
                rel="noreferrer"
              >
                Ops Guide
              </a>
              <DjangoLink href="admin" target="_blank">
                Django Admin
              </DjangoLink>
              <DjangoLink href="bridge" target="_blank">
                Email
              </DjangoLink>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default SiteNavDisplay;
