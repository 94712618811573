import { setup } from "./HuntLib";

import { SockAPIErrorCode } from "sockapi/errors";
import { ScopeType } from "sockapi/scope-types";
import { Scope, simplifyScope } from "sockapi/scopes";
import {
  SockAPIUpdatableScopeType,
  SockAPIUpdatableScope,
  SockAPIScopeUpdatePayload,
} from "sockapi/scope-updates";
import {
  TeamPuzzleSockAPIScopeState,
  getCompletionVerb,
} from "sockapi/scope-specs/team-spec";
import {
  AdminQueueTaskType,
  getPuzzleSubmissionTaskDesc,
} from "sockapi/scope-specs/admin-queue-task-spec";
import { getPuzzleDisplayName } from "sockapi/puzzle-data";
import {
  HuntNotificationType,
  HuntNotification,
} from "sockapi/client-notifications";
import { SockClient } from "sockapi/client/SockClient";
import { BrowserRawWSInterface } from "sock-server/server-interface/BrowserRawWSInterface";
import { InteractiveServerInterface } from "sock-server/server-interface/InteractiveServerInterface";
import {
  ServerInterfaceType,
  NullServerInterface,
} from "sock-server/server-interface/ServerInterface";
import settings, { mockServerOpts } from "settings";
import { useServerInteractionStore } from "stores/ServerInteractionStore";
import {
  useScopeStateStore,
  getTeamScopeStateNonReactive,
  getScopeTrackingEntryNonReactive,
  getGlobalScopeStateNonReactive,
  getScopeStateResolvableNonReactive,
} from "stores/ScopeStateStore";
import {
  useIsAdminEnabledStore,
  useAdminPreferencesStore,
} from "stores/UserPreferencesStore";
import { useGachaStore, isPullInProgress } from "stores/GachaStore";
import {
  shareBrowserWidePersistentState,
  useBrowserWidePersistentStateStore,
  updateBrowserWidePersistentStateNonReactive,
} from "stores/BrowserWidePersistentState";
import { getJwt } from "getJwt";
import Globals, { showNotify, toastr } from "Globals";
import { SharedWorkerInterface } from "sock-server/server-interface/SharedWorkerInterface";

import SharedWorkerServer from "./sharedWorkerServer?sharedworker";

const setupSockClientAsync = async () => {
  const initIsAdmin =
    useAdminPreferencesStore.getState().isAdminEnabledPref &&
    settings.hasAdminAccess;
  const initIsSpectating =
    useAdminPreferencesStore.getState().isSpectatingPref &&
    settings.hasAdminAccess;

  useIsAdminEnabledStore.getState().setIsAdminEnabled(initIsAdmin);
  useIsAdminEnabledStore.getState().setIsSpectating(initIsSpectating);
  useServerInteractionStore.getState().setActiveTeamId(settings.fixedTeamId);
  shareBrowserWidePersistentState();

  const sockClient = new SockClient({
    dumpWsMessages: settings.dumpWsMessages,
    initTeamId: settings.fixedTeamId ?? undefined,
    // In posthunt mode, connect to the server as admin,
    // but make it seem like we're not admin in the UI.
    initIsAdmin: initIsAdmin || settings.isPosthunt,
    initIsSpectating,
    delay: settings.clientDelay,
    getJwtFunc: async (isAdmin, forceRegen) => {
      const teamId = settings.fixedTeamId ?? undefined;
      if (!settings.useJwt)
        return JSON.stringify({
          teamId,
          isAdmin: isAdmin ? true : undefined,
        });
      if (teamId === undefined)
        throw new Error("teamId should be fixed in jwt mode");
      return getJwt(teamId, isAdmin, forceRegen);
    },
    makeServerInterfaceFunc: async (type) => {
      // Disable cursors server this year until we find a need for it.
      if (type === ServerInterfaceType.CURSOR) return new NullServerInterface();

      // Mask out both the shared mock and mock servers entirely
      // in (non-posthunt) prod to avoid including them in the build.
      if (import.meta.env.MODE !== "production") {
        // If shared workers aren't supported, fallback to
        // the raw mock server interface.
        if (settings.useSharedWorker && window.SharedWorker !== undefined) {
          const worker = new SharedWorkerServer();
          return new SharedWorkerInterface(worker, mockServerOpts);
        } else if (settings.localMode) {
          const makeClientMockServerInterface = (
            await import("makeClientMockServerInterface")
          ).default;
          const mockServerInterface = await makeClientMockServerInterface(type);
          Globals.sockServer =
            mockServerInterface.mockServerInterface.sockServer;
          return mockServerInterface;
        }
      }

      const endpoint = ((type: ServerInterfaceType): string | null => {
        switch (type) {
          case ServerInterfaceType.MAIN:
            return settings.wsEndpoint;
          case ServerInterfaceType.CURSOR:
            return settings.cursorsWsEndpoint;
        }
      })(type);

      // Fallback in case we don't have a cursor WS.
      if (!endpoint) {
        console.warn(`[${type}] endpoint not found`);
        return new NullServerInterface();
      }

      return new InteractiveServerInterface({
        rawWsInterface: new BrowserRawWSInterface(endpoint),
      });
    },
    getScopeStateCacheTag: (scope) =>
      getScopeTrackingEntryNonReactive(scope)?.cacheTag,
    directBackendUrl: settings.directBackendUrl,
  });
  Globals.sockClient = sockClient;

  sockClient.onTeamIdChangeEvent.addListener((teamId) => {
    useServerInteractionStore.getState().setActiveTeamId(teamId);
  });

  const getIsAdmin = () => settings.hasAdminAccess && sockClient.isAdmin;

  // Notifications that might be waiting for global state to be updated
  // so that we can resolve and show them.
  const notifsQueue: HuntNotification[] = [];
  const tryGetPuzzleData = (
    puzName: string
  ): TeamPuzzleSockAPIScopeState | null => {
    const teamScopeState = getTeamScopeStateNonReactive();
    if (teamScopeState === null) return null;
    const { puzzles } = teamScopeState;
    return puzzles[puzName] ?? null;
  };
  const tryGetPuzzleNotifInfo = (
    puzName: string
  ): {
    displayName: string;
    link: string;
    completionVerb: string;
    puzData: TeamPuzzleSockAPIScopeState;
  } | null => {
    const puzData = tryGetPuzzleData(puzName);
    const teamId = sockClient.getTeamId();
    const customTitles =
      getScopeStateResolvableNonReactive(
        ScopeType.TEAM_TITLES,
        teamId !== null
          ? {
              teamId,
            }
          : null
      )?.titles ?? null;
    const shouldDisplayAdmin =
      useServerInteractionStore.getState().shouldDisplayAdmin;

    if (puzData === null || customTitles === null) return null;
    const { displayName, slug, untitledPuzzleIndex } = puzData;
    const completionVerb = getCompletionVerb(puzData);

    return {
      displayName: getPuzzleDisplayName(
        puzName,
        displayName,
        untitledPuzzleIndex,
        customTitles[puzName],
        shouldDisplayAdmin
      ),
      link: `${settings.djangoBaseUrl}puzzle/${slug}`,
      completionVerb,
      puzData,
    };
  };

  /**
   * Pop up notifications from the notifs queue as long as we have the
   * global state synced for them.
   */
  const processNotifsQueue = (): void => {
    // Block notifications
    const gachaStoreState = useGachaStore.getState();
    if (isPullInProgress(gachaStoreState)) return;

    while (true) {
      const notif = notifsQueue[0];
      if (notif === undefined) break;
      const notify: typeof showNotify = (data) => {
        showNotify(data);
        useScopeStateStore.getState().playAudioNotif?.(notif);
      };
      const isResolved = ((): boolean => {
        switch (notif.type) {
          case HuntNotificationType.INTERNAL: {
            const { message } = notif;
            toastr.warning(message);
            return true;
          }
          case HuntNotificationType.ADMIN_QUEUE_TASK: {
            const { taskId, taskSummary } = notif;
            // Always return something to make sure that the switch
            // statement is exhaustive.
            return ((): boolean => {
              switch (taskSummary.type) {
                case AdminQueueTaskType.HINT: {
                  notify({
                    title: "Hint request",
                    text: "A hint request was submitted.",
                    link: `${settings.djangoBaseUrl}admin-queue/task/${taskId}`,
                    isImportant: true,
                    shortTimeout: true,
                  });
                  return true;
                }
                case AdminQueueTaskType.PUZZLE_SUBMISSION: {
                  // Omit the default case to make typescript ensure
                  // that this is exhaustive.
                  const desc = getPuzzleSubmissionTaskDesc(
                    taskSummary.submissionType
                  );
                  notify({
                    title: `${desc} submission`,
                    text: `A ${desc} submission is awaiting review.`,
                    link: `${settings.djangoBaseUrl}admin-queue/task/${taskId}`,
                    isImportant: true,
                    shortTimeout: true,
                  });
                  return true;
                }
              }
            })();
          }
          case HuntNotificationType.VICTORY: {
            notify({
              title: "Congratulations!",
              text: "You’ve finished Galactic Puzzle Hunt 2024!",
              link: `${settings.djangoBaseUrl}sunrise`,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.SUBMISSION_REJECTED: {
            const { puzName } = notif;
            const puzNotifInfo = tryGetPuzzleNotifInfo(puzName);
            if (puzNotifInfo === null) return false;
            const { displayName, link } = puzNotifInfo;
            notify({
              title: displayName,
              text: "Your submission was rejected.",
              link,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.HINT_ANSWERED: {
            const { puzName } = notif;
            const puzNotifInfo = tryGetPuzzleNotifInfo(puzName);
            if (puzNotifInfo === null) return false;
            const { displayName, link } = puzNotifInfo;
            notify({
              title: displayName,
              text: "Hint answered!",
              link,
              isImportant: true,
            });
            return true;
          }
          case HuntNotificationType.ERRATUM: {
            notify({
              title: "New erratum",
              text: "A new erratum has been published.",
              link: `${settings.djangoBaseUrl}errata`,
            });
            return true;
          }
          case HuntNotificationType.SOLVE: {
            const { puzName } = notif;

            const puzNotifInfo = tryGetPuzzleNotifInfo(puzName);
            if (puzNotifInfo === null) return false;
            const {
              displayName,
              link,
              completionVerb,
              puzData: { isStory = false },
            } = puzNotifInfo;

            // Don't notify story solves.
            if (isStory) return true;

            notify({
              title: displayName,
              text: `${completionVerb}!`,
              link,
            });
            return true;
          }
          case HuntNotificationType.BEGIN_PULL:
          case HuntNotificationType.END_PULL: {
            return true;
          }
          case HuntNotificationType.UNLOCK: {
            const {
              puzData: { puzName },
            } = notif;

            const puzNotifInfo = tryGetPuzzleNotifInfo(puzName);
            if (puzNotifInfo === null) return false;
            const {
              displayName,
              link,
              puzData: { isStory = false, isCelestial = false },
            } = puzNotifInfo;

            if (isStory) {
              notify({
                title: displayName,
                text: "A new cutscene is available!",
                link,
                isImportant: true,
              });
              return true;
            }

            if (isCelestial) {
              notify({
                title: displayName,
                text: "You found a celestial!",
                link,
              });
              return true;
            }

            notify({
              title: displayName,
              text: "You unlocked a new puzzle!",
              link: puzName === "puz330" ? undefined : link,
            });
            return true;
          }
          // Don't provide a default so that typescript will check
          // that this is exhaustive.
        }
      })();
      if (!isResolved) return;
      notifsQueue.shift();
    }
  };

  const clearLockedCutsceneStateIfNeeded = (scope: Scope) => {
    const ownTeamId = sockClient.getTeamId();
    switch (scope.type) {
      case ScopeType.TEAM: {
        if (scope.teamId !== ownTeamId) break;
        const teamState = getScopeStateResolvableNonReactive(ScopeType.TEAM, {
          teamId: ownTeamId,
        });
        if (teamState === null) break;

        const { puzzles } = teamState;
        const { completedStoryBeats, cutscenesState, pinnedPuzzles } =
          useBrowserWidePersistentStateStore.getState().state;

        const staleStoryBeats = Object.keys(completedStoryBeats).filter(
          (puzName) => puzzles[puzName]?.solveTime === undefined
        );
        if (staleStoryBeats.length > 0)
          updateBrowserWidePersistentStateNonReactive({
            completedStoryBeats: Object.fromEntries(
              staleStoryBeats.map((puzName) => [puzName, false])
            ),
          });

        const staleCutscenes = Object.keys(cutscenesState).filter(
          (puzName) => puzzles[puzName] === undefined
        );
        if (staleCutscenes.length > 0)
          updateBrowserWidePersistentStateNonReactive({
            cutscenesState: Object.fromEntries(
              staleCutscenes.map((puzName) => [puzName, null])
            ),
          });

        if (pinnedPuzzles !== undefined) {
          const newPinnedPuzzles = pinnedPuzzles.filter(
            (puzName) => puzzles[puzName] !== undefined
          );
          if (newPinnedPuzzles.length < pinnedPuzzles.length)
            updateBrowserWidePersistentStateNonReactive({
              pinnedPuzzles: newPinnedPuzzles,
            });
        }

        break;
      }
      case ScopeType.TEAM_BLOCKS: {
        if (scope.teamId !== ownTeamId) break;
        const teamBlocksState = getScopeStateResolvableNonReactive(
          ScopeType.TEAM_BLOCKS,
          {
            teamId: ownTeamId,
          }
        );
        if (teamBlocksState === null) break;

        const { blocksPuzzleBoardsState } =
          useBrowserWidePersistentStateStore.getState().state;
        if (blocksPuzzleBoardsState !== undefined) {
          const staleBoards = Object.keys(blocksPuzzleBoardsState).filter(
            (boardId) => Number(boardId) >= teamBlocksState.boardSpecs.length
          );
          if (staleBoards.length > 0)
            updateBrowserWidePersistentStateNonReactive({
              blocksPuzzleBoardsState: Object.fromEntries(
                staleBoards.map((boardId) => [boardId, null])
              ),
            });
        }

        break;
      }
    }
  };

  sockClient.onScopeAssignEvent.addListener((assign) => {
    useScopeStateStore.getState().applyScopeAssign(assign);
    clearLockedCutsceneStateIfNeeded(assign.scope);
  });

  sockClient.onScopeUpdateEvent.addListener((upd) => {
    useScopeStateStore.getState().applyScopeUpdate(upd);
    clearLockedCutsceneStateIfNeeded(upd.scope);

    // Effects to trigger in response to scope updates.
    const updEffects: {
      [TScopeType in SockAPIUpdatableScopeType]?: (
        scope: Scope<TScopeType>,
        payload: SockAPIScopeUpdatePayload<TScopeType>
      ) => void;
    } = {
      [ScopeType.TEAM]: (scope, payload) => {
        if (scope.teamId !== sockClient.getTeamId()) return;

        const { unlocks, solveTimes } = payload;

        for (const [puzName, solveTime] of Object.entries(solveTimes ?? {})) {
          if (solveTime === null) continue;
          sockClient.onNotificationEvent.fire({
            type: HuntNotificationType.SOLVE,
            puzName,
          });
        }

        for (const [puzName, unlock] of Object.entries(unlocks ?? {})) {
          if (unlock === null) continue;
          sockClient.onNotificationEvent.fire({
            type: HuntNotificationType.UNLOCK,
            puzData: unlock,
          });
        }
      },
      [ScopeType.ADMIN_QUEUE]: (scope, payload) => {
        const { setTasks } = payload;
        const adminQueueState = getGlobalScopeStateNonReactive(
          ScopeType.ADMIN_QUEUE
        );
        if (adminQueueState === null)
          throw new Error("got update for uninitialized admin queue state");
        for (const [taskId, taskSummary] of Object.entries(setTasks ?? {})) {
          if (taskSummary.respondTime === undefined) {
            sockClient.onNotificationEvent.fire({
              type: HuntNotificationType.ADMIN_QUEUE_TASK,
              taskId,
              taskSummary,
            });
          }
        }
      },
    };
    const applyUpdEffect = <TScope extends SockAPIUpdatableScope>(
      scope: TScope,
      payload: SockAPIScopeUpdatePayload<TScope["type"]>
    ) => {
      const scopeType: TScope["type"] = scope.type;
      updEffects[scopeType]?.(simplifyScope(scope), payload);
    };
    applyUpdEffect(upd.scope, upd.payload);
  });

  sockClient.onNotificationEvent.addListener((notif) => {
    switch (notif.type) {
      case HuntNotificationType.BEGIN_PULL: {
        const { pullId } = notif;
        useGachaStore.getState().addServerPullLock(pullId);
        break;
      }
      case HuntNotificationType.END_PULL: {
        // Trigger pull animations here since they shouldn't be blocked
        // by the pull lock.
        const { pullId, pull } = notif;
        if (pull !== undefined)
          useGachaStore.getState().pushToPullsQueue(pullId, pull);
        useGachaStore.getState().removeServerPullLock(pullId);
        break;
      }
    }

    notifsQueue.push(notif);
    processNotifsQueue();
  });

  sockClient.onErrorEvent.addListener((err) => {
    const { errCode } = err;
    toastr.error(`Received error "${errCode}".`);
    switch (errCode) {
      case SockAPIErrorCode.TEAM_INACTIVE: {
        window.location.href = settings.djangoBaseUrl;
        break;
      }
    }
  });

  useScopeStateStore.subscribe(({ scopeTrackingState }) => {
    sockClient.afterUpdateScopeTrackingState(scopeTrackingState);
    Globals.scopeTrackingState = scopeTrackingState;
  });

  useGachaStore.subscribe((gachaStoreState) => {
    processNotifsQueue();
  });

  sockClient.addAuthEffect(() => {
    const setIsAdmin = useServerInteractionStore.getState().setIsAdmin;
    setIsAdmin(getIsAdmin());
    return () => setIsAdmin(null);
  });

  // If admin, maintain subscriptions needed for receiving and
  // rendering admin queue notifications.
  sockClient.addConditionalSubscribeMultipleEffect(() =>
    getIsAdmin()
      ? [
          {
            type: ScopeType.HUNT_ADMIN,
          },
          {
            type: ScopeType.ADMIN_QUEUE,
          },
        ]
      : []
  );

  // Notifications require a persistent subscription to the team
  // scope to work.
  sockClient.addConditionalSubscribeMultipleEffect(() => {
    const teamId = sockClient.getTeamId();
    if (teamId === null) return [];
    return [
      {
        type: ScopeType.TEAM,
        teamId,
      },
      {
        type: ScopeType.TEAM_TITLES,
        teamId,
      },
    ];
  });

  useServerInteractionStore.getState().setSockClient(sockClient);
  await sockClient.connectAsync();
};
setupSockClientAsync().catch(console.error);

setup();
