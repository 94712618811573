import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export type DummyScopeSpec = {
  step: string;
  stepResponse: string;
};

export const dummyScopeHooks: ScopeHooks<ScopeType.DUMMY> = {
  identityFunc: (scope) => scope,
};
