import { create } from "zustand";
import produce from "immer";
import { GachaPull } from "sockapi/gacha/pulls";
import { useUILockWithStore } from "ui-locks";

// Wishing sequence:
// - When the user presses the "wish" button, send the wish request.
// - When the response comes back, set pendingPull  and navigate
//   to the camp page.
// - Unset pendingPull, then start with wishing animation. If the
//   user navigates away and back, they should not see the animation,
//   just the normal camp page.
// - When the animation finishes, move pendingPull to shownPull to
//   show the modal.

export type GachaActivePullAnimation = {
  pullId: string;
  pull: GachaPull;
};

interface GachaStoreState {
  pendingPull: { pullId: string; pull: GachaPull } | null;
  setPendingPull: (
    pendingPull: { pullId: string; pull: GachaPull } | null
  ) => void;

  isPullLockHeld: boolean;
  setIsPullLockHeld: (isPullLockHeld: boolean) => void;

  serverPullLocksHeld: { [pullId: string]: true };
  addServerPullLock: (pullId: string) => void;
  removeServerPullLock: (pullId: string) => void;

  activePullAnimation: GachaActivePullAnimation | null;
  setActivePullAnimation: (
    activePullAnimation: GachaActivePullAnimation | null
  ) => void;

  pullsQueue: { pullId: string; pull: GachaPull }[];
  pushToPullsQueue: (pullId: string, pull: GachaPull) => void;
  /**
   * Remove elements in the pulls queue from the front up to and
   * including the element with the given pullId. If no pullId is
   * specified, empties the entire queue.
   */
  shiftPullsQueue: (pullId?: string) => void;
}

export const useGachaStore = create<GachaStoreState>((set) => ({
  pendingPull: null,
  setPendingPull: (pendingPull) => set({ pendingPull }),

  isPullLockHeld: false,
  setIsPullLockHeld: (isPullLockHeld) => set({ isPullLockHeld }),

  serverPullLocksHeld: {},
  addServerPullLock: (pullId) =>
    set(
      produce((state) => {
        state.serverPullLocksHeld[pullId] = true;
      })
    ),
  removeServerPullLock: (pullId) =>
    set(
      produce((state) => {
        delete state.serverPullLocksHeld[pullId];
      })
    ),

  activePullAnimation: null,
  setActivePullAnimation: (activePullAnimation) => set({ activePullAnimation }),

  pullsQueue: [],
  pushToPullsQueue: (pullId, pull) =>
    set(
      produce((state) => {
        state.pullsQueue.push({ pullId, pull });
      })
    ),
  shiftPullsQueue: (pullId) =>
    set(
      produce((state) => {
        const q: { pullId: string; pull: GachaPull }[] = state.pullsQueue;
        if (pullId === undefined) {
          q.splice(0, state.pullsQueue.length);
          return;
        }
        const idx = q.findIndex((entry) => entry.pullId === pullId);
        if (idx < 0) return;
        q.splice(0, idx + 1);
      })
    ),
}));

export const usePullLock = () => {
  return useUILockWithStore(
    useGachaStore<[boolean, (isHeld: boolean) => void]>((state) => [
      state.isPullLockHeld,
      state.setIsPullLockHeld,
    ])
  );
};

export const isPullInProgress = ({
  isPullLockHeld,
  activePullAnimation,
  pendingPull,
  pullsQueue,
  serverPullLocksHeld,
}: GachaStoreState) => {
  return (
    isPullLockHeld ||
    activePullAnimation !== null ||
    pendingPull !== null ||
    pullsQueue.length > 0 ||
    Object.values(serverPullLocksHeld).some((val) => val)
  );
};

export const useIsPullInProgress = () => {
  return useGachaStore(isPullInProgress);
};
