import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export enum PublicSubmissionType {
  PUBLIC_CUSTOM_PUZZLE = "public_custom_puzzle",
  PRIVATE_CUSTOM_PUZZLE = "private_custom_puzzle",
  HUG_A_TREE = "hug_a_tree",
  HUG_A_TREE_FACT_ONLY = "hug_a_tree_fact_only",
}

export type PublicSubmissionSummary = {
  type: PublicSubmissionType;
  teamId: string;
  respondTime: number;
  displayName?: string;
};

export type PublicSubmissionsScopeSpec = {
  state: {
    submissions: {
      [submissionId: string]: PublicSubmissionSummary;
    };
  };
};

export const publicSubmissionsScopeHooks: ScopeHooks<ScopeType.PUBLIC_SUBMISSIONS> =
  {
    identityFunc: (scope) => scope,
  };
