import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { PUZZLE_105_CONFIG } from "sockapi/scope-specs/team-puzzle-105-spec";

/**
 * keyframeIds of cutscene choices that should be shared with
 * the client.
 */
export const CLIENT_SHARED_CUTSCENE_CHOICES = [
  PUZZLE_105_CONFIG.cutsceneChoice.keyframeId,
];

export type TeamCutscenesScopeSpec = {
  identifier: ScopeTeamIdentifier;
  step: {
    setCutsceneComplete?: string;
    chooseDialogOption?: {
      keyframeId: string;
      index: number;
    };
  };
  stepResponse: {
    isDisallowed?: true;
  };
};

export const teamCutscenesScopeHooks: ScopeHooks<ScopeType.TEAM_CUTSCENES> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
};
