import { PromiseCallbacks } from "util/util";

export class AsyncSemaphore {
  /**
   * Maximum number of concurrent active tasks.
   * This can be updated at any time; if it gets decreased to less
   * than the current number of active tasks, the semaphore will just
   * block until enough active tasks end to go below the new capacity.
   * If a function is provided, it will be called each time to get
   * the most updated capacity.
   */
  capacity: number | (() => number);
  private numActiveTasks: number;
  private waitQueue: PromiseCallbacks[];

  constructor(capacity: number | (() => number)) {
    this.capacity = capacity;
    this.numActiveTasks = 0;
    this.waitQueue = [];
  }

  private triggerWaitQueue() {
    const capacity =
      typeof this.capacity === "number" ? this.capacity : this.capacity();
    while (this.numActiveTasks < capacity) {
      const waitingPromise = this.waitQueue.shift();
      if (waitingPromise === undefined) break;
      this.numActiveTasks++;
      waitingPromise.resolve();
    }
  }

  async waitAsync() {
    await new Promise<void>((resolve, reject) => {
      this.waitQueue.push({ resolve, reject });
      this.triggerWaitQueue();
    });
  }

  signal() {
    this.numActiveTasks--;
    this.triggerWaitQueue();
  }

  async acquireAndRunAsync<T>(func: () => Promise<T>): Promise<T> {
    await this.waitAsync();
    try {
      return await func();
    } finally {
      this.signal();
    }
  }

  getNumWaitingOrActive() {
    return this.numActiveTasks + this.waitQueue.length;
  }

  getIsActive() {
    return this.getNumWaitingOrActive() > 0;
  }
}

export class AsyncLock extends AsyncSemaphore {
  constructor() {
    super(1);
  }
}

export class AsyncLockMap {
  locks: Map<string, AsyncLock>;

  constructor() {
    this.locks = new Map();
  }

  private getOrCreateLock(key: string) {
    const existingLock = this.locks.get(key);
    if (existingLock !== undefined) return existingLock;
    const newLock = new AsyncLock();
    this.locks.set(key, newLock);
    return newLock;
  }

  async acquireAndRunAsync<T>(key: string, func: () => Promise<T>): Promise<T> {
    const lock = this.getOrCreateLock(key);
    try {
      return await lock.acquireAndRunAsync(func);
    } finally {
      if (!lock.getIsActive()) this.locks.delete(key);
    }
  }
}
