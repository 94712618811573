/** The bounds on how much hope you can have. */
export const hopeBounds = {
  lower: 0,
  upper: 1000,
  initial: 500,
};

/** How much hope you get every second. */
export const hopeGainPerSecond = 100 / 60 / 60; // 100 per hour

export type HopeSnapshot = {
  /**
   * The amount of hope a team has, total, at the given timestamp.
   * The team's actual hope might be different due to auto-hope.
   */
  hope: number;
  /**
   * Millisecond Unix timestamp.
   */
  timestamp: number;
};

export type HopeDelta = {
  delta: number;
  timestamp: number;
};

/**
 * The amount of hope you'll have after considering a set of hope-affecting events.
 */
export const hopeSnapshotWithEvents = (
  snapshot: HopeSnapshot | null,
  events: HopeDelta[]
): HopeSnapshot | null => {
  // Filter out events that have already been applied to the snapshot, and sort them in ascending timestamp order.
  const relevantEvents = events
    .filter(
      ({ timestamp }) => snapshot === null || timestamp > snapshot.timestamp
    )
    .sort(({ timestamp: a }, { timestamp: b }) => a - b);
  let currentSnapshot = snapshot === null ? null : { ...snapshot };
  for (const { delta, timestamp } of relevantEvents) {
    if (currentSnapshot === null) {
      currentSnapshot = {
        hope: hopeBounds.initial,
        timestamp,
      };
    }
    // Between events, if the current hope is less than the upper bound, add autohope until we hit it.
    else if (currentSnapshot.hope < hopeBounds.upper) {
      currentSnapshot.hope =
        currentSnapshot.hope +
        hopeGainPerSecond * ((timestamp - currentSnapshot.timestamp) / 1000);
      if (currentSnapshot.hope > hopeBounds.upper) {
        currentSnapshot.hope = hopeBounds.upper;
      }
    }
    // Then, apply the event.
    // Clamp below but not above, so teams don't lose out on hope
    // if they solve with a full bar.
    currentSnapshot.hope = Math.max(
      hopeBounds.lower,
      currentSnapshot.hope + delta
    );
    // Advance the timestamp.
    currentSnapshot.timestamp = timestamp;
  }
  return currentSnapshot;
};

/**
 * The actual hope that a team has now (or at a given timestamp), given a snapshot.
 * This includes auto-hope and hope bounding.
 */
export const actualHope = (
  snapshot: HopeSnapshot | null,
  now?: number
): number => {
  if (snapshot === null) return hopeBounds.initial;

  const { hope, timestamp } = snapshot;
  // If the snapshot doesn't gain autohope, exit early.
  if (hope >= hopeBounds.upper) {
    return hope;
  }

  now = now ?? Date.now();
  // This shouldn't happen but maybe there's a minor time divergence between server and client.
  const timeDif = now > timestamp ? now - timestamp : 0;
  // Divide by 1000 to get seconds instead of ms
  const uncappedHope = hope + hopeGainPerSecond * (timeDif / 1000);
  // Apply the cap.
  return hopeBounds.upper < uncappedHope
    ? hopeBounds.upper
    : hopeBounds.lower > uncappedHope
    ? hopeBounds.lower
    : uncappedHope;
};

// Show one decimal place, but always round down to avoid giving false hope.
export const formatHope = (hope: number) =>
  (Math.floor(10 * hope) / 10).toFixed(1);
