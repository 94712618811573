import { SolveAttemptInfo } from "sockapi/solve-attempts";

export enum AnswerNormalization {
  UPPERCASE_LETTERS_ONLY = "uppercase_letters_only",
  UPPERCASE_LETTERS_OR_SYMBOLS_ONLY = "uppercase_letters_or_symbols_only",
  NFKD = "nfkd",
}

export const normalizeAnswer = (
  answer: string,
  normalization?: AnswerNormalization
): string => {
  switch (normalization ?? AnswerNormalization.UPPERCASE_LETTERS_ONLY) {
    case AnswerNormalization.UPPERCASE_LETTERS_ONLY:
      return answer
        .normalize("NFKD")
        .toUpperCase()
        .replace(/[^A-Z]/g, "");
    case AnswerNormalization.UPPERCASE_LETTERS_OR_SYMBOLS_ONLY:
      return answer.normalize("NFKD").toUpperCase().replace(/\s/g, "");
    case AnswerNormalization.NFKD:
      return answer.normalize("NFKD");
  }
};

export enum SubmitAnswerResultType {
  ALREADY_SOLVED = "already_solved",
  NO_GUESSES = "no_guesses",
  PUZZLE_MESSAGES = "puzzle_messages",
  NO_ANSWER = "no_answer",
  NOT_NUMERICAL = "not_numerical",
  TRIED_BEFORE = "tried_before",
  SUCCESS = "success",
}

export type SubmitAnswerResult =
  | {
      type:
        | SubmitAnswerResultType.ALREADY_SOLVED
        | SubmitAnswerResultType.NO_GUESSES
        | SubmitAnswerResultType.NO_ANSWER;
    }
  | {
      type:
        | SubmitAnswerResultType.TRIED_BEFORE
        | SubmitAnswerResultType.NOT_NUMERICAL;
      submittedAnswer: string;
    }
  | {
      type: SubmitAnswerResultType.SUCCESS;
      info: Omit<SolveAttemptInfo, "timestamp">;
    };
