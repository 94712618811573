import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import {
  BackendScope,
  BackendScopeState,
  BackendScopeStateEntry,
} from "sockapi/backend-scope-state";
import { TeamProgress } from "sockapi/team-progress";

export type TeamBackendState = {
  displayName: string;
  isInactive: boolean;
  isHidden: boolean;
  members: string[];
  /** puzNames of puzzles viewed by the team. */
  viewTimes: { [puzName: string]: number };
  hints: { [hintId: string]: BackendScopeState<ScopeType.HINT> };
  teamProgress: TeamProgress;
  isPlaceholder?: true;
};

export type SavedHuntBackendState = {
  version: number;
  defaultTeamId?: string;
  teams: { [teamId: string]: TeamBackendState };
  scopeState: { [scopeId: string]: BackendScopeStateEntry };
};

export type StateDownloadScopeSpec = {
  step: {
    downloadScopes?: BackendScope[];
    downloadTeams?: string[];
    downloadAll?: true;
    downloadPublicCustomPuzzles?: true;
    downloadPosthunt?: true;
  };
  stepResponse: SavedHuntBackendState;
};

export const stateDownloadScopeHooks: ScopeHooks<ScopeType.STATE_DOWNLOAD> = {
  identityFunc: (scope) => scope,
};
