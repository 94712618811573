import { KeysMatching } from "util/util";

import { ScopeSpecs, updatableScopeHooks } from "sockapi/scope-specs";
import {
  SockAPIScope,
  SockAPIScopeState,
  SockAPIScopeTrackingState,
  SockAPIScopeStateChangeResult,
  setScopeState,
  updateScopeState,
} from "sockapi/scope-state";

export type SockAPIScopeAssign<TScope extends SockAPIScope = SockAPIScope> = {
  scope: TScope;
  state: SockAPIScopeState<TScope["type"]>;
  tag?: string;
};

type SockAPIScopeUpdatePayloadDict = {
  [TScopeType in KeysMatching<
    ScopeSpecs,
    { update: unknown }
  >]: ScopeSpecs[TScopeType]["update"];
};

export type SockAPIUpdatableScopeType = keyof SockAPIScopeUpdatePayloadDict;
export type SockAPIUpdatableScope = Extract<
  SockAPIScope,
  { type: SockAPIUpdatableScopeType }
>;

export type SockAPIScopeUpdatePayload<
  TScopeType extends SockAPIUpdatableScopeType
> = SockAPIScopeUpdatePayloadDict[TScopeType];

export type SockAPIScopeUpdate<
  TScope extends SockAPIUpdatableScope = SockAPIUpdatableScope
> = {
  scope: TScope;
  payload: SockAPIScopeUpdatePayload<TScope["type"]>;
  tag?: string;
};

export const applyScopeUpdate = <TScopeType extends SockAPIUpdatableScopeType>(
  scopeType: TScopeType,
  state: SockAPIScopeState<TScopeType>,
  payload: SockAPIScopeUpdatePayload<TScopeType>
) => {
  updatableScopeHooks[scopeType].update(state, payload);
};

export const applyScopeAssignToTrackingState = (
  state: SockAPIScopeTrackingState,
  upd: SockAPIScopeAssign,
  isPosthunt?: true
): SockAPIScopeStateChangeResult<(typeof upd.scope)["type"]> => {
  return setScopeState(state, upd.scope, upd.state, isPosthunt);
};

export const applyScopeUpdateToTrackingState = (
  state: SockAPIScopeTrackingState,
  upd: SockAPIScopeUpdate
): SockAPIScopeStateChangeResult<(typeof upd.scope)["type"]> => {
  const applyUpd = <TScope extends SockAPIUpdatableScope>(
    scopeState: SockAPIScopeState<TScope["type"]>,
    scope: TScope,
    payload: SockAPIScopeUpdatePayload<TScope["type"]>
  ) => {
    const scopeType: TScope["type"] = scope.type;
    applyScopeUpdate(scopeType, scopeState, payload);
  };
  return updateScopeState(state, upd.scope, (scopeState) =>
    applyUpd(scopeState, upd.scope, upd.payload)
  );
};
