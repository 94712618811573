import { ScopeHooks } from "sockapi/scope-specs";
import { getPuzzleScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopePuzzleIdentifier } from "sockapi/scopes";

type PuzzleStatsCounters = {
  hints: number;
  getCannedHints: number;
  openCannedHints: number;
};

export type PuzzleStats = {
  unlocks: { [teamId: string]: number };
  solves: { [teamId: string]: number };
  guesses: { [teamId: string]: number };
} & PuzzleStatsCounters;

export type PuzzleStatsUpdate = {
  unlocks?: { [teamId: string]: number | null };
  solves?: { [teamId: string]: number | null };
  guesses?: { [teamId: string]: number };
} & Partial<PuzzleStatsCounters>;

export type StatsScopeSpec = {
  identifier: ScopePuzzleIdentifier;
  state: PuzzleStats;
  update: PuzzleStatsUpdate;
};

export const statsScopeHooks: ScopeHooks<ScopeType.STATS> = {
  identityFunc: (scope) => scope,
  getIdComponents: getPuzzleScopeIdComponents,
  update: (state, payload) => {
    const { unlocks = {}, solves = {}, guesses = {}, ...others } = payload;
    for (const [teamId, value] of Object.entries(unlocks)) {
      if (value === null) delete state.unlocks[teamId];
      else state.unlocks[teamId] = value;
    }
    for (const [teamId, value] of Object.entries(solves)) {
      if (value === null) delete state.solves[teamId];
      else state.solves[teamId] = value;
    }
    for (const [teamId, delta] of Object.entries(guesses))
      state.guesses[teamId] = (state.guesses[teamId] ?? 0) + delta;
    for (const [key, delta] of Object.entries(others))
      state[key as keyof PuzzleStatsCounters] += delta;
  },
};
