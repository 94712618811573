export enum SubmitImageSlot {
  DUMMY = "dummy",
  PUZZLE_105 = "puzzle_105",
  PUZZLE_139 = "puzzle_139",
}

export enum DirectBackendReqType {
  DUMMY = "dummy",
  SUBMIT_IMAGE = "submit_image",
  GET_ERRATUM_EMAILS = "get_erratum_emails",
}

type DirectBackendReqPayloadDict = {
  [DirectBackendReqType.DUMMY]: unknown;
  [DirectBackendReqType.SUBMIT_IMAGE]: {
    slot: SubmitImageSlot;
  };
  [DirectBackendReqType.GET_ERRATUM_EMAILS]: {
    puzName: string;
  };
};
type DirectBackendRespPayloadDict = {
  [DirectBackendReqType.DUMMY]: string;
  [DirectBackendReqType.SUBMIT_IMAGE]: unknown;
  [DirectBackendReqType.GET_ERRATUM_EMAILS]: string[];
};

export type DirectBackendReqPayload<TReqType extends DirectBackendReqType> =
  DirectBackendReqPayloadDict[TReqType];
export type DirectBackendRespPayload<TReqType extends DirectBackendReqType> =
  DirectBackendRespPayloadDict[TReqType];
export type DirectBackendReq<
  TReqType extends DirectBackendReqType = DirectBackendReqType
> = {
  type: TReqType;
  payload: DirectBackendReqPayload<TReqType>;
};
export type DirectBackendResp<
  TReqType extends DirectBackendReqType = DirectBackendReqType
> = {
  type: TReqType;
  payload: DirectBackendRespPayload<TReqType>;
};

export const sendDirectBackendReqToUrlAsync = async <
  TReqType extends DirectBackendReqType
>(
  url: string,
  jwt: string,
  req: DirectBackendReq<TReqType>,
  file?: File
): Promise<DirectBackendResp<TReqType>> => {
  const formData = new FormData();
  formData.append("type", req.type);
  formData.append("payload", JSON.stringify(req.payload));
  if (file !== undefined) formData.append("file", file, file.name);
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${jwt}`,
    },
    body: formData,
    credentials: "include",
  });
  if (resp.status !== 200)
    throw new Error(
      `direct backend received status ${
        resp.status
      } for request ${JSON.stringify(req)}`
    );
  return (await resp.json()) as DirectBackendResp<TReqType>;
};
