export enum SockAPIErrorCode {
  /** Something went wrong with the connection to the interactive server. */
  CONNECTION_ERROR = "connection_error",
  /** An unexpected error occurred with the interactive server. */
  SERVER_ERROR = "server_error",
  /** Something went wrong with a backend call. */
  BACKEND_ERROR = "backend_error",
  /** Authentication failed. */
  AUTH_ERROR = "auth_error",
  /** Something went wrong on the client. */
  CLIENT_ERROR = "client_error",
  /** The client does not have the permissions for a request. */
  PERMISSION_DENIED = "permission_denied",
  /** The team is inactive. */
  TEAM_INACTIVE = "team_inactive",
  /** The team is temporarily blocked for maintenance */
  TEAM_TEMP_BLOCKED = "down_for_maintenance",
  /** The request was canceled by the client. */
  CANCELED_BY_CLIENT = "canceled_by_client",
  /** The connection is not ready for steps. */
  NOT_READY = "not_ready",
  /** The requested team is invalid. */
  INVALID_TEAM = "invalid_team",
  /** A puzzle selection needs to be supplied. */
  NEED_PUZZLE = "need_puzzle",
  /** The requested puzName is invalid, or not unlocked by the team. */
  INVALID_PUZZLE = "invalid_puzzle",
  /** The requested keyframe is invalid. */
  INVALID_KEYFRAME = "invalid_keyframe",
  /** The requested hint is invalid, or not available to the team. */
  INVALID_HINT = "invalid_hint",
  /** The WS request is invalid. */
  BAD_REQUEST = "bad_request",
  /** The client tried to spectate with an invalid key. */
  SPECTATE_INVALID = "spectate_invalid",
  /** The client tried to take actions while spectating. */
  SPECTATE_ACTION = "spectate_action",
  /** The client is already subscribed to the scope. */
  ALREADY_SUBSCRIBED = "already_subscribed",
  /** The client is not subscribed to the scope. */
  NOT_SUBSCRIBED = "not_subscribed",
  /** The client does not have enough hope to do this action. */
  NOT_ENOUGH_HOPE = "not_enough_hope",
  /** The hunt is over. */
  HUNT_IS_OVER = "hunt_is_over",
}

export type SockAPIError = {
  errCode: SockAPIErrorCode;
};
