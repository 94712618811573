export type PromiseCallbacks<TResult = void> = {
  resolve: (result: TResult) => void;
  // Promise.reject natively takes "any".
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (err: any) => void;
};

export class SetMap<TK1, TK2> {
  m: Map<TK1, Set<TK2>>;

  constructor() {
    this.m = new Map();
  }

  has(k1: TK1, k2: TK2): boolean {
    const submap = this.m.get(k1);
    if (submap === undefined) return false;
    return submap.has(k2);
  }

  l1Entries() {
    return this.m.entries();
  }

  /**
   * Returns a set of all entries for a given key.
   * The returned set should not be edited as it may not be
   * a reference into the data structure.
   */
  l1Get(k: TK1): ReadonlySet<TK2> {
    return this.m.get(k) ?? new Set();
  }

  /**
   * Not exposed since it might leave us in an inconsistent state
   * due to an empty L2 set.
   */
  private l1GetOrCreate(k: TK1) {
    const submap = this.m.get(k);
    if (submap !== undefined) return submap;
    const newSubmap = new Set<TK2>();
    this.m.set(k, newSubmap);
    return newSubmap;
  }

  /** Returns whether the key was newly added. */
  add(k1: TK1, k2: TK2): boolean {
    const submap = this.l1GetOrCreate(k1);
    if (submap.has(k2)) return false;
    submap.add(k2);
    return true;
  }

  l1Delete(k: TK1): boolean {
    return this.m.delete(k);
  }

  /** Returns whether the key was present. */
  delete(k1: TK1, k2: TK2): boolean {
    const submap = this.l1GetOrCreate(k1);
    const didDelete = submap.delete(k2);
    if (submap.size === 0) this.m.delete(k1);
    return didDelete;
  }
}

export class MapMap<TK1, TK2, TV> {
  m: Map<TK1, Map<TK2, TV>>;

  constructor() {
    this.m = new Map();
  }

  l1Entries() {
    return this.m.entries();
  }

  /**
   * Returns a map of all entries for a given key.
   * The returned map should not be edited as it may not be
   * a reference into the data structure.
   */
  l1Get(k: TK1): ReadonlyMap<TK2, TV> {
    return this.m.get(k) ?? new Map();
  }

  /**
   * Not exposed since it might leave us in an inconsistent state
   * due to an empty L2 map.
   */
  private l1GetOrCreate(k: TK1) {
    const submap = this.m.get(k);
    if (submap !== undefined) return submap;
    const newSubmap = new Map<TK2, TV>();
    this.m.set(k, newSubmap);
    return newSubmap;
  }

  /** Returns whether the key was added. */
  setIfAbsent(k1: TK1, k2: TK2, v: TV): boolean {
    const submap = this.l1GetOrCreate(k1);
    if (submap.has(k2)) return false;
    submap.set(k2, v);
    return true;
  }

  l1Delete(k: TK1): boolean {
    return this.m.delete(k);
  }

  /** Returns whether the key was present. */
  delete(k1: TK1, k2: TK2): boolean {
    const submap = this.l1GetOrCreate(k1);
    const didDelete = submap.delete(k2);
    if (submap.size === 0) this.m.delete(k1);
    return didDelete;
  }
}

/**
 * Used to make typescript narrow the type of an a key when checking
 * if an object contains it.
 */
export const isKeyInObj = <TObj extends object>(
  k: PropertyKey,
  obj: TObj
): k is keyof TObj => {
  return k in obj;
};

/**
 * Useful when typescript can't infer that X ??= ... means that
 * X is not undefined afterwards.
 */
export const objGetOrCreate = <TObj, TK extends keyof TObj>(
  obj: TObj,
  k: TK,
  newVal: TObj[TK] & (object | null)
): TObj[TK] & (object | null) => {
  const val = obj[k];
  if (val !== undefined) return val;
  obj[k] = newVal;
  return newVal;
};

/** Use a distributive conditional type to distribute the Omit. */
export type DistributiveOmit<T, K extends string> = T extends object
  ? Omit<T, K>
  : never;

/**
 * Non-string object entries are always treated as strings internally.
 * This type utility allows us to represent the internal key type
 * while preserving tighter constraints on string keys where needed.
 */
type StringKeyOf<TObj extends object> = keyof TObj extends string
  ? keyof TObj
  : string;

/**
 * Object.keys, but with the type assertion that there are
 * no other properties besides those present in the declared type.
 * Note that this is a true type assertion, as an object type
 * in typescript does not actually guarantee the absence of
 * any additional properties.
 */
export const objectKeysUnsafe = <TObj extends object>(
  obj: TObj
): StringKeyOf<TObj>[] => {
  return Object.keys(obj) as StringKeyOf<TObj>[];
};

/**
 * Object.entries, but with the type assertion that there are
 * no other properties besides those present in the declared type.
 * Note that this is a true type assertion, as an object type
 * in typescript does not actually guarantee the absence of
 * any additional properties.
 */
export const objectEntriesUnsafe = <TObj extends object>(
  obj: TObj
): [StringKeyOf<TObj>, TObj[keyof TObj]][] => {
  return Object.entries(obj) as [StringKeyOf<TObj>, TObj[keyof TObj]][];
};

/**
 * Object.entries, but with number keys, and the same caveat as
 * objectEntriesUnsafe.
 */
export const objectNumberKeyEntriesUnsafe = <TVal>(obj: {
  [k: number]: TVal;
}): [number, TVal][] => {
  return objectEntriesUnsafe(obj).map(([k, v]) => [Number(k), v]);
};

export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
export type KeysNotMatching<T, V> = Exclude<keyof T, KeysMatching<T, V>>;

export const arrayZipMap = <T1, T2, TRet>(
  arr1: T1[],
  arr2: T2[],
  func: (x1: T1, x2: T2) => TRet
): TRet[] => {
  if (arr1.length !== arr2.length)
    throw new Error("tried to zip arrays of unequal length");
  return arr1.map((x, i) => func(x, arr2[i]));
};

export const arrayZip = <T1, T2>(arr1: T1[], arr2: T2[]): [T1, T2][] => {
  if (arr1.length !== arr2.length)
    throw new Error("tried to zip arrays of unequal length");
  return arr1.map((x, i) => [x, arr2[i]]);
};

export const jsonDeepCopy = <T>(val: T): T => {
  return JSON.parse(JSON.stringify(val));
};

export const minutesToMs = (minutes: number): number => {
  return minutes * 60 * 1000;
};

export const hoursToMs = (hours: number): number => {
  return hours * 60 * 60 * 1000;
};

export const formatDuration = (duration: number): string => {
  const sign = duration < 0 ? "-" : "";
  duration = Math.abs(duration);
  const hours = Math.floor(duration / 1000 / 60 / 60);
  const minutes = Math.floor(duration / 1000 / 60) % 60;
  const seconds = Math.floor(duration / 1000) % 60;
  const ms = Math.floor(duration) % 1000;
  const omitHours = hours === 0;
  const omitMinutes = omitHours && minutes === 0;
  const omitSeconds = !omitHours;
  const omitMs = !omitMinutes;
  const hoursStr = hours.toString();
  const minutesStr = minutes.toString().padStart(omitHours ? 1 : 2, "0");
  const secondsStr = seconds.toString().padStart(omitMinutes ? 1 : 2, "0");
  const msStr = ms.toString().padStart(3, "0");
  return `${sign}${omitHours ? "" : `${hoursStr}h`}${
    omitMinutes ? "" : `${minutesStr}m`
  }${omitSeconds ? "" : `${secondsStr}${omitMs ? "" : `.${msStr}`}s`}`;
};

export const lerp = (min: number, max: number, frac: number): number =>
  min * (1 - frac) + max * frac;

export const lerpClamp = (min: number, max: number, frac: number): number =>
  Math.max(Math.min(min * (1 - frac) + max * frac, max), min);

export const randomInRange = (min: number, max: number) =>
  lerp(min, max, Math.random());

export const capitalize = (s: string) => `${s[0].toUpperCase()}${s.slice(1)}`;

export const promiseWithResolvers = <T>() => {
  let resolve!: (value: T | PromiseLike<T>) => void;
  let reject!: (reason: unknown) => void;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return { promise, resolve, reject };
};

const WORDS: { [num: number]: string } = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
} as Record<number, string>;

export const spellOutNumber = (num: number): string => {
  return WORDS[num] ?? num.toString();
};

export const ordinalNumber = (num: number): string => {
  switch (new Intl.PluralRules("en", { type: "ordinal" }).select(num)) {
    case "one":
      return `${num}st`;
    case "two":
      return `${num}nd`;
    case "few":
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};
