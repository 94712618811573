import { useEffect, useState } from "react";
import Twemoji from "react-twemoji";
import settings from "settings";
import TeamDisplayNameDisplay from "components/TeamDisplayNameDisplay";
import { CompletionIcon } from "components/Icon";
import Tooltip from "components/Tooltip";
import {
  useTeamScopeState,
  useLeaderboardNumSolves,
  useNumTotalHintTokens,
  useNumUsedHintTokens,
} from "stores/ScopeStateStore";
import {
  useServerInteractionStore,
  useTeamId,
} from "stores/ServerInteractionStore";

function NavTeamName() {
  const sockClient = useServerInteractionStore(
    (state) => state.getSockClient
  )();
  const teamId = useTeamId();
  const teamScopeState = useTeamScopeState();
  const numSolves = useLeaderboardNumSolves();
  const numTotalHintTokens = useNumTotalHintTokens(teamId);
  const numUsedHintTokens = useNumUsedHintTokens(teamId);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    return sockClient?.addAuthEffect(() => {
      setConnected(true);
      return () => {
        setConnected(false);
      };
    });
  }, [sockClient]);

  if (
    teamId === null ||
    teamScopeState === null ||
    numTotalHintTokens === null ||
    numUsedHintTokens === null ||
    numSolves === null
  )
    return null;
  const { finishedData } = teamScopeState;
  const numSolveCountPuzzles = finishedData?.numSolveCountPuzzles;

  return (
    <>
      {!settings.localMode ? (
        <Tooltip
          text={
            connected ? "Connected" : "Disconnected; attempting to reconnect..."
          }
        >
          <div className="nav-stat">
            <Twemoji tag="span">{connected ? "🟢️" : "🟡️"}</Twemoji>
            &nbsp;{connected ? "Connected" : "Disconnected"}
          </div>
        </Tooltip>
      ) : null}
      <TeamDisplayNameDisplay hideAdminLinks={true} />
      {numTotalHintTokens === 0 && numSolves === 0 ? null : (
        <div className="nav-stats">
          {numTotalHintTokens === 0 ? null : (
            <Tooltip text="Hints available">
              {numTotalHintTokens - numUsedHintTokens}
              <Twemoji tag="small">💡</Twemoji>
            </Tooltip>
          )}
          {numSolves === 0 ? null : (
            <Tooltip text="Stars restored">
              {numSolves}
              {numSolveCountPuzzles !== undefined ? (
                <small>
                  {" "}
                  ({Math.floor((numSolves / numSolveCountPuzzles) * 100)}%)
                </small>
              ) : null}
              <small>
                <CompletionIcon
                  numSolves={numSolves}
                  numSolveCountPuzzles={numSolveCountPuzzles}
                />
              </small>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
}

export default NavTeamName;
