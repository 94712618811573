import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

export type TeamHopePoolScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    cycleContribution: number;
    hopeFromSolve?: number;
  };
  step: {
    contribute?: number;
    claim?: true;
  };
  stepResponse: "";
};

export const teamHopePoolScopeHooks: ScopeHooks<ScopeType.TEAM_HOPE_POOL> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
};
