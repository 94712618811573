import { ScopeIdComponents } from "sockapi/scope-specs";
import {
  ScopeTeamIdentifier,
  ScopePuzzleIdentifier,
  ScopeTeamPuzzleIdentifier,
  Scope,
} from "sockapi/scopes";

// Utilities for defining scope specs.

export const getTeamScopeIdComponents = ({
  teamId,
}: Extract<Scope, ScopeTeamIdentifier>): ScopeIdComponents => [teamId];
export const getPuzzleScopeIdComponents = ({
  puzName,
}: Extract<Scope, ScopePuzzleIdentifier>): ScopeIdComponents => [puzName];
export const getTeamPuzzleScopeIdComponents = ({
  teamId,
  puzName,
}: Extract<Scope, ScopeTeamPuzzleIdentifier>): ScopeIdComponents => [
  teamId,
  puzName,
];
