import { ScopeHooks } from "sockapi/scope-specs";
import { getPuzzleScopeIdComponents } from "sockapi/scope-specs-util";
import { PuzzleContent } from "sockapi/scope-specs/puzzle-content-spec";
import { ScopeType } from "sockapi/scope-types";
import { ScopePuzzleIdentifier } from "sockapi/scopes";
import { PuzzleMetadata } from "sockapi/puzzle-data";

export const doesSolutionExist = (puzData: PuzzleMetadata) => {
  return !puzData.isStory;
};

export const isSolutionPageAvailable = (
  puzName: string,
  isUnlocked: boolean,
  isSolved: boolean
) => {
  if (isSolved) return true;
  // Find the Answer VII's solution is always available.
  if (puzName === "puz327" && isUnlocked) return true;

  return false;
};

export type SolutionContentScopeSpec = {
  identifier: ScopePuzzleIdentifier;
  state: PuzzleContent & {
    answer: string | null;
    authors?: string[];
    otherCredits?: string;
  };
};

export const solutionContentScopeHooks: ScopeHooks<ScopeType.SOLUTION_CONTENT> =
  {
    identityFunc: (scope) => scope,
    getIdComponents: getPuzzleScopeIdComponents,
  };
