import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamPuzzleScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamPuzzleIdentifier } from "sockapi/scopes";
import { SolveAttemptInfo } from "sockapi/solve-attempts";
import { SubmitAnswerResult } from "sockapi/answers";

export const MAX_ANSWER_LENGTH = 250;

export type TeamAnswerableScopeSpec = {
  identifier: ScopeTeamPuzzleIdentifier;
  state: {
    numGuessesRemaining: number;
    submissions: {
      [timestamp: number]: SolveAttemptInfo;
    };
  };
  update: {
    numGuessesRemaining?: number;
    updSubmissions?: {
      [timestamp: number]: SolveAttemptInfo | null;
    };
  };
  step: {
    /** Answer to submit. */
    submitAnswer?: string;
  };
  stepResponse: SubmitAnswerResult;
};

export const teamAnswerableScopeHooks: ScopeHooks<ScopeType.TEAM_ANSWERABLE> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamPuzzleScopeIdComponents,
  update: (scopeState, payload) => {
    const { numGuessesRemaining, updSubmissions } = payload;
    const { submissions } = scopeState;
    if (numGuessesRemaining !== undefined)
      scopeState.numGuessesRemaining = numGuessesRemaining;
    for (const [timestamp, submission] of Object.entries(
      updSubmissions ?? {}
    )) {
      if (submission === null) delete submissions[Number(timestamp)];
      else submissions[Number(timestamp)] = submission;
    }
  },
};
