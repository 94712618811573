import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

type WrapupSection = {
  content: string;
  claimer: string | null;
};
type WrapupState = { [id: string]: WrapupSection };
type WrapupUpdate = { id: string } & Partial<WrapupSection>;

const newSection = () => ({ content: "", claimer: null });

export const createState = () => ({ "1": newSection() });

export const generateId = (id: string, nextId?: string) => {
  let newId = `${id.slice(0, -1)}21`;
  while (nextId !== undefined && nextId <= newId)
    newId = `${newId.slice(0, -1)}01`;
  return newId;
};

export const mergeUpdate = (state: WrapupState, update: WrapupUpdate) => {
  const { id, ...section } = update;
  state[id] ??= newSection();
  Object.assign(state[id], section);
};

export type WrapupScopeSpec = {
  state: WrapupState;
  update: WrapupUpdate;
  step: WrapupUpdate;
  stepResponse: true;
  backendState: WrapupState;
};

export const wrapupScopeHooks: ScopeHooks<ScopeType.WRAPUP> = {
  identityFunc: (scope) => scope,
  update: mergeUpdate,
};
