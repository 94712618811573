import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { SockAPIScopeState } from "sockapi/scope-state";

export const PUZZLE_144_CONFIG = {
  puzName: "puz144",
  maxLength: 1000,
};

type Puzzle144Request = {
  text: string;
  timestamp: number;
};

export type Puzzle144Response = {
  text: string;
  numPoints: number;
  hopeGained: number;
  timestamp: number;
};

export type TeamPuzzle144ScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    req?: Puzzle144Request;
    resp?: Puzzle144Response;
  };
  update: {
    setReq?: Puzzle144Request;
    setResp?: Puzzle144Response;
  };
  step: {
    submitReq?: Omit<Puzzle144Request, "timestamp">;
    setResp?: Omit<Puzzle144Response, "timestamp" | "hopeGained">;
  };
  stepResponse: "";
  backendState: SockAPIScopeState<ScopeType.TEAM_PUZZLE_144>;
};

export const teamPuzzle144ScopeHooks: ScopeHooks<ScopeType.TEAM_PUZZLE_144> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const { setReq, setResp } = payload;
    if (setReq !== undefined) scopeState.req = setReq;
    if (setResp !== undefined) scopeState.resp = setResp;
  },
};
