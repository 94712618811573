import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { DirectBackendReq, DirectBackendResp } from "sockapi/direct-backend";

export type MockDirectBackendScopeSpec = {
  identifier: ScopeTeamIdentifier;
  step: {
    jwt: string;
    req: DirectBackendReq;
    /** For now, the file encoded as a base64 data URL, if any. */
    file?: string;
  };
  stepResponse: DirectBackendResp;
  backendState: {
    submittedImages: {
      [imageId: string]: string;
    };
  };
};

export const mockDirectBackendScopeHooks: ScopeHooks<ScopeType.MOCK_DIRECT_BACKEND> =
  {
    identityFunc: (scope) => scope,
    getIdComponents: getTeamScopeIdComponents,
  };
