import { EventController } from "util/events";
import { Scope } from "sockapi/scopes";

import {
  SockAPIStepResponseScopeType,
  SockAPIScopeStepResponse,
  SockAPIScopeStepResponsePayload,
  isScopeStepResponseOfType,
} from "sockapi/scope-steps";

export class SockClientStepTracker {
  nextStepId: number;
  pendingSteps: Map<string, EventController<SockAPIScopeStepResponse | null>>;

  constructor() {
    this.nextStepId = 0;
    this.pendingSteps = new Map();
  }

  genStepId(): string {
    return `step_${this.nextStepId++}`;
  }

  async waitForRespAsync<TScopeType extends SockAPIStepResponseScopeType>(
    stepId: string,
    scopeType: TScopeType
  ): Promise<SockAPIScopeStepResponsePayload<
    Scope<TScopeType>["type"]
  > | null> {
    if (this.pendingSteps.has(stepId))
      throw new Error(`requested to wait for duplicate step ${stepId}`);

    const event = new EventController<SockAPIScopeStepResponse | null>();
    this.pendingSteps.set(stepId, event);
    const stepResponse = await event.waitAsync();
    this.pendingSteps.delete(stepId);

    if (stepResponse === null) return null;
    if (!isScopeStepResponseOfType(stepResponse, scopeType))
      throw new Error(
        `unexpected response to step ${stepId} of type ${scopeType}: ${JSON.stringify(
          stepResponse
        )}`
      );
    return stepResponse.payload;
  }

  handleResponse(stepId: string, stepResponse: SockAPIScopeStepResponse) {
    const event = this.pendingSteps.get(stepId);
    if (event === undefined) throw new Error(`no pending step ${stepId}`);
    event.fire(stepResponse);
    this.pendingSteps.delete(stepId);
  }

  handleErr(stepId: string) {
    const event = this.pendingSteps.get(stepId);
    if (event === undefined) throw new Error(`no pending step ${stepId}`);
    event.fire(null);
    this.pendingSteps.delete(stepId);
  }

  handleDisconnect() {
    for (const event of this.pendingSteps.values()) {
      event.fire(null);
    }
    this.pendingSteps.clear();
  }
}
