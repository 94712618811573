import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

type BlocksPuzzleBoardSpec = string[];

export const BLOCKS_CONFIG = {
  puzName: "blocks",
  inscriptionNumBoards: 7,
};

export type TeamBlocksProgressState = {
  solvedBoards: { [boardIndex: number]: true };
};

export type TeamBlocksScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: TeamBlocksProgressState & {
    numWishes: number;
    maxNumBoards: number;
    boardSpecs: BlocksPuzzleBoardSpec[];
    inscription?: string;
  };
  update: {
    /**
     * The data is small and rarely updated.
     * Just override the whole thing whenever it needs an update.
     */
    boardSpecs?: BlocksPuzzleBoardSpec[];
    solvedBoards?: { [boardIndex: number]: true };
    inscription?: string | null;
  };
  step: {
    setBoardSolved?: {
      boardIndex: number;
      isSolved: boolean;
    };
  };
  stepResponse: "";
  backendState: TeamBlocksProgressState;
};

export const teamBlocksScopeHooks: ScopeHooks<ScopeType.TEAM_BLOCKS> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const { boardSpecs, solvedBoards, inscription } = payload;
    if (boardSpecs !== undefined) scopeState.boardSpecs = boardSpecs;
    if (solvedBoards !== undefined) scopeState.solvedBoards = solvedBoards;
    if (inscription !== undefined) {
      if (inscription !== null) scopeState.inscription = inscription;
      else delete scopeState.inscription;
    }
  },
};
