import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export type TeamNameDisplayData = {
  teamId: string;
  displayName: string;
  collectibleIcons?: string;
};

export type TeamSummaryState = {
  teamId: string;
  displayName: string;
  numSolves: number;
  completionTime?: number;
  lastSolveTime?: number;
  isHidden?: boolean;
  collectibleIcons?: string;
};

export type TeamSummaryUpdate = {
  displayName?: string;
  numSolves?: number;
  completionTime?: number;
  lastSolveTime?: number;
  isHidden?: boolean;
  collectibleIcons?: string;
};

export const mergeTeamSummaryUpdate = (
  upd1: TeamSummaryUpdate,
  upd2: TeamSummaryUpdate
) => {
  Object.assign(upd1, upd2);
};

export const updateTeamSummary = (
  state: TeamSummaryState,
  upd: TeamSummaryUpdate
) => {
  Object.assign(state, upd);
};

export type TeamListScopeSpec = {
  state: {
    [teamId: string]: TeamSummaryState;
  };
  update: {
    addTeams?: { [teamId: string]: TeamSummaryState };
    removeTeams?: { [teamId: string]: true };
    updTeams?: { [teamId: string]: TeamSummaryUpdate };
  };
};

export const teamListScopeHooks: ScopeHooks<ScopeType.TEAM_LIST> = {
  identityFunc: (scope) => scope,
  update: (scopeState, payload) => {
    const { addTeams, removeTeams, updTeams } = payload;
    Object.assign(scopeState, addTeams ?? {});
    for (const teamId of Object.keys(removeTeams ?? {})) {
      delete scopeState[teamId];
    }
    for (const [teamId, teamUpd] of Object.entries(updTeams ?? {})) {
      const teamState = scopeState[teamId];
      if (teamState === undefined)
        throw new Error("received team list update for non-existent team");
      updateTeamSummary(teamState, teamUpd);
    }
  },
};
