/**
 * Scopes are general concepts that may be used by the SockAPI or
 * backend interfaces. The scopes used by SockAPI are defined in
 * scope-state.ts as SockAPIScope.
 */
export enum ScopeType {
  /**
   * A dummy scope. Should not expose any functionality.
   * Used to ensure that all scope type unions are non-empty
   * (to preserve functionality not currently used) since
   * empty unions cause problems with typescript.
   */
  DUMMY = "dummy",

  HUNT_ADMIN = "hunt_admin",
  SERVER_HEALTH = "server_health",
  BIG_BOARD = "big_board",
  TEAM_LIST = "team_list",
  TEAM_MEMBERS = "team_members",
  TEAM = "team",
  TEAM_ANSWERABLE = "team_answerable",
  PUZZLE_CONTENT = "puzzle_content",

  TEAM_PUZZLE_105 = "team_puzzle_105",
  ERRATUM_CONTENT = "erratum_content",
  TEAM_ERRATA = "team_errata",
  SOLUTION_CONTENT = "solution_content",
  CANNED_HINT = "canned_hint",
  HINT = "hint",
  TEAM_GACHA = "team_gacha",
  TEAM_CANNED_HINTS = "team_canned_hints",
  TEAM_HINTS = "team_hints",
  KEYFRAME = "keyframe",
  TEAM_CUTSCENES = "team_cutscenes",
  MOCK_DIRECT_BACKEND = "mock_direct_backend",
  TEAM_OLIVE = "team_olive",
  ADMIN_QUEUE = "admin_queue",
  ADMIN_QUEUE_TASK = "admin_queue_task",
  TEAM_BLOCKS = "team_blocks",
  TEAM_COLLECTIBLES = "team_collectibles",
  TEAM_FARMER = "team_farmer",
  TEAM_BIG_BRANCH = "team_big_branch",
  TEAM_PUZZLE_144 = "team_puzzle_144",
  HOPE_POOL = "hope_pool",
  TEAM_HOPE_POOL = "team_hope_pool",
  TEAM_HOPELESS = "team_hopeless",
  TEAM_HISTORY = "team_history",
  TEAM_TITLES = "team_titles",
  TEAM_PUZZLE_139 = "team_puzzle_139",
  STATE_DOWNLOAD = "state_download",
  ADMIN_STATS = "admin_stats",
  STATS = "stats",
  HUNT_STATS = "hunt_stats",
  WRAPUP = "wrapup",
  PUBLIC_SUBMISSIONS = "public_submissions",
}
