import { useCallback, useEffect, useRef } from "react";
import Twemoji from "react-twemoji";
import {
  useSeparatelyPersistedState,
  useUpdateSeparatelyPersistedState,
} from "stores/UserPreferencesStore";
import "./VolumeControl.css";

function VolumeSlider({
  volume,
  setVolume,
  isMuted,
  setIsMuted,
}: {
  volume: number;
  setVolume: (volume: number) => void;
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
}) {
  const sliderRef = useRef<HTMLDivElement>(null);

  // scroll wheel to adjust volume by 5
  const scrollListener = useCallback(
    (evt: WheelEvent) => {
      evt.preventDefault();
      const delta = evt.deltaY > 0 ? -5 : 5;

      setVolume(Math.min(100, Math.max(0, volume + delta)));
    },
    [setVolume, volume]
  );

  useEffect(() => {
    if (sliderRef.current === null) return;
    const el = sliderRef.current;
    el.addEventListener("wheel", scrollListener, {
      passive: false, // prevent scrolling
    });
    return () => {
      el.removeEventListener("wheel", scrollListener);
    };
  }, [sliderRef, scrollListener]);

  return (
    <div className="volume-slider" ref={sliderRef}>
      <label
        onClick={(e) => {
          e.stopPropagation();
          setIsMuted(!isMuted);
        }}
      >
        <Twemoji>{isMuted ? "🔈️" : "🔊"}</Twemoji>
      </label>{" "}
      <input
        type="range"
        value={volume}
        style={{ filter: isMuted ? "saturate(0)" : undefined }}
        onChange={(e) => {
          e.stopPropagation();
          setVolume(+e.target.value);
          // unmute if volume is set to non-zero
          if (isMuted && +e.target.value > 0) {
            setIsMuted(false);
          }
        }}
      />
    </div>
  );
}

function VolumeControl() {
  const volume = useSeparatelyPersistedState((state) => state.volume);
  const isMuted = useSeparatelyPersistedState((state) => state.isMuted);
  const updateSeparatelyPersistedState = useUpdateSeparatelyPersistedState();
  const setVolume = (newVolume: number) => {
    updateSeparatelyPersistedState({ volume: newVolume });
  };
  const setIsMuted = (newIsMuted: boolean) => {
    updateSeparatelyPersistedState({ isMuted: newIsMuted });
  };

  return (
    <VolumeSlider
      volume={volume}
      setVolume={setVolume}
      isMuted={isMuted}
      setIsMuted={setIsMuted}
    />
  );
}

export default VolumeControl;
