import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { HopeSnapshot } from "sockapi/gacha/hope";

export type TeamHistoryScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: HopeSnapshot[];
  update: {
    keep: number;
    add: HopeSnapshot[];
  };
};

export const teamHistoryScopeHooks: ScopeHooks<ScopeType.TEAM_HISTORY> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (state, payload) => {
    const { keep, add } = payload;
    state.length = keep;
    state.push(...add);
  },
};
