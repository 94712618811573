import { EventController } from "util/events";
import { SockAPIErrorCode } from "sockapi/errors";
import { SavedHuntBackendState } from "sockapi/scope-specs/state-download-spec";

import {
  AfterDisconnectEventData,
  ServerInterface,
  ServerInterfaceType,
} from "sock-server/server-interface/ServerInterface";
import {
  WSReq,
  WSReqType,
  WSResp,
  WSRespType,
  SockAPIAuthSuccessResult,
} from "sockapi/sockapi-messages";

export class ServerConnection {
  type: ServerInterfaceType;
  getJwtFunc: (forceRegenJwt: boolean) => Promise<string | null>;
  onResp: (resp: WSResp) => void;
  dumpWsMessages: boolean;

  serverInterface?: ServerInterface;
  isAuthed: boolean;
  pendingAuthReq?: (result: SockAPIAuthSuccessResult | null) => void;
  /** Requests waiting for the connection to be established. */
  pendingReqs: WSReq[];

  /**
   * Event that fires every time we successfully authenticate
   * to the server, i.e. the server is ready to receive our requests.
   */
  afterAuthEvent: EventController<void>;
  /**
   * Event that fires every time we get disconnected and plan to
   * reconnect.
   */
  afterDisconnectEvent: EventController<AfterDisconnectEventData>;

  /** Timer handle for the ping task. */
  pingTimer?: ReturnType<typeof setInterval>;

  constructor(
    type: ServerInterfaceType,
    dumpWsMessages: boolean,
    getJwtFunc: (forceRegenJwt: boolean) => Promise<string | null>,
    onResp: (resp: WSResp) => void
  ) {
    this.type = type;
    this.dumpWsMessages = dumpWsMessages;
    this.getJwtFunc = getJwtFunc;
    this.onResp = onResp;

    this.isAuthed = false;
    this.pendingReqs = [];

    this.afterAuthEvent = new EventController();
    this.afterDisconnectEvent = new EventController();
  }

  async initAsync(
    makeServerInterfaceFunc: () => Promise<ServerInterface>,
    afterAuth: (result: SockAPIAuthSuccessResult | null) => void,
    afterDisconnect: (evData: AfterDisconnectEventData) => void
  ) {
    let connectCounter = 0;
    this.serverInterface = await makeServerInterfaceFunc();
    this.serverInterface.init({
      onOpen: () => {
        const currConnectCounter = connectCounter;
        const getShouldCancel = () => currConnectCounter !== connectCounter;
        (async () => {
          const result =
            await (async (): Promise<SockAPIAuthSuccessResult | null> => {
              const firstResult = await this.authAsync(false, getShouldCancel);
              if (firstResult !== null) return firstResult;
              if (getShouldCancel()) return null;
              // If we failed auth, there might be something wrong with
              // our token. Regenerate one and try again.
              return await this.authAsync(true, getShouldCancel);
            })();

          this.processPendingReqs();

          // This must be separate from afterAuthEvent since we
          // need to guarantee that this runs before any client-added
          // after-auth events.
          afterAuth(result);
          if (result !== null) this.afterAuthEvent.fire();
        })().catch(console.error);
      },
      onResp: (msg) => {
        const resp = JSON.parse(msg) as WSResp;
        if (this.dumpWsMessages) {
          console.log(`[${this.type}] > ${JSON.stringify(resp, null, 2)}`);
        }
        this.handleResp(resp);
      },
      afterDisconnect: (evData) => {
        connectCounter++;
        const { pendingAuthReq } = this;
        delete this.pendingAuthReq;
        pendingAuthReq?.(null);
        this.isAuthed = false;

        this.afterDisconnectEvent.fire(evData);
        // This must be separate from afterDisconnectEvent since we
        // need to guarantee that this runs after any client-added
        // after-disconnect events.
        afterDisconnect(evData);
      },
    });

    // Send a heartbeat to the server every so often to prevent the connection
    // from being closed.
    this.pingTimer = setInterval(() => {
      this.sendReq({ type: WSReqType.PING }, { noBuffer: true });
    }, 20000);
  }

  reconnect(): void {
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.reconnect();
  }

  teardown(): void {
    if (this.pingTimer !== undefined) clearInterval(this.pingTimer);
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.close();
  }

  resetServer(initState?: SavedHuntBackendState): void {
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.resetServer?.(initState);
  }

  private sendReqRaw(req: WSReq) {
    if (this.dumpWsMessages) {
      console.log(`[${this.type}] < ${JSON.stringify(req, null, 2)}`);
    }
    if (this.serverInterface === undefined)
      throw new Error("expect server interface to be initialized");
    this.serverInterface.send(JSON.stringify(req));
  }

  sendReq(
    req: WSReq,
    opts?: {
      // If set, the request will be dropped instead of buffered
      // if the connection is not ready.
      noBuffer?: boolean;
    }
  ) {
    if ((opts?.noBuffer ?? false) && !this.isAuthed) return;
    this.pendingReqs.push(req);
    this.processPendingReqs();
  }

  processPendingReqs() {
    while (this.isAuthed) {
      const req = this.pendingReqs.shift();
      if (req === undefined) break;
      this.sendReqRaw(req);
    }
  }

  /**
   * Authenticate to the server. Returns whether the authentication
   * was successful.
   */
  private async authAsync(
    forceRegenJwt: boolean,
    getShouldCancel: () => boolean
  ): Promise<SockAPIAuthSuccessResult | null> {
    const jwt = await this.getJwtFunc(forceRegenJwt);
    if (jwt === null || getShouldCancel()) return null;
    this.sendReqRaw({ type: WSReqType.AUTH, jwt });
    return await new Promise((resolve, reject) => {
      if (this.pendingAuthReq !== undefined)
        throw new Error("only expect one auth request at a time");
      this.pendingAuthReq = resolve;
    });
  }

  handleResp(resp: WSResp): void {
    // Preliminary handling for auth-related responses.
    switch (resp.type) {
      case WSRespType.ERROR: {
        const { err } = resp;
        switch (err.errCode) {
          case SockAPIErrorCode.AUTH_ERROR: {
            const { pendingAuthReq } = this;
            delete this.pendingAuthReq;
            pendingAuthReq?.(null);
            break;
          }
        }
        break;
      }
      case WSRespType.AUTH_SUCCESS: {
        this.isAuthed = true;
        const { pendingAuthReq } = this;
        delete this.pendingAuthReq;
        pendingAuthReq?.(resp.result);
        break;
      }
    }
    this.onResp(resp);
  }

  /**
   * Utility function to set up effects that should be active
   * during any authenticated session.
   * `func` is a function that sets up the effect, and optionally
   * returns a function to cleanup the effect.
   * The effect is set up at the start of each authenticated session,
   * and cleaned up after. If the session has already started, the
   * effect is set up immediately.
   * Returns a cleanup function, which cleans up and unregisters
   * the effect.
   */
  addAuthEffect(func: () => (() => void) | void): () => void {
    let cleanupFunc: (() => void) | void = undefined;

    if (this.isAuthed) cleanupFunc = func();
    const cleanupAfterAuth = this.afterAuthEvent.addListener(() => {
      if (cleanupFunc !== undefined)
        throw new Error("func was not cleaned up for previous auth");
      cleanupFunc = func();
    });
    const cleanupAfterDisconnect = this.afterDisconnectEvent.addListener(() => {
      cleanupFunc?.();
      cleanupFunc = undefined;
    });

    return () => {
      cleanupFunc?.();
      cleanupAfterAuth();
      cleanupAfterDisconnect();
    };
  }
}
