import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import { ScopePuzzleIdentifier } from "sockapi/scopes";

export type CannedHintScopeSpec = {
  identifier: ScopePuzzleIdentifier & { index: number };
  state: {
    content: string;
  };
  update: {
    content: string;
  };
};

export const cannedHintScopeHooks: ScopeHooks<ScopeType.CANNED_HINT> = {
  identityFunc: (scope) => scope,
  getIdComponents: ({ puzName, index }) => [puzName, index],
  update: (scopeState, payload) => {
    scopeState.content = payload.content;
  },
};
