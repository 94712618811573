import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export type KeyframeData = {
  puzName: string;
  endCutscene?: boolean;
  imageName: string;
  dialogue: string;
  dialogOptions?: string[];
  nextId: string[];
  /** Path to redirect to when the cutscene is complete, if any. */
  redirectPath?: string;
  /**
   * If set, the team would not be allowed to access this keyframe
   * unless the condition is satisfied. Conditions must be manually
   * implemented.
   */
  keyframeReq?: string;
  /**
   * If set, the dialog options would respectively be displayed as
   * disabled if their respective conditions are not satisfied.
   */
  dialogOptionsReqs?: string[];
};

export type KeyframeScopeSpec = {
  identifier: { keyframeId: string };
  state: KeyframeData;
};

export const keyframeScopeHooks: ScopeHooks<ScopeType.KEYFRAME> = {
  identityFunc: (scope) => scope,
  getIdComponents: ({ keyframeId }) => [keyframeId],
};
