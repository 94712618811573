import { PuzzleContent } from "sockapi/scope-specs/puzzle-content-spec";
import { KeyframeData } from "sockapi/scope-specs/keyframe-spec";
import { AnswerNormalization } from "sockapi/answers";
import { Rarity, CollectibleMetadata } from "sockapi/gacha/pulls";

export const NUM_CELESTIALS = 7;
export const UNTITLED_PUZZLE_DISPLAY_NAME = "Unnamed Star";

export const getPuzzleDisplayName = (
  puzName: string,
  displayName: string,
  untitledPuzzleIndex: number | undefined,
  customTitle: string | undefined,
  isAdmin: boolean
): string => {
  const adminPrefix = isAdmin ? `[${puzName}] ` : "";
  if (displayName !== "") return `${adminPrefix}${displayName}`;
  if (customTitle !== undefined && customTitle !== "")
    return `${adminPrefix}${customTitle}`;
  const untitledPuzzleSuffix =
    untitledPuzzleIndex === undefined ? "" : ` ${untitledPuzzleIndex + 1}`;
  return `${adminPrefix}${UNTITLED_PUZZLE_DISPLAY_NAME}${untitledPuzzleSuffix}`;
};

export type PuzzleMetadata = {
  /** The internal identifier used to refer to the puzzle. */
  puzName: string;
  /** The slug used by Django and shown in the URL bar. */
  slug: string;
  displayName: string;
  /**
   * The answer normalization to be used for the puzzle,
   * if different from the uppercase-letters-only default.
   */
  answerNormalization?: AnswerNormalization;
  /** The sequence that this puzzle belongs to, if any. */
  sequenceId?: string;
  constellationEdges?: string[];
  /** Whether the puzzle entry represents a story beat. */
  isStory?: true;
  /** If set, indicates that the puzzle is a celestial. */
  isCelestial?: true;
  /** Whether the puzzle is a find celestial story beat. */
  isFindCelestial?: true;
  /** If set, indicates that the puzzle is the final meta. */
  isBigMeta?: true;
  /**
   * If set, the puzzle gets extra priority in the gacha and, if part
   * of a sequence, gets rendered last in the sequence display.
   */
  isMeta?: true;
  /** Whether the puzzle is the final cutscene. */
  isFinalCutscene?: true;
  /** The rarity of the puzzle. */
  rarity?: Rarity;
  /** The position of the puzzle on the map, if displayed. */
  mapPos?: { x: number; y: number };
  /** If set, then this is a custom puzzle. */
  customPuzzleData?: {
    srcTeamId: string;
  };
  /** All images used, if the puzzle is a cutscene. Used for preloading. */
  cutsceneImages?: string[];
};

export type UnlockingInEdge = {
  unlockGroupId: string;
  /** Value required. Defaults to 1 */
  val?: number;
  /**
   * Normally, the requirement pertains to the solves contribution
   * to the unlock group. If this flag is set, then the requirement
   * pertains to the unlocks contribution instead.
   */
  isUnlockOnly?: true;
};
export type UnlockingOutEdge = {
  unlockGroupId: string;
  /** Value added on unlock/solve. Defaults to 1 */
  val?: number;
};

export type PuzzleData = PuzzleMetadata & {
  order: number;

  /**
   * Emoji used to represent this puzzle in internal Discord alerts.
   * May be empty if not defined. Currently only used by Django.
   */
  emoji: string;

  /**
   * Round that the puzzle belongs to. Only used by Django for minor
   * functionality like sorting puzzles on the bigboard and other
   * stats displays.
   */
  round: string;
  /**
   * If set, follows the traditional unlock mechanism where it unlocks
   * once its requirements are satisifed. Otherwise, its unlock must
   * be triggered manually by the server.
   */
  isAutoUnlocked?: true;
  /**
   * Values needed to be reached by unlock groups for the puzzle
   * to be eligible for unlocking.
   */
  unlockInEdges: UnlockingInEdge[];
  /**
   * Values added to the unlock and solve unlock group counters
   * respectively when this puzzle is unlocked or solved.
   */
  unlockOutEdges: UnlockingOutEdge[];
  /** Conditions needed to be met to reveal the solution. */
  solutionInEdges?: UnlockingInEdge[];
  /** The number of solves needed before this puzzle can be unlocked. */
  introReq?: number;
  /**
   * The puzzle's answer. If no answer is specified, then an answer
   * cannot be called in and the solve page is inaccessible until
   * the puzzle is solved (so that solvers can leave feedback and
   * see card unlocks).
   */
  answer: string | null;
  /**
   * Whether the puzzle represents a "full" and "real" puzzle from the
   * perspective of Django. When enabled:
   * - Feedback and hints pages will be enabled for the puzzle.
   * - The puzzle will show up on bigboard.
   */
  isFullPuzzle?: true;
  /**
   * Hope you get for solving this puzzle, if different from
   * the default.
   */
  hopeForSolve?: number;
  /**
   * The rarity that is displayed to the user, if different from
   * the actual gacha rarity.
   */
  displayedRarity?: Rarity;

  isCustomPuzzleAvailableInGacha?: true;
  customPuzzleContent?: string;
  customPuzzleSolutionContent?: string;

  authors?: string[];
  otherCredits?: string;
};

export type AllPuzzleContent = {
  puzzles: { [puzName: string]: PuzzleContent };
  solutions: { [puzName: string]: PuzzleContent };
};

export type AllPuzzles = {
  puzzles: PuzzleData[];
};

export type PuzzleMessagesData = {
  [puzName: string]: {
    // Message to show for a given answer.
    // If null, use the default "Keep going!" message.
    [answer: string]: string | null;
  };
};

export type CannedHints = { [puzName: string]: string[] };
export type KeyframesData = {
  firstKeyframeIds: { [puzName: string]: string };
  keyframes: { [keyframeId: string]: KeyframeData };
};

type CollectibleData = CollectibleMetadata & {
  maxNumPerTeam?: number;
};
export type CollectiblesData = {
  [collectibleId: string]: CollectibleData;
};

export type PuzzlesDBData = {
  allPuzzles: AllPuzzles;
  allPuzzleContent: AllPuzzleContent;
  puzzleMessages: PuzzleMessagesData;
  cannedHints: CannedHints;
  keyframesData: KeyframesData;
  collectiblesData: CollectiblesData;
};

export const isPuzzleMicropuzzle = (puzData: PuzzleMetadata): boolean => {
  const { isStory = false, isCelestial = false, isBigMeta = false } = puzData;
  return !isStory && !isCelestial && !isBigMeta;
};

/**
 * PuzzlesDB data should be readonly, so store custom puzzles separately
 * from the puzzles DB.
 */
export type CustomPuzzles = {
  [puzName: string]: PuzzleData;
};

export const isUsedForSolveCount = ({ isStory }: PuzzleMetadata) =>
  !(isStory ?? false);
