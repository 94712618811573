import { ScopeType } from "sockapi/scope-types";
import NavTeamName from "components/NavTeamName";
import SiteNavDisplay from "components/SiteNavDisplay";
import { Spinner } from "components/Loading";
import { useTeamScopeState, useGlobalScopeState } from "stores/ScopeStateStore";
import { useShouldDisplayAdmin } from "stores/ServerInteractionStore";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import Logo from "images/logo.svg?react";
import Logo2 from "images/logo2.svg?react";
import type { PartialOptions } from "overlayscrollbars";
import "./HuntLib.css";

export const defaultOverlayScrollbarsOptions: PartialOptions = {
  showNativeOverlaidScrollbars: true,
  scrollbars: { theme: "os-theme-light" },
};

export const autohideOverlayScrollbarsOptions: PartialOptions = {
  showNativeOverlaidScrollbars: true,
  scrollbars: { theme: "os-theme-light", autoHide: "leave" },
};

function MenuButton() {
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const openTaskCount = Object.values(
    useGlobalScopeState(ScopeType.ADMIN_QUEUE, shouldDisplayAdmin)?.tasks ?? {}
  )?.filter(({ respondTime }) => respondTime === undefined).length;
  const teamScopeState = useTeamScopeState();
  const pastIntro = Object.values(teamScopeState?.puzzles ?? {}).some(
    (puz) => puz.solveTime !== undefined
  );
  return (
    <>
      {openTaskCount > 0 ? <span>{openTaskCount}</span> : null}
      {pastIntro ? <Logo2 /> : <Logo />}
    </>
  );
}

export const setup = () => {
  const siteNav = document.querySelector(".site-nav");
  if (siteNav !== null) {
    ReactDOM.createRoot(siteNav).render(
      <React.StrictMode>
        <SiteNavDisplay />
      </React.StrictMode>
    );
  }

  const navTeamName = document.getElementById("nav-team-name");
  if (navTeamName !== null) {
    ReactDOM.createRoot(navTeamName).render(
      <React.StrictMode>
        <NavTeamName />
      </React.StrictMode>
    );
  }

  const menuButton = document.getElementById("menu-btn");
  if (menuButton !== null) {
    ReactDOM.createRoot(menuButton).render(
      <React.StrictMode>
        <MenuButton />
      </React.StrictMode>
    );
  }

  const gameContainer = document.getElementById("game-root");
  if (gameContainer !== null) {
    const App = React.lazy(() => import("./App"));
    ReactDOM.createRoot(gameContainer).render(
      <React.StrictMode>
        <Suspense
          fallback={<Spinner size={128} text="Everything is going smoothly." />}
        >
          <App />
        </Suspense>
      </React.StrictMode>
    );
  }
};
