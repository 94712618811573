import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

export const HOPELESS_CONFIG = {
  puzName: "puz264",
  hopeLimit: 1,
};

export type TeamHopelessScopeSpec = {
  identifier: ScopeTeamIdentifier;
  step: {
    solve?: true;
  };
  stepResponse: "";
};

export const teamHopelessScopeHooks: ScopeHooks<ScopeType.TEAM_HOPELESS> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
};
