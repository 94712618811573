import { PeriodicTimer } from "util/timers";
import {
  WSReqUpdateSubscriptionRequestType,
  SockAPIUpdateSubscriptionError,
} from "sockapi/sockapi-messages";
import { scopeToString } from "sockapi/scopes";
import { SockAPIScope } from "sockapi/scope-state";

/**
 * The amount of time to wait after all components have unsubscribed
 * from a scope to actually unsubscribe from it.
 */
const SUBSCRIPTION_EXPIRY_DELAY_MS = 10 * 60 * 1000; // 10 minutes

type ActiveScopeSubscription = {
  scope: SockAPIScope;
  /** Number of subscription clients requesting this scope. */
  numClients: number;
};

type StaleScopeSubscription = {
  scope: SockAPIScope;
  /** The time up to which the subscription was active. */
  lastUsedTime: number;
};

interface SockAPISubscriptionsClientCallbacks {
  subscribe(scopes: SockAPIScope[]): void;
  unsubscribe(scopes: SockAPIScope[]): void;
}

class ClientSubscriptionsTracker {
  /**
   * Active scopes, indexed by scopeId. A scope is active as
   * long as some component is subscribed to it.
   */
  activeScopes: Map<string, ActiveScopeSubscription>;
  /**
   * Stale scopes, indexed by scopeId.
   * The client should not unsubscribe from stale scopes until
   * after they expire.
   * This allows us to reuse subscriptions, for example, when a
   * client navigates between pages that require the same
   * subscriptions.
   */
  staleScopes: Map<string, StaleScopeSubscription>;
  /**
   * scopeIds of scopes that we have a pending subscription request
   * going for. To simplify error handling, don't unsubscribe from
   * a scope until all subscription requests are complete.
   */
  pendingScopeIds: Set<string>;
  /**
   * scopeIds of failed subscriptions that we need to resubscribe to
   * if requested.
   */
  erroredScopeIds: Set<string>;

  constructor() {
    this.activeScopes = new Map();
    this.staleScopes = new Map();
    this.pendingScopeIds = new Set();
    this.erroredScopeIds = new Set();
  }

  /**
   * This is marked private as it leaves the tracker in an inconsistent
   * state, i.e. with an active scope subscription with zero clients.
   */
  private getOrCreateActiveScope(scope: SockAPIScope) {
    const scopeId = scopeToString(scope);
    const subscription = this.activeScopes.get(scopeId);
    if (subscription !== undefined) return subscription;
    const newActiveScope = { scope, numClients: 0 };
    this.activeScopes.set(scopeId, newActiveScope);
    return newActiveScope;
  }

  /**
   * Adds a new scope subscription, and returns if we are not already
   * holding a subscription (stale or not) to the scope.
   */
  add(scope: SockAPIScope): boolean {
    const scopeId = scopeToString(scope);
    const wasStale = this.staleScopes.delete(scopeId);
    const subscription = this.getOrCreateActiveScope(scope);
    const needSubscribe =
      (this.erroredScopeIds.has(scopeId) &&
        !this.pendingScopeIds.has(scopeId)) ||
      (!wasStale && subscription.numClients === 0);
    subscription.numClients++;
    if (needSubscribe) this.pendingScopeIds.add(scopeId);
    return needSubscribe;
  }

  /**
   * Removes a scope subscription, and returns if doing so caused the
   * scope to become stale.
   * This must only be called on active scopes.
   */
  remove(scope: SockAPIScope): boolean {
    const scopeId = scopeToString(scope);
    const subscription = this.activeScopes.get(scopeId);
    if (subscription === undefined) throw new Error("not active");
    subscription.numClients--;
    const isStale = subscription.numClients === 0;
    if (isStale) {
      this.activeScopes.delete(scopeId);
      this.staleScopes.set(scopeId, {
        scope,
        lastUsedTime: Date.now(),
      });
    }
    return isStale;
  }

  handleAddResponse(scope: SockAPIScope, isErrored: boolean) {
    const scopeId = scopeToString(scope);
    if (!this.pendingScopeIds.delete(scopeId) && isErrored)
      console.error(
        `got an subscription error response for ${scopeId} but no request is pending`
      );
    if (isErrored) this.erroredScopeIds.add(scopeId);
    else this.erroredScopeIds.delete(scopeId);
  }

  /** Returns expired scopes and removes them from tracking. */
  flushExpired() {
    const timeNow = Date.now();
    const expiredEntries = [...this.staleScopes].filter(
      ([scopeId, { lastUsedTime }]) =>
        !this.pendingScopeIds.has(scopeId) &&
        timeNow - lastUsedTime >= SUBSCRIPTION_EXPIRY_DELAY_MS
    );
    const validExpiredScopes = expiredEntries
      .filter(([scopeId, subscription]) => !this.erroredScopeIds.has(scopeId))
      .map(([scopeId, { scope }]) => scope);
    for (const [scopeId, subscription] of expiredEntries) {
      this.staleScopes.delete(scopeId);
      this.erroredScopeIds.delete(scopeId);
    }
    return validExpiredScopes;
  }

  assertNoActiveAndDropAll() {
    if (this.activeScopes.size > 0)
      throw new Error("expect no active subscriptions");
    this.staleScopes.clear();
    this.pendingScopeIds.clear();
    this.erroredScopeIds.clear();
  }
}

export class SockAPISubscriptionsClient {
  callbacks: SockAPISubscriptionsClientCallbacks;
  tracker: ClientSubscriptionsTracker;
  cleanupTimer: PeriodicTimer;

  constructor(callbacks: SockAPISubscriptionsClientCallbacks) {
    this.callbacks = callbacks;
    this.tracker = new ClientSubscriptionsTracker();
    this.cleanupTimer = new PeriodicTimer();
  }

  add(scope: SockAPIScope) {
    if (this.tracker.add(scope)) this.callbacks.subscribe([scope]);
  }

  addMultiple(scopes: SockAPIScope[]) {
    const newScopes = scopes.filter((scope) => this.tracker.add(scope));
    if (newScopes.length <= 0) return;
    this.callbacks.subscribe(newScopes);
  }

  remove(scope: SockAPIScope) {
    this.tracker.remove(scope);
    // Even if the scope becomes stale, don't unsubscribe
    // until it actually expires.
  }

  removeMultiple(scopes: SockAPIScope[]) {
    for (const scope of scopes) {
      this.tracker.remove(scope);
    }
    // Even if the scope becomes stale, don't unsubscribe
    // until it actually expires.
  }

  async startPeriodicCleanupAsync() {
    await this.cleanupTimer.startAsync(async () => {
      const expiredScopes = this.tracker.flushExpired();
      this.callbacks.unsubscribe(expiredScopes);
    }, SUBSCRIPTION_EXPIRY_DELAY_MS / 2);
  }

  handleSubscriptionErrors(errors: SockAPIUpdateSubscriptionError[]) {
    for (const errDesc of errors) {
      if (errDesc.type === WSReqUpdateSubscriptionRequestType.ADD)
        this.tracker.handleAddResponse(errDesc.scope, true);
    }
  }

  /** Scope assigns tell us that a scope is valid. */
  handleScopeAssign(scope: SockAPIScope) {
    this.tracker.handleAddResponse(scope, false);
  }

  /**
   * If we disconnect and reconnect, we need to ensure that
   * we resubscribe to stale scopes.
   * The client should ensure that all subscriptions are removed
   * by the time we reconnect.
   */
  onAuth() {
    this.tracker.assertNoActiveAndDropAll();
  }

  teardown() {
    this.cleanupTimer.stop();
  }
}
