import { useState, useRef, useMemo } from "react";

/**
 * Create a lock to be used to block UI from being used until
 * a task is complete.
 */
export const useUILockWithStore = ([isHeld, setIsHeld]: [
  boolean,
  (newIsHeld: boolean) => void
]) => {
  const cancelFunc = useRef<(() => void) | null>(null);
  const cancel = useMemo(
    () => () => {
      if (cancelFunc.current !== null) {
        cancelFunc.current();
        cancelFunc.current = null;
      }
      setIsHeld(false);
    },
    [setIsHeld]
  );

  return {
    isHeld,
    acquire: () => setIsHeld(true),
    release: () => setIsHeld(false),
    cancel,
    acquireAndRun: (func: (getIsCanceled: () => boolean) => Promise<void>) => {
      // If the lock is held, then there might be a race condition
      // with a duplicate request happening before the UI gets locked,
      // so just drop the request.
      if (isHeld) return;
      // Create a new isCanceled state. This needs to be unique for
      // each acquire, so we can't just use a ref.
      let isCanceled = false;
      cancelFunc.current = () => {
        isCanceled = true;
      };
      setIsHeld(true);
      (async () => {
        await func(() => isCanceled);
        // Setting React state is a no-op if the component is unmounted,
        // so it's safe to release here.
        setIsHeld(false);
      })().catch(console.error);
    },
  };
};

export const useUILock = () => {
  return useUILockWithStore(useState(false));
};
