import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

export type TeamMembersScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    members: string[];
  };
  update: {
    members: string[];
  };
};

export const teamMembersScopeHooks: ScopeHooks<ScopeType.TEAM_MEMBERS> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    scopeState.members = payload.members;
  },
};
