import "./Tooltip.css";
import {
  HTMLProps,
  PropsWithChildren,
  useRef,
  useState,
  ReactNode,
} from "react";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  Placement,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";

interface TooltipProps {
  text?: ReactNode;
  format?: boolean;
  placement?: Placement;
  into?: boolean;
  noWrap?: boolean;
  directRef?: (props: Record<string, unknown>) => ReactNode;
  disableRecursion?: boolean;
  disableTooltips?: boolean;
}

const TooltipInternal = (
  props: PropsWithChildren<TooltipProps> & HTMLProps<HTMLDivElement>
) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const {
    text,
    format,
    placement = "top",
    into = false,
    noWrap = false,
    directRef,
    disableRecursion,
    disableTooltips,
    children,
    ...domProps
  } = props;

  const nodeId = useFloatingNodeId();
  const { context, refs, floatingStyles } = useFloating({
    nodeId,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset(5),
      flip({
        crossAxis: false,
      }),
      shift({
        padding: 5,
      }),
      arrow({ element: arrowRef }),
    ],
  });

  const hover = useHover(context, {
    handleClose:
      (format || into) && !disableRecursion ? safePolygon() : undefined,
    move: false,
    delay: { open: 150 },
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const show = open && text && !disableTooltips;

  return (
    <>
      {directRef ? (
        directRef({
          ref: refs.setReference,
          ...getReferenceProps(domProps),
        })
      ) : (
        <div
          className="tooltip-container"
          ref={refs.setReference}
          {...getReferenceProps(domProps)}
        >
          {children}
        </div>
      )}
      {show ? (
        <FloatingNode id={nodeId}>
          <FloatingPortal>
            <div
              className="tooltip"
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                ...(noWrap
                  ? {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      padding: 0,
                    }
                  : {}),
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              {...getFloatingProps()}
            >
              {text}
              <FloatingArrow
                className="tooltip-arrow"
                ref={arrowRef}
                context={context}
              />
            </div>
          </FloatingPortal>
        </FloatingNode>
      ) : null}
    </>
  );
};

/** A tooltip that appears when the child is hovered. */
const Tooltip = (
  props: PropsWithChildren<TooltipProps> & HTMLProps<HTMLDivElement>
) => {
  // black magic... don't worry about it
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <TooltipInternal {...props} />
      </FloatingTree>
    );
  }

  return <TooltipInternal {...props} />;
};

export default Tooltip;
