import { ScopeHooks } from "sockapi/scope-specs";
import { getPuzzleScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopePuzzleIdentifier } from "sockapi/scopes";

export type PuzzleStatsAdmin = {
  [key: string]: number;
};

export type AdminStatsScopeSpec = {
  identifier: ScopePuzzleIdentifier;
  state: PuzzleStatsAdmin;
  update: PuzzleStatsAdmin;
};

export const adminStatsScopeHooks: ScopeHooks<ScopeType.ADMIN_STATS> = {
  identityFunc: (scope) => scope,
  getIdComponents: getPuzzleScopeIdComponents,
  update: (state, payload) => {
    Object.assign(state, payload);
  },
};
