import { DistributiveOmit } from "util/util";
import { SockAPIScopeState } from "sockapi/scope-state";
import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

export const PUZZLE_139_CONFIG = {
  puzName: "puz139",
  maxLength: 1000,
};

export enum Puzzle139StatusType {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum Puzzle139SharePermission {
  BOTH = "both",
  FACT = "fact",
  NONE = "none",
}

export type Puzzle139Status = (
  | {
      type: Puzzle139StatusType.PENDING;
    }
  | {
      type: Puzzle139StatusType.ACCEPTED;
      comment: string;
      /** Time when the review was completed. */
      reviewTime: number;
    }
  | {
      type: Puzzle139StatusType.REJECTED;
      reason: string;
      /** Time when the review was completed. */
      reviewTime: number;
    }
) & {
  submitTime: number;
};

/**
 * Returns the time when the submission was responeded to in admin review,
 * if it has.
 */
export const getTeamPuzzle139ReviewTime = ({
  status: submitStatus,
}: SockAPIScopeState<ScopeType.TEAM_PUZZLE_139>): number | null => {
  if (submitStatus === undefined) return null;
  switch (submitStatus.type) {
    case Puzzle139StatusType.ACCEPTED:
    case Puzzle139StatusType.REJECTED:
      return submitStatus.reviewTime;
    default:
      return null;
  }
};

export const isTeamPuzzle139Editable = ({
  status: submitStatus,
}: SockAPIScopeState<ScopeType.TEAM_PUZZLE_139>) => {
  return !(
    submitStatus !== undefined &&
    [Puzzle139StatusType.PENDING, Puzzle139StatusType.ACCEPTED].includes(
      submitStatus.type
    )
  );
};

type Puzzle139SetStatusPayload = DistributiveOmit<
  Puzzle139Status,
  "reviewTime"
>;

export type TeamPuzzle139ScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    submissionText?: string;
    diagramUrl?: string;
    status?: Puzzle139Status;
    sharePermission?: Puzzle139SharePermission;
  };
  update: {
    submissionText?: string;
    diagramUrl?: string;
    setStatus?: Puzzle139Status;
    sharePermission?: Puzzle139SharePermission;
  };
  step: {
    submit?: {
      submissionText: string;
      sharePermission: Puzzle139SharePermission;
    };
    /** Trigger the server to download the diagram URL from the backend. */
    downloadDiagramUrl?: true;
    setStatus?: Puzzle139SetStatusPayload;
  };
  stepResponse: "";
  backendState: SockAPIScopeState<ScopeType.TEAM_PUZZLE_139>;
};

export const teamPuzzle139ScopeHooks: ScopeHooks<ScopeType.TEAM_PUZZLE_139> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const { submissionText, diagramUrl, setStatus, sharePermission } = payload;
    if (submissionText !== undefined)
      scopeState.submissionText = submissionText;
    if (diagramUrl !== undefined) scopeState.diagramUrl = diagramUrl;
    if (setStatus !== undefined) scopeState.status = setStatus;
    if (sharePermission !== undefined)
      scopeState.sharePermission = sharePermission;
  },
};
