import { KeysMatching } from "util/util";
import { Scope } from "sockapi/scopes";
import { ScopeSpecs } from "sockapi/scope-specs";

type SockAPIScopeStepPayloadDict = {
  [TScopeType in KeysMatching<
    ScopeSpecs,
    { step: unknown }
  >]: ScopeSpecs[TScopeType]["step"];
};

export type SockAPISteppableScopeType = keyof SockAPIScopeStepPayloadDict;
export type SockAPISteppableScope = Extract<
  Scope,
  { type: SockAPISteppableScopeType }
>;

export type SockAPIScopeStepPayload<
  TScopeType extends SockAPISteppableScopeType
> = SockAPIScopeStepPayloadDict[TScopeType];

export type SockAPIScopeStep<
  TScope extends SockAPISteppableScope = SockAPISteppableScope
> = {
  scope: TScope;
  payload: SockAPIScopeStepPayload<TScope["type"]>;
};

type SockAPIScopeStepResponsePayloadDict = {
  [TScopeType in KeysMatching<
    ScopeSpecs,
    { stepResponse: NonNullable<unknown> }
  >]: ScopeSpecs[TScopeType]["stepResponse"];
};

export type SockAPIStepResponseScopeType =
  keyof SockAPIScopeStepResponsePayloadDict;
export type SockAPIStepResponseScope = Extract<
  Scope,
  { type: SockAPIStepResponseScopeType }
>;

export type SockAPIScopeStepResponsePayload<
  TScopeType extends SockAPIStepResponseScopeType
> = SockAPIScopeStepResponsePayloadDict[TScopeType];

export type SockAPIScopeStepResponse<
  TScope extends SockAPIStepResponseScope = SockAPIStepResponseScope
> = {
  scope: TScope;
  payload: SockAPIScopeStepResponsePayload<TScope["type"]>;
};

export const isScopeStepResponseOfType = <
  TScopeType extends SockAPIStepResponseScopeType
>(
  stepResponse: SockAPIScopeStepResponse,
  type: TScopeType
): stepResponse is SockAPIScopeStepResponse<Scope<TScopeType>> => {
  return stepResponse.scope.type === type;
};
