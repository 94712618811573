import { useMemo } from "react";
import produce from "immer";
import { create } from "zustand";
import settings from "settings";
import type { BlocksPuzzleBoardState } from "components/PuzzleContent/BlocksPuzzle";

// Update this whenever changes are made to BrowserWidePersistentState
// to ensure that the stale state gets deleted.
const BROWSER_WIDE_PERSISTENT_STATE_SCHEMA_VERSION = 2;

// State that is saved to localStorage and reactively shared
// across all tabs in the browser. Updates to the state in one
// tab are immediately reflected in other tabs.

export type CompletedStoryBeats = {
  [puzName: string]: true;
};
export type CutsceneState = {
  /** keyframdIds of the user's history for this cutscene. */
  history: string[];
  /**
   * Position in history that the user is currently at. Null if the user
   * has not entered the cutscene, or has exited it.
   */
  historyPosition: number | null;
};
type CutscenesState = {
  [puzName: string]: CutsceneState;
};
type BlocksPuzzleBoardsState = {
  [boardId: string]: BlocksPuzzleBoardState;
};
type BrowserWidePersistentState = {
  version: number;
  completedStoryBeats: CompletedStoryBeats;
  cutscenesState: CutscenesState;
  viewTimes?: { [puzName: string]: number };
  pinnedPuzzles?: string[];
  blocksPuzzleBoardsState?: BlocksPuzzleBoardsState;
};

type BrowserWidePersistentStateUpdate = {
  completedStoryBeats?: { [puzName: string]: boolean };
  cutscenesState?: { [puzName: string]: CutsceneState | null };
  viewTimes?: { [puzName: string]: number };
  pinnedPuzzles?: string[];
  blocksPuzzleBoardsState?: {
    [boardId: string]: BlocksPuzzleBoardState | null;
  };
  resetProgress?: true;
};

const makeInitialBrowserWidePersistentState =
  (): BrowserWidePersistentState => ({
    version: BROWSER_WIDE_PERSISTENT_STATE_SCHEMA_VERSION,
    completedStoryBeats: {},
    cutscenesState: {},
  });

const applyBrowserWidePersistentStateUpdate = (
  state: BrowserWidePersistentState,
  {
    completedStoryBeats,
    cutscenesState,
    viewTimes,
    pinnedPuzzles,
    blocksPuzzleBoardsState,
    resetProgress,
  }: BrowserWidePersistentStateUpdate
): void => {
  for (const [puzName, isCompleted] of Object.entries(
    completedStoryBeats ?? {}
  )) {
    if (isCompleted) state.completedStoryBeats[puzName] = true;
    else delete state.completedStoryBeats[puzName];
  }
  for (const [puzName, cutsceneState] of Object.entries(cutscenesState ?? {})) {
    if (cutsceneState !== null) state.cutscenesState[puzName] = cutsceneState;
    else delete state.cutscenesState[puzName];
  }
  if (viewTimes !== undefined)
    state.viewTimes = Object.assign(state.viewTimes ?? {}, viewTimes);
  if (pinnedPuzzles !== undefined) state.pinnedPuzzles = pinnedPuzzles;
  for (const [boardId, boardState] of Object.entries(
    blocksPuzzleBoardsState ?? {}
  )) {
    state.blocksPuzzleBoardsState ??= {};
    if (boardState !== null)
      state.blocksPuzzleBoardsState[boardId] = boardState;
    else delete state.blocksPuzzleBoardsState[boardId];
  }
  if (resetProgress ?? false) {
    state.completedStoryBeats = {};
    state.cutscenesState = {};
    delete state.viewTimes;
    delete state.pinnedPuzzles;
    delete state.blocksPuzzleBoardsState;
  }
};

const browserWidePersistentStateStorageKey = `${settings.browserStoragePrefix}__browser_wide_persistent_state`;
const loadBrowserWidePersistentState = (): BrowserWidePersistentState => {
  const rawVal = localStorage.getItem(browserWidePersistentStateStorageKey);
  const val = rawVal === null ? null : JSON.parse(rawVal);
  if (
    val === null ||
    val.version !== BROWSER_WIDE_PERSISTENT_STATE_SCHEMA_VERSION
  )
    return makeInitialBrowserWidePersistentState();
  return val;
};

const saveBrowserWidePersistentState = (state: BrowserWidePersistentState) => {
  try {
    localStorage.setItem(
      browserWidePersistentStateStorageKey,
      JSON.stringify(state)
    );
  } catch (err) {
    console.error(err);
  }
};

type BrowserWidePersistentStateStoreState = {
  // Use separate BroadcastChannel instances for sender and receiver
  // to receive messages in the same window.
  senderBC: BroadcastChannel;
  receiverBC: BroadcastChannel;
  state: BrowserWidePersistentState;
  update: (upd: BrowserWidePersistentStateUpdate) => void;
  overwrite: (state: BrowserWidePersistentState) => void;
};
export const useBrowserWidePersistentStateStore =
  create<BrowserWidePersistentStateStoreState>((set) => ({
    senderBC: new BroadcastChannel(browserWidePersistentStateStorageKey),
    receiverBC: new BroadcastChannel(browserWidePersistentStateStorageKey),
    state: loadBrowserWidePersistentState(),
    update: (upd) =>
      set(
        produce((state) =>
          applyBrowserWidePersistentStateUpdate(state.state, upd)
        )
      ),
    overwrite: (state) => set({ state }),
  }));

const useBrowserWidePersistentState = <TRet>(
  statePick: (state: BrowserWidePersistentState) => TRet
): TRet =>
  useBrowserWidePersistentStateStore((state) => statePick(state.state));

export const useUpdateBrowserWidePersistentState = () => {
  const senderBC = useBrowserWidePersistentStateStore(
    ({ senderBC }) => senderBC
  );
  return useMemo(
    () => (upd: BrowserWidePersistentStateUpdate) => {
      senderBC.postMessage(JSON.stringify(upd));
    },
    [senderBC]
  );
};

// TODO: actually all calls to this can be non-reactive
export const updateBrowserWidePersistentStateNonReactive = (
  upd: BrowserWidePersistentStateUpdate
) => {
  const { senderBC } = useBrowserWidePersistentStateStore.getState();
  senderBC.postMessage(JSON.stringify(upd));
};

export const useCompletedStoryBeats = () =>
  useBrowserWidePersistentState((state) => state.completedStoryBeats);
export const makeDefaultCutsceneState = () => ({
  history: [],
  historyPosition: null,
});
export const useCutsceneState = (puzName: string) =>
  useBrowserWidePersistentState((state) => state.cutscenesState[puzName]) ??
  makeDefaultCutsceneState();
export const useBlocksPuzzleBoardState = (boardId: string | null) =>
  useBrowserWidePersistentState((state) =>
    boardId !== null ? state.blocksPuzzleBoardsState?.[boardId] ?? null : null
  );
export const useViewTimes = () =>
  useBrowserWidePersistentState((state) => state.viewTimes) ?? {};
export const usePinnedPuzzles = () =>
  useBrowserWidePersistentState((state) => state.pinnedPuzzles) ?? [];

export const shareBrowserWidePersistentState = () => {
  useBrowserWidePersistentStateStore.getState().receiverBC.onmessage = (e) => {
    const upd = JSON.parse(e.data) as BrowserWidePersistentStateUpdate;
    useBrowserWidePersistentStateStore.getState().update(upd);
    // Every open tab will save the state, but it's fine for now I guess.
    // Make sure to call getState() again to get the new state after
    // the update.
    saveBrowserWidePersistentState(
      useBrowserWidePersistentStateStore.getState().state
    );
  };
};
