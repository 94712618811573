import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { CollectibleMetadata } from "sockapi/gacha/pulls";

export type CollectibleState = {
  num: number;
  timestamp: number;
};

export type SockAPITeamCollectibleState = CollectibleMetadata &
  CollectibleState;

export type TeamCollectiblesScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    collectibles: {
      [collectibleId: string]: SockAPITeamCollectibleState;
    };
  };
  update: {
    /**
     * For simplicity, send over the entire collectible metadata
     * with each update so we don't have to track which collectibles
     * clients have seen.
     */
    collectibles: {
      [collectibleId: string]: SockAPITeamCollectibleState;
    };
  };
};

export const teamCollectiblesScopeHooks: ScopeHooks<ScopeType.TEAM_COLLECTIBLES> =
  {
    identityFunc: (scope) => scope,
    getIdComponents: getTeamScopeIdComponents,
    update: (scopeState, payload) => {
      const { collectibles } = payload;
      for (const [collectibleId, collectibleData] of Object.entries(
        collectibles ?? {}
      )) {
        scopeState.collectibles[collectibleId] = collectibleData;
      }
    },
  };
