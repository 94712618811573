import { Spinner } from "components/Loading";

export default function DownloadFailedDisplay(props: {
  refresh: () => void;
  isRefreshing: boolean;
}) {
  const { refresh, isRefreshing } = props;
  return (
    <div>
      Failed to download state.{" "}
      {isRefreshing ? (
        <Spinner inline />
      ) : (
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            refresh();
          }}
        >
          Retry
        </a>
      )}
    </div>
  );
}
