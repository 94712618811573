import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

type TeamPuzzleCannedHintsSockAPIScopeState = {
  numTotal: number;
  numOpened: number;
  numMax: number;
};

export enum OpenCannedHintResponseType {
  SUCCESS = "success",
  INVALID_INDEX = "invalid_index",
}

export type TeamCannedHintsScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    cannedHints: { [puzName: string]: TeamPuzzleCannedHintsSockAPIScopeState };
  };
  update: {
    cannedHints?: {
      [puzName: string]: TeamPuzzleCannedHintsSockAPIScopeState;
    };
  };
  step: {
    openCannedHint?: {
      puzName: string;
      index: number;
    };
  };
  stepResponse: {
    type: OpenCannedHintResponseType;
  };
};

export const teamCannedHintsScopeHooks: ScopeHooks<ScopeType.TEAM_CANNED_HINTS> =
  {
    identityFunc: (scope) => scope,
    getIdComponents: getTeamScopeIdComponents,
    update: (scopeState, payload) => {
      const { cannedHints } = payload;
      for (const [puzName, cannedHintsForPuzzle] of Object.entries(
        cannedHints ?? {}
      )) {
        scopeState.cannedHints[puzName] = cannedHintsForPuzzle;
      }
    },
  };
