import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { SockAPIScopeState } from "sockapi/scope-state";

export const PUZNAME_FARMER = "farmer";

export type FarmerStageCategory = {
  name: string;
  threshold: number;
};

export type FarmerServerStep = {
  s: string;
  candidates: string[];
  category?: FarmerStageCategory;
};

export enum FarmerServerStepResponseType {
  ERROR = "error",
  INVALID_WORD = "invalid_word",
  NOT_IN_CATEGORY = "not_in_category",
  SUCCESS = "success",
}

export type FarmerServerStepResponse =
  | {
      type:
        | FarmerServerStepResponseType.ERROR
        | FarmerServerStepResponseType.INVALID_WORD
        | FarmerServerStepResponseType.NOT_IN_CATEGORY;
    }
  | {
      type: FarmerServerStepResponseType.SUCCESS;
      /** The similarity of the given word to each candidate. */
      similarities: number[];
    };

export type FarmerServerInterface = {
  getSimilaritiesAsync: (
    step: FarmerServerStep
  ) => Promise<FarmerServerStepResponse>;
  getLocksContention: () => number;
};

export type FarmerGraphNode = {
  nodeId: string;
  content: string;
};
export type FarmerGraphEdge = {
  nodeId1: string;
  nodeId2: string;
};

export type FarmerGraphEvaluation = {
  success: boolean;
  highlightNodes?: string[];
};

export type FarmerGraph = {
  nodes: FarmerGraphNode[];
  edges: FarmerGraphEdge[];
  /** History of nodes removed by undo to be put back on redo. */
  redoNodes: FarmerGraphNode[];
  evaluation?: FarmerGraphEvaluation;
};

export const makeInitFarmerGraph = (): FarmerGraph => {
  return {
    nodes: [],
    edges: [],
    redoNodes: [],
  };
};

export type SockAPIFarmerStageMetadata = {
  displayName: string;
  instruction?: string;
  targetImgName?: string;
  targetNumNodes?: number;
};

type SockAPIFarmerStageTeamData = {
  isSolved?: true;
  graph: FarmerGraph;
  /** Undo history. */
  prevGraphs: FarmerGraph[];
  /** Redo history. */
  nextGraphs: FarmerGraph[];
};

export type SockAPIFarmerStage = SockAPIFarmerStageTeamData & {
  metadata: SockAPIFarmerStageMetadata;
};

export type FarmerStageUpdate = {
  addNodes?: FarmerGraphNode[];
  addEdges?: FarmerGraphEdge[];
  setSolved?: true;
  evaluation?: FarmerGraphEvaluation;
  undo?: true;
  redo?: true;
  reset?: true;
};

export type FarmerAddNodePayload = {
  graphId: string;
  content: string;
};

export enum FarmerAddNodeResponseType {
  SUCCESS = "success",
  SERVER_ERROR = "server_error",
  INVALID_WORD = "invalid_word",
  NOT_IN_CATEGORY = "not_in_category",
  INVALID_NODE = "invalid_node",
  NO_EDGE = "no_edge",
  DUPLICATE = "duplicate",
  TOO_MANY_NODES = "too_many_nodes",
}
type FarmerAddNodeResponse = {
  type: FarmerAddNodeResponseType;
};

export const isFarmerStageUndoable = (
  stage: SockAPIFarmerStageTeamData
): boolean => {
  return stage.graph.nodes.length > 0 || stage.prevGraphs.length > 0;
};
export const isFarmerStageRedoable = (
  stage: SockAPIFarmerStageTeamData
): boolean => {
  return stage.graph.redoNodes.length > 0 || stage.nextGraphs.length > 0;
};

const clearFarmerStageRedoHistory = (
  stage: SockAPIFarmerStageTeamData
): void => {
  const { graph } = stage;
  const redoNodesSet = new Set(graph.redoNodes.map(({ nodeId }) => nodeId));
  graph.edges = graph.edges.filter(
    (edge) => !redoNodesSet.has(edge.nodeId1) && !redoNodesSet.has(edge.nodeId2)
  );
  graph.redoNodes = [];
  stage.nextGraphs = [];
};

export type TeamFarmerScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    stages: {
      [graphId: string]: SockAPIFarmerStage;
    };
    mapEdges: FarmerGraphEdge[];
    mapExtraEdges: FarmerGraphEdge[];
  };
  update: {
    updStages?: {
      [graphId: string]: FarmerStageUpdate;
    };
    addStages?: {
      [graphId: string]: SockAPIFarmerStage;
    };
    mapEdges?: FarmerGraphEdge[];
    mapExtraEdges?: FarmerGraphEdge[];
  };
  step: {
    isWorkshop?: true;

    addNode?: FarmerAddNodePayload;
    undoGraph?: string;
    redoGraph?: string;
    /** graphId of the graph to reset, if any. */
    resetGraph?: string;

    adminSetGraphSolved?: string;
    adminResetProgress?: true;
  };
  stepResponse: {
    addNodeResp?: FarmerAddNodeResponse;
  };
  backendState: Omit<SockAPIScopeState<ScopeType.TEAM_FARMER>, "stages"> & {
    stages: {
      [graphId: string]: SockAPIFarmerStageTeamData;
    };
  };
};

export const teamFarmerScopeHooks: ScopeHooks<ScopeType.TEAM_FARMER> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const { updStages, addStages, mapEdges, mapExtraEdges } = payload;
    for (const [graphId, upd] of Object.entries(updStages ?? {})) {
      const { addNodes, addEdges, setSolved, evaluation, undo, redo, reset } =
        upd;
      const stage = scopeState.stages[graphId];
      const { graph } = stage;
      for (const nodeId of addNodes ?? []) {
        clearFarmerStageRedoHistory(stage);
        graph.nodes.push(nodeId);
      }
      for (const edge of addEdges ?? []) {
        graph.edges.push(edge);
      }
      if (setSolved ?? false) stage.isSolved = true;
      if (evaluation !== undefined) stage.graph.evaluation = evaluation;
      if (undo ?? false) {
        const nodeToDelete = graph.nodes.pop();
        if (nodeToDelete !== undefined) {
          graph.redoNodes.push(nodeToDelete);
          delete stage.graph.evaluation;
        } else {
          const prevGraph = stage.prevGraphs.pop();
          if (prevGraph !== undefined) {
            stage.nextGraphs.push(stage.graph);
            stage.graph = prevGraph;
          } else {
            throw new Error("no history to undo to");
          }
        }
      }
      if (redo ?? false) {
        const nodeToRedo = graph.redoNodes.pop();
        if (nodeToRedo !== undefined) {
          graph.nodes.push(nodeToRedo);
        } else {
          const nextGraph = stage.nextGraphs.pop();
          if (nextGraph !== undefined) {
            stage.prevGraphs.push(stage.graph);
            stage.graph = nextGraph;
          } else {
            throw new Error("no history to redo to");
          }
        }
      }
      if ((reset ?? false) && stage.graph.nodes.length > 0) {
        clearFarmerStageRedoHistory(stage);
        stage.prevGraphs.push(stage.graph);
        stage.graph = makeInitFarmerGraph();
      }
    }
    for (const [graphId, stage] of Object.entries(addStages ?? {})) {
      scopeState.stages[graphId] = stage;
    }
    if (mapEdges !== undefined) scopeState.mapEdges = mapEdges;
    if (mapExtraEdges !== undefined) scopeState.mapExtraEdges = mapExtraEdges;
  },
};
