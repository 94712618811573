import { ScopeType } from "sockapi/scope-types";
import { SockAPIScopeState } from "sockapi/scope-state";

export const PULL_ANIMATION_TIME_MS = 2000;
export const PULL_ANIMATION_EXPIRY_TIME_MS = 3000;

/** What you can get as a prize! */
export enum GachaPrizeType {
  /** A micropuzzle unlock. */
  UNLOCK = "unlock",
  /** A canned hint for a specific puzzle. */
  CANNED_HINT = "canned_hint",
  /** A hint token for a custom hint. */
  HINT_TOKEN = "hint_token",
  /** A lot of hope. */
  JACKPOT = "jackpot",
  /** A collectible that's just shiny to look at. */
  COLLECTIBLE = "collectible",
  /** A roll for the olive macropuzzle. */
  OLIVE = "olive",
  /** A roll for the blocks macropuzzle. */
  BLOCKS = "blocks",
}
type GachaPrizeInfoDict = {
  [GachaPrizeType.UNLOCK]: { puzName: string };
  [GachaPrizeType.CANNED_HINT]: { puzName: string };
  [GachaPrizeType.JACKPOT]: { amount: number; msgIndex: number };
  [GachaPrizeType.COLLECTIBLE]: { collectibleId: string; num: number };
};

/**
 * Use "extends infer" to convince Typescript to distribute the union
 * of scope types.
 */
export type GachaPrize<TPrizeType extends GachaPrizeType = GachaPrizeType> =
  TPrizeType extends infer TPrizeTypeEx
    ? TPrizeTypeEx extends GachaPrizeType
      ? {
          type: TPrizeTypeEx;
        } & (TPrizeTypeEx extends keyof GachaPrizeInfoDict
          ? GachaPrizeInfoDict[TPrizeTypeEx]
          : unknown)
      : never
    : never;

export const JACKPOT_MESSAGES: string[] = [
  "A peaceful cloud rolls by.",
  "You spot a meteor. And another one!",
  "A star twinkles to you in morse. You wave back in semaphore.",
  "The rustling of the grass fills you with determination.",
  "You feel one with the stars.",
  "You soak in the vastness of the universe. Now where'd you put your towel?",
  "You ponder the celestial orbs.",
  "You catch a faint glimpse of the aurora borealis, localized entirely within the sky.",
  "A fluffy dog runs up to you. You pet it.",
  "You get yourself a glass of water and a tasty snack.",
  "You solved the hunt! Oh wait, it was just a dream. But it inspired you anyway.",
  "A friendly ✈️ whooshes by and sends you good luck.",
  "Cryptic numbers flash before your eyes, but you forget them as soon as they pass.",
];

/** How much hope each kind of machine costs. */
export const PULL_COST = 100;

/** A pull that a team has made. */
export type GachaPull = GachaPrize & { timestamp: number };

export enum PullAckType {
  /** Successfully pulled! */
  SUCCESS = "success",
  /** Not enough hope to pull. */
  NOT_ENOUGH_HOPE = "not_enough_hope",
  /** Someone pulled too soon before. */
  TOO_SOON = "too_soon",
}

export type PullAck =
  | { type: PullAckType.SUCCESS; pullId: string; pull: GachaPull }
  | { type: PullAckType.NOT_ENOUGH_HOPE }
  | { type: PullAckType.TOO_SOON };

export enum Rarity {
  COMMON = "common",
  UNCOMMON = "uncommon",
  RARE = "rare",
  EPIC = "epic",
  LEGENDARY = "legendary",
}

export type CollectibleMetadata = {
  name: string;
  nounPrefix: string;
  icon: string;
  isHiddenFromTeamName?: true;
};

export const getTeamCollectibleIcons = (
  { collectibles }: SockAPIScopeState<ScopeType.TEAM_COLLECTIBLES>,
  /**
   * The hunt end time, to filter out posthunt collectibles.
   * Leave null to include posthunt collectibles.
   */
  huntEndTime: number | null
): string => {
  return Object.values(collectibles)
    .filter(
      ({ isHiddenFromTeamName = false, timestamp }) =>
        !isHiddenFromTeamName &&
        (huntEndTime === null || timestamp < huntEndTime)
    )
    .sort(
      (collectibleData1, collectibleData2) =>
        collectibleData1.timestamp - collectibleData2.timestamp
    )
    .map(({ icon }) => icon)
    .join("");
};
