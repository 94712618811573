import { AdminQueueTaskSummary } from "sockapi/scope-specs/admin-queue-spec";
import { PuzzleMetadata } from "sockapi/puzzle-data";
import { GachaPull } from "sockapi/gacha/pulls";

export enum HuntNotificationType {
  INTERNAL = "internal",
  ADMIN_QUEUE_TASK = "admin_queue_task",
  SUBMISSION_REJECTED = "submission_rejected",
  HINT_ANSWERED = "hint_answered",
  UNLOCK = "unlock",
  SOLVE = "solve",
  BEGIN_PULL = "begin_pull",
  END_PULL = "end_pull",
  ERRATUM = "erratum",
  VICTORY = "victory",
}

export type HuntNotification =
  | {
      type: HuntNotificationType.INTERNAL;
      message: string;
    }
  | {
      type: HuntNotificationType.ADMIN_QUEUE_TASK;
      taskId: string;
      taskSummary: AdminQueueTaskSummary;
    }
  | {
      type: HuntNotificationType.ERRATUM | HuntNotificationType.VICTORY;
    }
  | {
      type:
        | HuntNotificationType.SUBMISSION_REJECTED
        | HuntNotificationType.HINT_ANSWERED
        | HuntNotificationType.SOLVE;
      puzName: string;
    }
  | {
      type: HuntNotificationType.UNLOCK;
      puzData: PuzzleMetadata;
    }
  | {
      type: HuntNotificationType.BEGIN_PULL;
      pullId: string;
    }
  | {
      type: HuntNotificationType.END_PULL;
      pullId: string;
      /** If not defined, then the pull was unsuccessful. */
      pull?: GachaPull;
    };
