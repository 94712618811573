import { PromiseCallbacks } from "util/util";

type CancelableTimerPendingWait = {
  timer: ReturnType<typeof setTimeout>;
  callbacks: PromiseCallbacks;
};

export class CancelableTimer {
  pendingWait?: CancelableTimerPendingWait;

  async waitAsync(delayMs: number) {
    if (this.pendingWait !== undefined) throw new Error("timer still active");

    await new Promise((resolve, reject) => {
      this.pendingWait = {
        timer: setTimeout(() => {
          this.fireIfActive();
        }, delayMs),
        callbacks: { resolve, reject },
      };
    });
  }

  /** Fire the timer. May be used to fire it early. */
  fireIfActive() {
    if (this.pendingWait === undefined) return;

    const {
      timer,
      callbacks: { resolve },
    } = this.pendingWait;
    clearTimeout(timer);
    delete this.pendingWait;
    resolve();
  }
}

export class PeriodicTimer {
  timer: CancelableTimer;
  isActive: boolean;

  constructor() {
    this.timer = new CancelableTimer();
    this.isActive = false;
  }

  async startAsync(callback: () => Promise<void>, delayMs: number) {
    if (this.isActive) throw new Error("periodic timer already active");
    this.isActive = true;
    while (true) {
      await this.timer.waitAsync(delayMs);
      if (!this.isActive) break;
      await callback();
    }
  }

  stop() {
    this.isActive = false;
    // Fire the timer to stop it.
    this.timer.fireIfActive();
  }
}

export const sleepAsync = async (delayMs: number): Promise<void> => {
  const timer = new CancelableTimer();
  await timer.waitAsync(delayMs);
};
