import { objectEntriesUnsafe } from "util/util";
import { EventController } from "util/events";
import { SockAPIErrorCode, SockAPIError } from "sockapi/errors";
import { ScopeType } from "sockapi/scope-types";
import { SockAPIScope, SockAPIScopeTrackingState } from "sockapi/scope-state";
import { SockAPIScopeAssign, SockAPIScopeUpdate } from "sockapi/scope-updates";
import {
  SockAPISteppableScope,
  SockAPIScopeStepPayload,
  SockAPIStepResponseScope,
  SockAPIScopeStepResponsePayload,
} from "sockapi/scope-steps";
import { SavedHuntBackendState } from "sockapi/scope-specs/state-download-spec";
import {
  DirectBackendReqType,
  DirectBackendReq,
  DirectBackendResp,
  sendDirectBackendReqToUrlAsync,
} from "sockapi/direct-backend";
import {
  HuntNotificationType,
  HuntNotification,
} from "sockapi/client-notifications";
import { SockAPISubscriptionsClient } from "sockapi/client/subscriptions-client";
import { SockClientStepTracker } from "sockapi/client/client-step-tracker";
import { ServerConnection } from "sockapi/client/server-connections";
import {
  ServerInterface,
  ServerInterfaceType,
  AfterDisconnectEventData,
} from "sock-server/server-interface/ServerInterface";
import {
  WSReq,
  WSReqType,
  WSResp,
  WSRespType,
  WSRespCursorEvent,
  WSReqUpdateSubscriptionRequestType,
  SockAPIAuthSuccessResult,
} from "sockapi/sockapi-messages";

type SockClientOpts = {
  dumpWsMessages: boolean;
  initTeamId?: string;
  initIsAdmin: boolean;
  initIsSpectating: boolean;
  getJwtFunc: (isAdmin: boolean, forceRegen: boolean) => Promise<string | null>;
  /** Async function to create the server interface. */
  makeServerInterfaceFunc: (
    type: ServerInterfaceType
  ) => Promise<ServerInterface>;
  getScopeStateCacheTag?: (scope: SockAPIScope) => string | undefined;
  /** Artificial delay in ms for simulating high-latency connections */
  delay?: number;
  /**
   * The URL that direct backend requests should be sent to.
   * If not provided, the client assumes that we're using a mock backend,
   * and sends the direct backend request as a SockAPI request to be
   * mocked by the sock server.
   */
  directBackendUrl?: string;
};

export class SockClient {
  opts: SockClientOpts;

  serverConnections: {
    [type in ServerInterfaceType]: ServerConnection;
  };
  teamId: string | null;
  /** Whether we are authenticated as an admin. */
  isAdmin: boolean;
  /** Whether we are spectating. */
  isSpectating: boolean;
  /**
   * Whether we are stuck, and the user should be told to refresh.
   * This could happen if the Node or Django server becomes unresponsive.
   */
  isStuckErr: SockAPIError | null;

  /** Whether we've tried to initialize a connection. */
  hasConnected: boolean;

  stepTracker: SockClientStepTracker;

  pendingConnectReq?: (result: SockAPIAuthSuccessResult | null) => void;

  /** Event that fires whenever we get an error from the server. */
  onErrorEvent: EventController<SockAPIError>;
  /**
   * Event that fires if we get stuck and the user should be told
   * to refresh.
   */
  onStuckEvent: EventController<SockAPIError>;
  onTeamIdChangeEvent: EventController<string | null>;
  /**
   * Event that fires every time a message is received from the server
   * and processed.
   */
  afterRespEvent: EventController<WSResp>;
  /** Event that fires every time a scope assign is received. */
  onScopeAssignEvent: EventController<SockAPIScopeAssign>;
  /** Event that fires every time a scope update is received. */
  onScopeUpdateEvent: EventController<SockAPIScopeUpdate>;
  onNotificationEvent: EventController<HuntNotification>;
  onSetCursorGroupAckEvent: EventController<number>;
  onCursorEvent: EventController<WSRespCursorEvent>;

  /** Response queue for artificially delaying responses */
  respQueue: { emitTime: number; resp: WSResp }[];
  /** Delay in milliseconds */
  delay: number;

  subscriptionsClient: SockAPISubscriptionsClient;

  constructor(opts: SockClientOpts) {
    this.opts = opts;
    this.teamId = this.opts.initTeamId ?? null;
    this.isAdmin = this.opts.initIsAdmin;
    this.isSpectating = this.opts.initIsSpectating;
    this.isStuckErr = null;
    this.hasConnected = false;
    this.stepTracker = new SockClientStepTracker();

    this.onErrorEvent = new EventController();
    this.onStuckEvent = new EventController();
    this.onTeamIdChangeEvent = new EventController();
    this.afterRespEvent = new EventController();
    this.onScopeAssignEvent = new EventController();
    this.onScopeUpdateEvent = new EventController();
    this.onNotificationEvent = new EventController();
    this.onSetCursorGroupAckEvent = new EventController();
    this.onCursorEvent = new EventController();

    this.respQueue = [];
    this.delay = this.opts.delay ?? 0;

    const makeServerConnection = (type: ServerInterfaceType) =>
      new ServerConnection(
        type,
        this.opts.dumpWsMessages,
        (forceRegenJwt) => {
          return this.opts.getJwtFunc(this.isAdmin, forceRegenJwt);
        },
        (resp) => {
          if (this.delay > 0) {
            const emitTime = Date.now() + this.delay * (1 + Math.random());
            this.respQueue.push({ emitTime, resp });
          } else {
            this.handleResp(resp);
          }
        }
      );

    this.serverConnections = {
      [ServerInterfaceType.MAIN]: makeServerConnection(
        ServerInterfaceType.MAIN
      ),
      [ServerInterfaceType.CURSOR]: makeServerConnection(
        ServerInterfaceType.CURSOR
      ),
    };

    if (this.delay > 0) {
      setTimeout(() => this.handleQueue(), this.delay);
    }

    this.subscriptionsClient = new SockAPISubscriptionsClient({
      subscribe: (scopes) => {
        this.sendReq({
          type: WSReqType.UPDATE_SUBSCRIPTIONS,
          upds: scopes.map((scope) => ({
            type: WSReqUpdateSubscriptionRequestType.ADD,
            scope,
            tag: this.opts.getScopeStateCacheTag?.(scope),
          })),
        });
      },
      unsubscribe: (scopes) => {
        this.sendReq({
          type: WSReqType.UPDATE_SUBSCRIPTIONS,
          upds: scopes.map((scope) => ({
            type: WSReqUpdateSubscriptionRequestType.REMOVE,
            scope,
          })),
        });
      },
    });
  }

  getTeamId(): string | null {
    return this.teamId;
  }

  async sendDirectBackendReqAsync<TReqType extends DirectBackendReqType>(
    req: DirectBackendReq<TReqType>,
    file?: File,
    // Only used for the mock backend, since we can't convert
    // from File to data URLs in Node.
    // This should always be provided if file is provided.
    getFileDataURLAsync?: () => Promise<string>
  ): Promise<DirectBackendResp<TReqType>> {
    const teamId = this.getTeamId();
    if (teamId === null) throw new Error("no active team");
    const jwt = await this.opts.getJwtFunc(this.isAdmin, false);
    if (jwt === null) throw new Error("failed to get jwt");
    const { directBackendUrl } = this.opts;
    if (directBackendUrl === undefined) {
      const stepRespPayload = await this.sendScopeStepAndWaitAsync(
        {
          type: ScopeType.MOCK_DIRECT_BACKEND,
          teamId,
        },
        {
          jwt,
          req,
          file: await getFileDataURLAsync?.(),
        }
      );
      if (stepRespPayload === null)
        throw new Error("failed to send scope step");
      const reqType: TReqType = req.type;
      const isDirectBackendRespOfType = <TReqType extends DirectBackendReqType>(
        resp: DirectBackendResp,
        type: TReqType
      ): resp is DirectBackendResp<TReqType> => resp.type === type;
      if (!isDirectBackendRespOfType(stepRespPayload, reqType))
        throw new Error(
          `sent request of type ${reqType} but received type ${stepRespPayload.type}`
        );
      return stepRespPayload;
    }
    return await sendDirectBackendReqToUrlAsync(
      directBackendUrl,
      jwt,
      req,
      file
    );
  }

  setStuck(err: SockAPIError) {
    this.isStuckErr = err;
    this.onStuckEvent.fire(err);
    // If we're stuck, give up trying to connect.
    for (const conn of Object.values(this.serverConnections)) {
      conn.teardown();
    }
  }

  async initServerInterfacesAsync() {
    await Promise.all(
      objectEntriesUnsafe(this.serverConnections).map(([type, conn]) => {
        const isMain = type === ServerInterfaceType.MAIN;
        return conn.initAsync(
          async () => {
            return await this.opts.makeServerInterfaceFunc(type);
          },
          (result) => {
            if (!isMain) {
              if (result === null) console.warn(`[${type}] could not connect`);
              return;
            }

            if (result !== null) {
              this.teamId = result.teamId;
              this.onTeamIdChangeEvent.fire(this.getTeamId());
              this.subscriptionsClient.onAuth();
            } else {
              this.setStuck({ errCode: SockAPIErrorCode.AUTH_ERROR });
            }

            const { pendingConnectReq } = this;
            delete this.pendingConnectReq;
            pendingConnectReq?.(result);
          },
          (evData: AfterDisconnectEventData) => {
            if (!isMain) {
              console.warn(`[${type}] disconnected`);
              return;
            }

            // Only clear the step tracker on the first disconnect.
            // Pending step reqs are queued when disconnected and
            // will be sent to the server on the next reconnect,
            // so we must not clear it again in between.
            if (evData.numReconnections === 0)
              this.stepTracker.handleDisconnect();
          }
        );
      })
    );
  }

  teardown(): void {
    this.subscriptionsClient.teardown();
    for (const conn of Object.values(this.serverConnections)) {
      conn.teardown();
    }
  }

  getIsAuthed(serverInterfaceType?: ServerInterfaceType): boolean {
    serverInterfaceType ??= ServerInterfaceType.MAIN;

    return this.serverConnections[serverInterfaceType].isAuthed;
  }

  setIsAdmin(newIsAdmin: boolean) {
    if (newIsAdmin === this.isAdmin) return;
    this.isAdmin = newIsAdmin;
    for (const conn of Object.values(this.serverConnections)) {
      conn.reconnect();
    }
  }

  /**
   * Connect to the server and authenticate.
   * Returns the auth result if the connection and authentication
   * was successful.
   */
  async connectAsync(): Promise<SockAPIAuthSuccessResult | null> {
    if (this.isConnected())
      throw new Error("only expect to be told to connect once");
    this.hasConnected = true;

    return await new Promise((resolve, reject) => {
      if (this.pendingConnectReq !== undefined)
        throw new Error("only expect to be told to connect once");
      this.pendingConnectReq = resolve;

      this.initServerInterfacesAsync().catch((err) => console.error(err));

      this.subscriptionsClient
        .startPeriodicCleanupAsync()
        .catch((err) => console.error(err));
    });
  }

  isConnected(): boolean {
    return this.hasConnected;
  }

  addAuthEffect(
    func: () => (() => void) | void,
    serverInterfaceType?: ServerInterfaceType
  ): () => void {
    serverInterfaceType ??= ServerInterfaceType.MAIN;

    return this.serverConnections[serverInterfaceType].addAuthEffect(func);
  }

  /**
   * Call func when stuck, or if already stuck.
   * Returns a cleanup function.
   */
  addStuckEffect(func: (err: SockAPIError) => void): () => void {
    if (this.isStuckErr !== null) func(this.isStuckErr);
    return this.onStuckEvent.addListener(func);
  }

  /**
   * Subscribe to multiple scopes. Returns a cleanup function. */
  subscribeMultiple(scopes: SockAPIScope[]): () => void {
    this.subscriptionsClient.addMultiple(scopes);
    return () => this.subscriptionsClient.removeMultiple(scopes);
  }

  addConditionalSubscribeMultipleEffect(
    getScopesFunc: () => SockAPIScope[]
  ): () => void {
    return this.addAuthEffect(() => {
      const scopes = getScopesFunc();
      this.subscriptionsClient.addMultiple(scopes);
      return () => this.subscriptionsClient.removeMultiple(scopes);
    });
  }

  addSubscribeMultipleEffect(scopes: SockAPIScope[]): () => void {
    return this.addConditionalSubscribeMultipleEffect(() => scopes);
  }

  addSubscribeEffect(scope: SockAPIScope): () => void {
    return this.addSubscribeMultipleEffect([scope]);
  }

  /**
   * The client should call this after every global state update,
   * or optionally more frequently.
   * This is intended for development and should not be depended on
   * in production, as there may be some race conditions with
   * React hooks.
   */
  afterUpdateScopeTrackingState(
    scopeTrackingState: SockAPIScopeTrackingState
  ): void {
    return;
  }

  handleQueue(): void {
    const now = Date.now();
    while (this.respQueue.length > 0 && this.respQueue[0].emitTime < now) {
      const { resp } = this.respQueue.shift() ?? {};
      if (resp !== undefined) {
        this.handleResp(resp);
      }
    }

    const wait =
      this.respQueue.length > 0
        ? Math.max(0, this.respQueue[0].emitTime - Date.now())
        : this.delay;
    setTimeout(() => this.handleQueue(), wait);
  }

  handleResp(resp: WSResp): void {
    switch (resp.type) {
      case WSRespType.ERROR: {
        const { err, stepId } = resp;
        console.error(err);
        if (stepId !== undefined) this.stepTracker.handleErr(stepId);
        this.onErrorEvent.fire(err);
        switch (err.errCode) {
          case SockAPIErrorCode.TEAM_TEMP_BLOCKED: {
            this.setStuck(err);
            break;
          }
        }
        break;
      }
      case WSRespType.SCOPE_ASSIGN: {
        this.subscriptionsClient.handleScopeAssign(resp.assign.scope);
        this.onScopeAssignEvent.fire(resp.assign);
        break;
      }
      case WSRespType.SCOPE_UPDATE: {
        this.onScopeUpdateEvent.fire(resp.upd);
        break;
      }
      case WSRespType.SCOPE_STEP_RESPONSE: {
        this.stepTracker.handleResponse(resp.stepId, resp.stepResponse);
        break;
      }
      case WSRespType.SET_CURSOR_GROUP_ACK: {
        this.onSetCursorGroupAckEvent.fire(resp.reqId);
        break;
      }
      case WSRespType.CURSOR_EVENT: {
        this.onCursorEvent.fire(resp);
        break;
      }
      case WSRespType.NOTIFICATION: {
        const { notif } = resp;
        this.onNotificationEvent.fire(notif);
        break;
      }
      case WSRespType.UPDATE_SUBSCRIPTIONS_ERRORS: {
        const { errors } = resp;
        this.subscriptionsClient.handleSubscriptionErrors(errors);
        const firstErr = errors[0]?.err;
        if (firstErr === undefined)
          console.error(
            "expect update subscription errors responses to be non-empty"
          );
        else this.onErrorEvent.fire(firstErr);
        break;
      }
    }

    this.afterRespEvent.fire(resp);
  }

  /**
   * If this returns true, then the message is either sent is guaranteed
   * to eventually be sent. Otherwise, the message was not and will never
   * be sent.
   */
  sendReq(req: WSReq): boolean {
    const serverInterfaceType = (() => {
      switch (req.type) {
        case WSReqType.SET_CURSOR_GROUP:
        case WSReqType.CURSOR_EVENT: {
          return ServerInterfaceType.CURSOR;
        }
        default: {
          return ServerInterfaceType.MAIN;
        }
      }
    })();
    if (
      this.isSpectating &&
      ![WSReqType.SET_CURSOR_GROUP, WSReqType.UPDATE_SUBSCRIPTIONS].includes(
        req.type
      )
    ) {
      this.onNotificationEvent.fire({
        type: HuntNotificationType.INTERNAL,
        message: `Tried to ${req.type}, but you’re spectating!`,
      });
      return false;
    }
    this.serverConnections[serverInterfaceType].sendReq(req, {
      noBuffer: [
        WSReqType.PING,
        // Drop cursor events if not authed.
        WSReqType.CURSOR_EVENT,
        // Dropping subscription messages when not authed is
        // functionally required to ensure that the subscriptions
        // state is consistent on auth.
        WSReqType.UPDATE_SUBSCRIPTIONS,
      ].includes(req.type),
    });
    return true;
  }

  /**
   * If this returns true, then the message is either sent is guaranteed
   * to eventually be sent. Otherwise, the message was not and will never
   * be sent.
   */
  sendScopeStep<TScope extends SockAPISteppableScope>(
    scope: TScope,
    payload: SockAPIScopeStepPayload<TScope["type"]>,
    stepId?: string
  ): boolean {
    return this.sendReq({
      type: WSReqType.SCOPE_STEP,
      step: {
        scope,
        payload,
      },
      stepId,
    });
  }

  async sendScopeStepAndWaitAsync<TScope extends SockAPIStepResponseScope>(
    scope: TScope,
    payload: SockAPIScopeStepPayload<TScope["type"]>
  ): Promise<SockAPIScopeStepResponsePayload<TScope["type"]> | null> {
    const stepId = this.stepTracker.genStepId();
    if (!this.sendScopeStep(scope, payload, stepId)) return null;
    const scopeType: TScope["type"] = scope.type;
    return await this.stepTracker.waitForRespAsync(stepId, scopeType);
  }

  async sendDummyScopeStepAndWaitAsync() {
    return await this.sendScopeStepAndWaitAsync({ type: ScopeType.DUMMY }, "");
  }

  async sendAdminScopeStepAndWaitAsync(
    payload: SockAPIScopeStepPayload<ScopeType.HUNT_ADMIN>
  ) {
    return await this.sendScopeStepAndWaitAsync(
      { type: ScopeType.HUNT_ADMIN },
      payload
    );
  }

  async sendStateDownloadScopeStepAndWaitAsync(
    payload: SockAPIScopeStepPayload<ScopeType.STATE_DOWNLOAD>
  ) {
    return await this.sendScopeStepAndWaitAsync(
      { type: ScopeType.STATE_DOWNLOAD },
      payload
    );
  }

  sendAdminScopeStep(payload: SockAPIScopeStepPayload<ScopeType.HUNT_ADMIN>) {
    this.sendScopeStep({ type: ScopeType.HUNT_ADMIN }, payload);
  }

  sendTeamPuzzle105ScopeStep(
    payload: SockAPIScopeStepPayload<ScopeType.TEAM_PUZZLE_105>,
    overrideTeamId?: string
  ): boolean {
    const teamId = overrideTeamId ?? this.getTeamId();
    if (teamId === null) return false;
    this.sendScopeStep(
      {
        type: ScopeType.TEAM_PUZZLE_105,
        teamId,
      },
      payload
    );
    return true;
  }

  // Only used for mock servers.
  resetServer(initState?: SavedHuntBackendState) {
    this.onTeamIdChangeEvent.fire(null);
    for (const conn of Object.values(this.serverConnections)) {
      conn.resetServer(initState);
    }
  }
}
