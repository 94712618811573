import { create } from "zustand";

import { SockClient } from "sockapi/client/SockClient";

interface ServerInteractionStoreState {
  /**
   * HACK: wrap access to sock client in a function
   * so that react doesn't try to manage its state.
   */
  getSockClient: () => SockClient | null;
  setSockClient: (sockClient: SockClient) => void;

  /** If authenticated, tracks whether we're authenticated as admin. */
  isAdmin: boolean | null;
  /**
   * If we should display admin interfaces.
   * Similar to isAdmin, but does not get cleared on disconnect.
   */
  shouldDisplayAdmin: boolean;
  setIsAdmin: (isAdmin: boolean | null) => void;

  /** The teamId to be exposed in the client. */
  activeTeamId: string | null;
  setActiveTeamId: (teamId: string | null) => void;
}

export const useServerInteractionStore = create<ServerInteractionStoreState>(
  (set, get) => ({
    getSockClient: () => {
      return null;
    },
    setSockClient: (sockClient: SockClient) => {
      set({
        getSockClient: () => {
          return sockClient;
        },
      });
    },
    isAdmin: null,
    shouldDisplayAdmin: false,
    setIsAdmin: (isAdmin) =>
      set({
        isAdmin,
        ...(isAdmin === null ? {} : { shouldDisplayAdmin: isAdmin }),
      }),
    activeTeamId: null,
    setActiveTeamId: (teamId) => set({ activeTeamId: teamId }),
  })
);

/**
 * Get the sock client directly, asserting that it has
 * been initialized. Should only be used in components that are only
 * created after sock client has been initialized.
 */
export const useSockClient = (): SockClient => {
  const sockClient = useServerInteractionStore(
    (state) => state.getSockClient
  )();
  if (sockClient === null) throw new Error("sock client not initialized");
  return sockClient;
};

export const useTeamId = (): string | null => {
  return useServerInteractionStore((state) => state.activeTeamId);
};

export const useIsAdmin = (): boolean | null => {
  return useServerInteractionStore((state) => state.isAdmin);
};

export const useShouldDisplayAdmin = (): boolean => {
  return useServerInteractionStore((state) => state.shouldDisplayAdmin);
};
