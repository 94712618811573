import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";

export const BIG_BRANCH_CONFIG = {
  puzName: "branch",
  rateLimit: {
    intervalMs: 15 * 1000,
    num: 5,
  },
};

export type TeamBigBranchScopeSpec = {
  identifier: ScopeTeamIdentifier;
  step: {
    checkAnswer?: string;
  };
  stepResponse: {
    checkAnswerResp?: {
      isRateLimited?: true;
      isCorrect?: boolean;
    };
  };
};

export const teamBigBranchScopeHooks: ScopeHooks<ScopeType.TEAM_BIG_BRANCH> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
};
