import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import { ErratumSummary } from "sockapi/scope-specs/erratum-content-spec";
import { PUZZLE_105_CONFIG } from "sockapi/scope-specs/team-puzzle-105-spec";
import { PuzzleData, PuzzlesDBData } from "sockapi/puzzle-data";
import { TeamProgressEventReason } from "sockapi/team-progress";
import { SockServerConfig } from "sockapi/server-config";

export const comparePuzzlesDefaultUnlockOrder = (
  puz1: PuzzleData,
  puz2: PuzzleData
) => {
  // Sort custom puzzles last.
  const isCustom1 = puz1.customPuzzleData !== undefined ? 1 : 0;
  const isCustom2 = puz2.customPuzzleData !== undefined ? 1 : 0;
  const diffIsCustom = isCustom1 - isCustom2;
  if (diffIsCustom !== 0) return diffIsCustom;
  const diffOrder = puz1.order - puz2.order;
  if (diffOrder !== 0) return diffOrder;
  if (isCustom1 && isCustom2) {
    const teamId1 = puz1.puzName.slice(
      PUZZLE_105_CONFIG.customPuzzlePuzNamePrefix.length
    );
    const teamId2 = puz2.puzName.slice(
      PUZZLE_105_CONFIG.customPuzzlePuzNamePrefix.length
    );
    const teamId1Int = parseInt(teamId1);
    const teamId2Int = parseInt(teamId2);
    return (
      (isNaN(teamId1Int) ? 0 : teamId1Int) -
      (isNaN(teamId2Int) ? 0 : teamId2Int)
    );
  }
  return puz1.puzName.localeCompare(puz2.puzName);
};

export type SockAPITeamAdminData = {
  userId: string;
  displayName: string;
  isHidden: boolean;
  isInactive: boolean;
  isTempBlocked: boolean;
};

export type AdminModifyTeamParams = {
  isInactive?: boolean;
  isHidden?: boolean;
  isTempBlocked?: boolean;
  displayName?: string;
  overrideSolved?: { [puzName: string]: boolean };
  overrideUnlocked?: { [puzName: string]: boolean };
  overridePuzzleNumExtraGuesses?: { [puzName: string]: number };
  overridePuzzleNumTotalCannedHints?: { [puzName: string]: number };
  overrideNumTotalHintTokens?: number;
  overrideHope?: number;
  overrideNumOliveWishes?: number;
  overrideNumBlocksWishes?: number;
};

export type HuntAdminScopeSpec = {
  state: {
    teamAdminData: { [teamId: string]: SockAPITeamAdminData };
    puzzles: { [puzName: string]: PuzzleData };
    numCannedHints: { [puzName: string]: number };
    errata: {
      [erratumId: string]: { puzName: string | null; timestamp: number };
    };
  };
  update: {
    addTeams?: {
      [teamId: string]: SockAPITeamAdminData;
    };
    updTeams?: {
      [teamId: string]: {
        isHidden?: boolean;
        isInactive?: boolean;
        displayName?: string;
      };
    };
    addPuzzles?: {
      [puzName: string]: PuzzleData;
    };
    numCannedHints?: { [puzName: string]: number };
    setErrata?: {
      [erratumId: string]: ErratumSummary | null;
    };
  };
  step: {
    config?: Partial<SockServerConfig>;
    teams?: {
      [teamId: string]: AdminModifyTeamParams;
    };
    reloadPuzzles?: true;
    setPuzzlesDB?: PuzzlesDBData;
    /**
     * If provided, will be set as the reason for all events
     * tagged with reasons. Otherwise, MISC without a reason text
     * would be used by default.
     */
    reason?: TeamProgressEventReason;
    /**
     * Force all timestamps generated to start at an offset from hunt
     * start. Only used for testing.
     */
    setForcedTimeOffset?: number;
  };
  stepResponse: "";
};

export const huntAdminScopeHooks: ScopeHooks<ScopeType.HUNT_ADMIN> = {
  identityFunc: (scope) => scope,
  update: (scopeState, payload) => {
    const { addTeams, updTeams, addPuzzles, numCannedHints, setErrata } =
      payload;
    Object.assign(scopeState.teamAdminData, addTeams ?? {});
    for (const [teamId, teamUpd] of Object.entries(updTeams ?? {})) {
      const teamData = scopeState.teamAdminData[teamId];
      Object.assign(teamData, teamUpd);
    }
    Object.assign(scopeState.puzzles, addPuzzles ?? {});
    if (numCannedHints !== undefined)
      Object.assign(scopeState.numCannedHints, numCannedHints);
    for (const [erratumId, erratum] of Object.entries(setErrata ?? {})) {
      if (erratum === null) delete scopeState.errata[erratumId];
      else scopeState.errata[erratumId] = erratum;
    }
  },
};
