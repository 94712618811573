import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import { HopeSnapshot } from "sockapi/gacha/hope";

export type BigBoardTeamPuzzleState = {
  solveTime?: number;
  numHintsUsed?: number;
  numWrongGuesses?: number;
  numMembers?: number;
};

export type BigBoardTeamState = {
  numHintsTotal: number;
  unlocks: {
    [puzName: string]: BigBoardTeamPuzzleState;
  };
  hope: HopeSnapshot | null;
};

export type BigBoardTeamUpdate = {
  numHintsTotal?: number;
  unlocks?: {
    [puzName: string]: {
      solveTime?: number;
      numHintsUsed?: number;
      numWrongGuesses?: number;
      numMembers?: number;
    } | null;
  };
  hope?: HopeSnapshot;
};

export const mergeBigBoardTeamUpdate = (
  upd1: BigBoardTeamUpdate,
  upd2: BigBoardTeamUpdate
) => {
  if (upd2.numHintsTotal !== undefined) upd1.numHintsTotal = upd2.numHintsTotal;
  for (const [puzName, puzUpds] of Object.entries(upd2.unlocks ?? {})) {
    upd1.unlocks ??= {};
    if (puzUpds === null) {
      upd1.unlocks[puzName] = null;
      return;
    }
    upd1.unlocks[puzName] = Object.assign(upd1.unlocks[puzName] ?? {}, puzUpds);
  }
  if (upd2.hope !== undefined) upd1.hope = upd2.hope;
};

export const updateBigBoardTeamState = (
  state: BigBoardTeamState,
  upd: BigBoardTeamUpdate
) => {
  const { numHintsTotal, unlocks, hope } = upd;
  if (numHintsTotal !== undefined) state.numHintsTotal = numHintsTotal;
  for (const [puzName, puzUpds] of Object.entries(unlocks ?? {})) {
    state.unlocks[puzName] = Object.assign(
      state.unlocks[puzName] ?? {},
      puzUpds
    );
  }
  if (hope !== undefined) state.hope = hope;
};

export type BigBoardScopeSpec = {
  state: {
    teams: { [teamId: string]: BigBoardTeamState };
  };
  update: {
    addTeams?: { [teamId: string]: BigBoardTeamState };
    updTeams?: { [teamId: string]: BigBoardTeamUpdate };
  };
};

export const bigBoardScopeHooks: ScopeHooks<ScopeType.BIG_BOARD> = {
  identityFunc: (scope) => scope,
  update: (scopeState, payload) => {
    const { addTeams, updTeams } = payload;
    Object.assign(scopeState.teams, addTeams ?? {});
    for (const [teamId, teamUpd] of Object.entries(updTeams ?? {})) {
      const teamState = scopeState.teams[teamId];
      if (teamState === undefined)
        throw new Error("received big board update for non-existent team");
      updateBigBoardTeamState(teamState, teamUpd);
    }
  },
  disableClientCache: true,
};
