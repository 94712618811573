import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";

export type ErratumContent = {
  puzName: string | null;
  updatesText: string;
  puzzleText: string;
  timestamp: number;
  isPublished: boolean;
};

export type ErratumSummary = {
  puzName: string | null;
  timestamp: number;
};

export type ErratumContentScopeSpec = {
  identifier: { erratumId: string };
  state: ErratumContent;
  backendState: ErratumContent;
};

export const erratumContentScopeHooks: ScopeHooks<ScopeType.ERRATUM_CONTENT> = {
  identityFunc: (scope) => scope,
  getIdComponents: ({ erratumId }) => [erratumId],
};
