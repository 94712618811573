import { KeysMatching, objGetOrCreate } from "util/util";

import {
  Scope,
  ScopeIdentifierType,
  ScopeIdentifier,
  scopeToString,
} from "sockapi/scopes";
import { ScopeSpecs } from "sockapi/scope-specs";

type SockAPIScopeStateDict = {
  [TScopeType in KeysMatching<
    ScopeSpecs,
    { state: unknown }
  >]: ScopeSpecs[TScopeType]["state"];
};

export type SockAPIScopeType = keyof SockAPIScopeStateDict;
export type SockAPIScope<
  TScopeType extends SockAPIScopeType = SockAPIScopeType
> = Scope<TScopeType>;
export type SockAPIScopeIdentifierType = Extract<
  ScopeIdentifierType,
  SockAPIScopeType
>;
export type SockAPIScopeIdentifier<
  TScopeType extends SockAPIScopeIdentifierType
> = ScopeIdentifier<TScopeType>;
export type SockAPIScopeState<TScopeType extends SockAPIScopeType> =
  SockAPIScopeStateDict[TScopeType];

export type SockAPIScopeTrackingEntry<TScopeType extends SockAPIScopeType> = {
  /**
   * If the entry exists but not the state, then the state was not
   * found in the cache and we must wait for the init state to be
   * downloaded from the server.
   */
  state?: SockAPIScopeState<TScopeType>;
  cacheTag?: string;
  /**
   * If set, then this is static posthunt state. Static posthunt state
   * always takes priority over non-posthunt state, and should never be updated.
   */
  isPosthunt?: true;
  downloadFailedInfo?: { timestamp: number };
};
export type SockAPIScopeTrackingStateForType<
  TScopeType extends SockAPIScopeType
> = {
  [scopeId: string]: SockAPIScopeTrackingEntry<TScopeType>;
};

export type SockAPIScopeTrackingState = {
  [TScopeType in SockAPIScopeType]?: SockAPIScopeTrackingStateForType<TScopeType>;
};

export const getScopeTrackingStateForTypeReadonly = <
  TScopeType extends SockAPIScopeType
>(
  state: SockAPIScopeTrackingState,
  scopeType: TScopeType
): Readonly<SockAPIScopeTrackingStateForType<TScopeType>> => {
  return state[scopeType] ?? {};
};

const getOrCreateScopeTrackingStateForType = <
  TScopeType extends SockAPIScopeType
>(
  state: SockAPIScopeTrackingState,
  scopeType: TScopeType
): SockAPIScopeTrackingStateForType<TScopeType> => {
  return objGetOrCreate(state, scopeType, {});
};

export const getOrCreateScopeTrackingEntry = <TScope extends SockAPIScope>(
  state: SockAPIScopeTrackingState,
  scope: TScope,
  getInitState?: () => SockAPIScopeTrackingEntry<TScope["type"]> | undefined
): SockAPIScopeTrackingEntry<TScope["type"]> => {
  const scopeId = scopeToString(scope);
  const scopeType: TScope["type"] = scope.type;
  const scopeStates = getOrCreateScopeTrackingStateForType(state, scopeType);
  const existingEntry = scopeStates[scopeId];
  if (existingEntry !== undefined) return existingEntry;
  const newEntry: SockAPIScopeTrackingEntry<TScope["type"]> =
    getInitState?.() ?? {};
  scopeStates[scopeId] = newEntry;
  return newEntry;
};

export type SockAPIScopeStateChangeResult<TScopeType extends SockAPIScopeType> =
  {
    state: SockAPIScopeState<TScopeType>;
  };

export const setScopeState = <TScope extends SockAPIScope>(
  state: SockAPIScopeTrackingState,
  scope: TScope,
  scopeState: SockAPIScopeState<TScope["type"]>,
  isPosthunt?: true
): SockAPIScopeStateChangeResult<TScope["type"]> => {
  const scopeId = scopeToString(scope, isPosthunt ?? false);
  const typeScopes: SockAPIScopeTrackingStateForType<TScope["type"]> =
    objGetOrCreate(state, scope.type, {});
  const existingEntry = typeScopes[scopeId];
  if (!(isPosthunt ?? false)) {
    if (
      existingEntry?.state !== undefined &&
      (existingEntry.isPosthunt ?? false)
    )
      throw new Error(
        "should not receive non-posthunt assigns for posthunt state"
      );
  }
  typeScopes[scopeId] = { state: scopeState, isPosthunt };
  return { state: scopeState };
};

export const setPosthuntScopeStateDownloadFailed = <
  TScope extends SockAPIScope
>(
  state: SockAPIScopeTrackingState,
  scope: TScope
): void => {
  const scopeId = scopeToString(scope, true);
  const typeScopes: SockAPIScopeTrackingStateForType<TScope["type"]> =
    objGetOrCreate(state, scope.type, {});
  const existingEntry = typeScopes[scopeId];
  // If a download succeeded before, don't set it to failed now.
  if (existingEntry?.state !== undefined) {
    if (!(existingEntry.isPosthunt ?? false))
      throw new Error("should not set download failed for non-posthunt state");
    return;
  }
  typeScopes[scopeId] = {
    isPosthunt: true,
    downloadFailedInfo: { timestamp: Date.now() },
  };
};

export const updateScopeState = <TScope extends SockAPIScope>(
  state: SockAPIScopeTrackingState,
  scope: TScope,
  updateFunc: (scopeState: SockAPIScopeState<TScope["type"]>) => void
): SockAPIScopeStateChangeResult<TScope["type"]> => {
  const entry = getOrCreateScopeTrackingEntry(state, scope);
  const scopeState = entry.state;
  if (scopeState === undefined)
    throw new Error(`scope ${scopeToString(scope)} not initialized`);
  if (entry.isPosthunt ?? false)
    throw new Error("should not receive updates for posthunt state");
  updateFunc(scopeState);
  return { state: scopeState };
};
